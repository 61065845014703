import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ScrollAreaWrapper from '../../UI/ScrollAreaWrapper';
import Moment from 'react-moment';
import { sumBy } from 'lodash';

const TournamentPlayers = ({
    isOpen,
    toggle,
    tournamentPlayersArr,
    playersModalId,
    openPlayerHistoryModalFunc,
    openChatModalFunc,
    openedPlayerTournament,
}) => {
    return (
        <Modal container={'div > div'} size="xl" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Players
            </ModalHeader>
            <ModalBody>
                <ScrollAreaWrapper
                    className="tournaments-results-scrollarea"
                    contentClassName="tournaments-scrollarea-body"
                    show
                    rightOffset={13}
                    topOffset={2}
                    bottomOffset={2}
                >
                    {(tournamentPlayersArr && tournamentPlayersArr[playersModalId]) ? (
                        <table style={{ width: '100%' }}>
                            <colgroup>
                                <col span="1" />
                            </colgroup>
                            <thead className="tournaments-table-header-admin">
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Joined
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Player Id
                                    </th>
                                    <th>
                                        Tournament Points
                                    </th>
                                    <th>
                                        Room Points
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Winnings
                                    </th>
                                    <th>
                                        Balance Change
                                    </th>
                                    <th>
                                        Environment
                                    </th>
                                    <th>
                                        History
                                    </th>
                                    <th>
                                        Message
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="tournaments-table-body-admin">
                                {tournamentPlayersArr[playersModalId].map((tournamentPlayer, index) => (
                                    <tr key={tournamentPlayer.key} className={`tournaments-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                                        <td className="tournaments-table-col">
                                            {index + 1}
                                        </td>
                                        <td className="tournaments-table-col">
                                            <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                                {tournamentPlayer.joinTime}
                                            </Moment>
                                        </td>
                                        <td className="tournaments-table-col">
                                            {`${tournamentPlayer.isAutomated ? '(B)' : ''} ${tournamentPlayer.name}`}
                                        </td>
                                        <td className="tournaments-table-col">
                                            {tournamentPlayer.uid}
                                        </td>
                                        <td className="tournaments-table-col">
                                            {tournamentPlayer.tournamentPoints}
                                        </td>
                                        <td className="tournaments-table-col">
                                            {tournamentPlayer.totalRoomPoints}
                                        </td>
                                        <td className="tournaments-table-col">
                                            {!tournamentPlayer.quit ? (tournamentPlayer.paused ? 'paused' : 'active') : 'left'}
                                        </td>
                                        <td className="tournaments-table-col">
                                            {tournamentPlayer.winnings}
                                        </td>
                                        <td className="tournaments-table-col">
                                            <div>{(tournamentPlayer.initialBal && tournamentPlayer.endBal) ? ((tournamentPlayer.endBal - tournamentPlayer.initialBal) - (tournamentPlayer.entryFee || 0) + (tournamentPlayer.winnings || 0)) : ''}</div>
                                        </td>
                                        <td className="tournaments-table-col">
                                            {tournamentPlayer.env}
                                        </td>
                                        <td className="tournaments-table-col">
                                            <Button className="allTournaments-table-col-button" color="primary" onClick={() => openPlayerHistoryModalFunc(playersModalId, tournamentPlayer.key)}>
                                                History
                                            </Button>
                                        </td>
                                        <td className="tournaments-table-col">
                                            <Button color="primary" onClick={() => openChatModalFunc(tournamentPlayer.uid, tournamentPlayer.name)}>
                                                Message
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                {openedPlayerTournament === 'closed' && (
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Total Balance Change:</td>
                                        <td>{sumBy(tournamentPlayersArr[playersModalId], (item) => (((item.endBal || 0) - (item.initialBal)) - (item.entryFee || 0) + (item.winnings || 0)))}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (null)}
                </ScrollAreaWrapper>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default TournamentPlayers;