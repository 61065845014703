
import { replace } from 'lodash';
import React from 'react';
import Moment from 'react-moment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const PlayerHistory = ({
    isOpen,
    toggle,
    tournamentPlayers,
    playersModalId,
    playerHistoryId,
    tournamentPlayerHistory,
    setLeaveReasonModal,
}) => {

    const replaceTournamentsPlayerResult = (text) => {
        let resultText = text;
        if (text?.includes("room points -") && text?.includes("added points -")) {
            resultText = replace(resultText, "room points -", "room points:");
            resultText = replace(resultText, "added points -", "added points:");
        } else if (text?.includes("room points -")) {
            resultText = replace(resultText, "room points -", "room points:");
        } else if (text?.includes("added points -")) {
            resultText = replace(resultText, "added points -", "added points:");
        }
    
        return resultText;
    }

    return (
        <Modal container={'div > div'} size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {(tournamentPlayers && tournamentPlayers[playersModalId] && playersModalId && playerHistoryId && tournamentPlayers[playersModalId][playerHistoryId]) ? tournamentPlayers[playersModalId][playerHistoryId].name : ''}
            </ModalHeader>
            <ModalBody>
                {tournamentPlayerHistory && (
                    <table style={{ width: '95%' }}>
                        <colgroup>
                            <col span="1" />
                        </colgroup>
                        <thead className="tournaments-table-header tournaments-table-header-admin">
                            <tr>
                                <th>
                                    Type
                                </th>
                                <th>
                                    Date
                                </th>
                            </tr>
                        </thead>
                        <tbody className="tournaments-table-body tournaments-table-body-admin">
                            {Object.keys(tournamentPlayerHistory).map((key, index) => (
                                <tr key={key} className={`tournaments-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                                    <td className="tournaments-table-col">
                                        {replaceTournamentsPlayerResult(tournamentPlayerHistory[key].type)}
                                        {(tournamentPlayerHistory[key].type === 'left' && tournamentPlayerHistory[key].leaveReason) && (
                                            <Button style={{position: 'absolute', padding: 1, fontSize: 12, marginLeft: 10}} color="secondary" onClick={() => setLeaveReasonModal(tournamentPlayerHistory[key].leaveReason)}>Reason</Button>
                                        )}
                                    </td>
                                    <td className="tournaments-table-col">
                                        <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                            {tournamentPlayerHistory[key].time}
                                        </Moment>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default PlayerHistory;




