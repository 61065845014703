import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const LeaveReason = ({leaveReasonModal, toggle}) => {
    return (
        <Modal container={'div > div'} size="lg" isOpen={!!leaveReasonModal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Leave reason
            </ModalHeader>
            <ModalBody className="text-center">
                {leaveReasonModal}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default LeaveReason;