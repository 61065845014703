import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import isEqual from 'react-fast-compare';

import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import pro from '../../../../images/icons/pro.svg';
import speed from '../../../../images/icons/fast_game.svg';
import lightning from '../../../../images/icons/fast_game_lightning.svg';
import unlimited from '../../../../images/redesign/common/Room types unlimited.svg';
import keyImg from '../../../../images/icons/private.svg';
import fourPImg from '../../../../images/icons/4_spēlētāji.svg';
import minGamesImg from '../../../../images/icons/min_games.svg';
import IconPopover from '../IconPopover';

const RoomsTableRowRender = React.memo(({
  room, removal, joinedRoom, uid, leaveRoom, activeTournament, finishedTournamentLastRound, popoverOpen, joinRoomClicked, onHover, onHoverLeave, joinRoomClickedFunc, joinPrivateRoomConfirm, fontMode, tooltipId
}) => {
  const { t } = useTranslation('common');

  if (!room || !room.globalParams || !room.playersList) {
    return null;
  }

  if (!room) {
    return null;
  }

  return (
    <tr key={room.key} data-roomkey={room.key} style={removal ? { opacity: 0 } : { opacity: 1 }} className="rooms-table-row">
      <td className="rooms-table-col">
        {room.globalParams.bet}
      </td>
      <td className="rooms-table-col">
        {room.globalParams.gameType === 'P' ? (
          <Fragment>
            {room.globalParams.smallGame ? (
              <div className="gameType">
                <p className="gameType-text">
                  PM
                </p>
              </div>
            ) : (
              <div className="gameType">
                <p className="gameType-text">
                  P
                </p>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {room.globalParams.smallGame ? (
              <div className="gameType">
                <p className="gameType-text">
                  MG
                </p>
              </div>
            ) : (
              <div className="gameType">
                <p className="gameType-text">
                  G
                </p>
              </div>
            )}
          </Fragment>
        )}
      </td>
      <td className="rooms-table-col pri">
        {room.globalParams.privateRoom && (
        <Fragment>
          <Media className="label-private" src={keyImg} alt="Privāta" />
        </Fragment>
        )}
      </td>
      <td className="rooms-table-col" style={{ padding: 0, paddingTop: 5, paddingLeft: 10 }}>
        {room.globalParams.fastGame && (
          <Fragment>
            <Media className="label-speed" src={speed} alt="Ātrā" />
          </Fragment>
        )}
        {room.globalParams.lightningGame && (
          <Fragment>
            <Media className="label-speed" src={lightning} alt="Ātrā" />
          </Fragment>
        )}
        {room.globalParams.unlimitedGame && ( // TODO 'unlimitedGame' path might change
          <Fragment>
            <Media className="label-speed" src={unlimited} alt="Bez limita" />
          </Fragment>
        )}
      </td>
      <td className="rooms-table-col">
        {room.globalParams.proGame && (
        <Fragment>
          <Media className="label-pro" src={pro} alt="Pro" />
        </Fragment>
        )}
      </td>
      <td className="rooms-table-col">
        <div className="minGames">
          <Media className="minGames-img" src={minGamesImg} alt="4 Spēlētāji" />
          <p className="minGames-text">
            {room.globalParams.minGames ? (
              room.globalParams.minGames
            ) : (
              '1'
            )}
          </p>
        </div>
      </td>

      <td className="rooms-table-col">
        {room.globalParams.fourPRoom && (
        <Media className="label-pro" src={fourPImg} alt="4 Spēlētāji" />
        )}
      </td>

      <td className="rooms-table-col">
        {room.playersList && (
        <Fragment>
          {room.globalParams.fourPRoom ? (
            ['player1', 'player2', 'player3', 'player4'].map(pkey => (
              <Col xs="3" sm="3" key={`rtc-${pkey}`} className="rooms-table-col-player-4">
                {room.playersList[pkey] ? (
                  <div className="rooms-table-player-4">
                    <div className="rooms-table-player-4-lvl">
                      <div className="rooms-table-player-4-lvl-text">
                        {`${room.playersList[pkey].lvl || 1}`}
                      </div>
                    </div>
                    <div className="rooms-table-player-4-name">
                      {`${room.playersList[pkey].shortName || room.playersList[pkey].name}`}
                    </div>
                  </div>
                ) : (
                  <div className="rooms-table-player-4-waiting">
                    {(t('roomsTable.waiting'))}
                  </div>
                )}
              </Col>
            ))) : (['player1', 'player2', 'player3'].map(pkey => (
              <Col xs="4" sm="4" key={`rtc-${pkey}`} className="rooms-table-col-player-3">
                {room.playersList[pkey] ? (
                  <div className="rooms-table-player">
                    <div className="rooms-table-player-lvl">
                      <div className="rooms-table-player-lvl-text">
                        {`${room.playersList[pkey].lvl || 1}`}
                      </div>
                    </div>
                    <div className="rooms-table-player-name">
                      {`${room.playersList[pkey].shortName || room.playersList[pkey].name}`}
                    </div>
                  </div>
                ) : (
                  <div className="rooms-table-player-waiting">
                    {(t('roomsTable.waiting'))}
                  </div>
                )}
              </Col>
          )))}
        </Fragment>
        )}
      </td>
      <td className="rooms-table-col">

        {(room.playersList.player1 && room.playersList.player1.uid === uid)
        || (room.playersList.player2 && room.playersList.player2.uid === uid)
        || (room.playersList.player3 && room.playersList.player3.uid === uid)
          ? (
            <Fragment>
              {room.playersList.player1 && room.playersList.player2 && room.playersList.player3 ? (
                <Link to={`/zole/${room.key}`} className="join-room-button button btn">
                  {t('roomsTable.join')}
                </Link>
              ) : (
                  <Button disabled={room.filled} className="join-room-button join-room-button-alreadyJoined" onClick={() => leaveRoom(room.key)}>
                  {t('roomsTable.leave')}
                </Button>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {room.globalParams.privateRoom ? (
                <Button
                  color="link"
                  className={`join-room-button ${((activeTournament && !finishedTournamentLastRound) || joinRoomClicked || joinedRoom) && 'disabled'}`}
                  onClick={() => joinPrivateRoomConfirm(room.key, 'player1')}
                  id={`join-room-button-${room.key}`}
                  onMouseEnter={() => onHover(room.key)}
                  onMouseLeave={() => onHoverLeave(room.key)}
                >
                  {t('roomsTable.join')}
                </Button>
              ) : (
                <Button
                    className={`join-room-button ${((activeTournament && !finishedTournamentLastRound) || joinRoomClicked || joinedRoom) && 'disabled'}`}
                  color="link"
                  onClick={() => joinRoomClickedFunc(room.key, 'player1')}
                  id={`join-room-button-${room.key}`}
                  onMouseEnter={() => onHover(room.key)}
                  onMouseLeave={() => onHoverLeave(room.key)}
                >
                  {t('roomsTable.join')}
                </Button>
              )}

              {(tooltipId && !removal) && (
                <>
                  {(activeTournament && !finishedTournamentLastRound) && (
                    <IconPopover fontMode={fontMode} text={t('common.inTournamentMessage')} placement="left" popoverOpen={!!(popoverOpen && popoverOpen[room.key])} targetId={tooltipId} />
                  )}
                  {joinedRoom && (
                    <IconPopover fontMode={fontMode} text={t('common:popovers.alreadyInRoom')} placement="left" popoverOpen={!!(popoverOpen && popoverOpen[room.key])} targetId={tooltipId} />
                  )}
                </>
              )}

              {/*  {(!removal && removal !== undefined && room && tooltipId && ((activeTournament && !finishedTournamentLastRound) || (joinedRoom && `join-room-button-${joinedRoom.key}` !== tooltipId))) && (
                <Popover container={'div > div'} popperClassName={`popover popover-font-${fontMode}`} placement="left" isOpen={!!(!removal && popoverOpen && popoverOpen[room.key])} target={tooltipId}>
                  <PopoverBody className="popover-body">
                    <div>
                      {(activeTournament && !finishedTournamentLastRound) &&
                        t('common.inTournamentMessage')
                      }
                      {(!activeTournament && joinedRoom) &&
                        t('common:popovers.alreadyInRoom')
                      }
                    </div>
                  </PopoverBody>
                </Popover>
              )} */}
            </Fragment>
          )}
      </td>
    </tr>
  );
// })
}, (prevProps, nextProps) => {
  // if (prevProps.room.key !== nextProps.room.key) {
  //   return false; // props are not equal -> update the component
  // }
  // if (prevProps.room.removal !== nextProps.room.removal) {
  //   return false; // props are not equal -> update the component
  // }
  // if (prevProps.popoverOpen !== nextProps.popoverOpen) {
  //   return false; // props are not equal -> update the component
  // }

  // if (prevProps.joinRoomClicked !== nextProps.joinRoomClicked) {
  //   return false; // props are not equal -> update the component
  // }

  // if (!isEqual(prevProps.room.playersList, nextProps.room.playersList)) {
  //   return false;
  // }

  // if (!isEqual(prevProps.joinedRoom, nextProps.joinedRoom)) {
  //   return false;
  // }

  // if (prevProps.uid !== nextProps.uid) {
  //   return false; // props are not equal -> update the component
  // }

  // if (prevProps.activeTournament !== nextProps.activeTournament) {
  //   return false;
  // }

  // if (prevProps.finishedTournamentLastRound !== nextProps.finishedTournamentLastRound) {
  //   return false;
  // }

  // return true;

  return false;
});

RoomsTableRowRender.propTypes = {
  room: PropTypes.shape(),
  joinedRoom: PropTypes.shape(),
  uid: PropTypes.string,
  leaveRoom: PropTypes.func.isRequired,
  activeTournament: PropTypes.string,
  popoverOpen: PropTypes.bool,
  joinRoomClicked: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  onHoverLeave: PropTypes.func.isRequired,
  joinRoomClickedFunc: PropTypes.func.isRequired,
  joinPrivateRoomConfirm: PropTypes.func.isRequired,
  tooltipId: PropTypes.string,
};

RoomsTableRowRender.defaultProps = {
  room: {},
  joinedRoom: null,
  uid: null,
  activeTournament: null,
  popoverOpen: false,
  joinRoomClicked: false,
  tooltipId: null,
};

export default RoomsTableRowRender;
