import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { connect } from 'react-redux';
import classNames from 'classnames';

import { filter, orderBy } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
// import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';


import Moment from 'react-moment';

import RoomLogs from './RoomLogs';
import Statistics from './MissedRoomsTotals';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import * as constants from '../../../constants/constants';

import {
  getSuspicisousTransactionsPlayers,
} from '../../../actions/admin';
import { setUserOnlineStatus } from '../../../actions/member';
import PaginationOld from '../Menu/PaginationOld';

const SuspiciousTransactionsPlayers = ({}) => {

  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  
  const suspiciousTransactionsPlayers = orderBy(useSelector(state => state.admin.suspiciousTransactionsPlayers), 'dateInitiated', constants.ORDER_BY_TYPE.desc);
  const allSuspiciousTransactions = useSelector(state => state.admin.allSuspiciousTransactions);

  const [openModal, setOpenModal] = useState(false);
  const [suspiciousPlayerData, setSuspiciousPlayerData] = useState([]);
  const [selectUserName, setSelectUserName] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 50;


  useEffect(() => {
    dispatch(getSuspicisousTransactionsPlayers());

    if (suspiciousTransactionsPlayers) {
      orderBy(suspiciousTransactionsPlayers, 'dateInitiated', constants.ORDER_BY_TYPE.desc);
    }
  }, []);

  const handleOpenModal = (date, userId, userName) => {
    if (allSuspiciousTransactions) {
      const filterData = filter(allSuspiciousTransactions, (filterItem) => { return filterItem.formatDate === date && filterItem.userUid === userId; });
      setSuspiciousPlayerData({ ...orderBy(filterData, 'dateInitiated', constants.ORDER_BY_TYPE.desc) });
      setSelectUserName(userName);
    }

    setOpenModal(true);
  };


  const toggle = () => {
    setOpenModal(prevOpenModal => !prevOpenModal);
    setSuspiciousPlayerData([]);
    setSelectUserName('');
  };


  const handlePagination = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  // console.log('suspiciousTransactionsPlayers', { suspiciousTransactionsPlayers });
  // console.log('suspiciousPlayerData', { suspiciousPlayerData });
  const pagesCount = Math.ceil(Object.keys(suspiciousTransactionsPlayers || {}).length / pageSize);

  return (
    <Fragment>
      <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
        <colgroup>
          <col span="1" className="" />
        </colgroup>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              {/* {t('date')} */}
              Date
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('playerId')} */}
              Player Id
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('name')} */}
              Name
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('count')} */}
              Count
            </th>
            <th style={{ textAlign: 'center' }}>
              {/* {t('view')} */}
              View
            </th>
          </tr>
        </thead>
        <tbody>
          {suspiciousTransactionsPlayers && Object.keys(suspiciousTransactionsPlayers)
            .sort((a, b) => { return b.formatDate - a.formatDate; })
            .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
            .map((key, index) => (
              <Fragment key={key}>
                <tr key={key} className={classNames({ 'allUsers-table-row odd': (index % 2 === 0), 'allUsers-table-row even ': index % 2 !== 0 })}>
                  <td className="allUsers-table-col">
                    {suspiciousTransactionsPlayers[key].formatDate}
                  </td>
                  <td className="allUsers-table-col">
                    {suspiciousTransactionsPlayers[key].userUid}
                  </td>
                  <th style={{ textAlign: 'center' }}>
                    {suspiciousTransactionsPlayers[key].name}
                  </th>
                  <td className="allUsers-table-col">
                    {suspiciousTransactionsPlayers[key].count}
                  </td>
                  <td className="allUsers-table-col">
                    <Button onClick={() => handleOpenModal(suspiciousTransactionsPlayers[key].formatDate, suspiciousTransactionsPlayers[key].userUid, suspiciousTransactionsPlayers[key].name)}>
                      {/* {t('view')} */}
                      View
                    </Button>
                  </td>
                </tr>
              </Fragment>
          ))}
        </tbody>
      </table>

      <PaginationOld
        show={true}
        currentPage={currentPage}
        pagesCount={pagesCount}
        handlePagination={handlePagination}
      />

      <Modal container={`div > div`} isOpen={openModal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {/* {t('data')} */}
          Data
        </ModalHeader>
        <ModalBody>
          <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('date')} */}
                  Date
                </th>
                <th>
                  {/* {t('playerId')} */}
                  Player Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('name')} */}
                  Name
                </th>
                <th style={{ textAlign: 'center' }}>
                  Producta Nr
                </th>
                <th style={{ textAlign: 'center' }}>
                  {/* {t('status')} */}
                  Status
                </th>
                <th style={{ textAlign: 'center' }}>
                  Provider
                </th>
              </tr>
            </thead>
            <tbody>
              {suspiciousPlayerData && Object.keys(suspiciousPlayerData)
                .sort((a, b) => { return b.dateInitiated - a.dateInitiated; })
                .map((key, index) => (
                  <Fragment key={key}>
                    <tr key={key}>
                      <td className="allUsers-table-col">
                        {suspiciousPlayerData[key].dateInitiated && (
                          <Moment format={constants.FORMAT_DATE_TYPE.fullDateMinute} locale={constants.sendLanguage.lv}>
                            {suspiciousPlayerData[key].dateInitiated}
                          </Moment>
                        )}
                      </td>
                      <td className="allUsers-table-col">
                        {suspiciousPlayerData[key].userUid}
                      </td>
                      <td className="allUsers-table-col">
                        {suspiciousPlayerData[key].userName || selectUserName}
                      </td>
                      <td className="allUsers-table-col">
                        {
                          filter(constants.SHOP_PRODUCT_TYPES, ['id', suspiciousPlayerData[key].productNr])[0].productNr
                        }
                      </td>
                      <td className="allUsers-table-col">
                        {suspiciousPlayerData[key].status}
                      </td>
                      <td className="allUsers-table-col">
                        {suspiciousPlayerData[key].provider}
                      </td>
                    </tr>
                  </Fragment>
              ))}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

SuspiciousTransactionsPlayers.propTypes = {
  member: PropTypes.shape({
    email: PropTypes.string,
  }),
  suspiciousPlayers: PropTypes.shape({}),
  suspiciousTransactionsPlayers: PropTypes.shape({}),
};

SuspiciousTransactionsPlayers.defaultProps = {
  member: {},
  suspiciousPlayers: [],
  suspiciousTransactionsPlayers: {},
};

export default SuspiciousTransactionsPlayers;
