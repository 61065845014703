import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* import {
  Media,
} from 'reactstrap'; */
import classNames from 'classnames';

import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';

import * as constants from '../../../../constants/constants';
import coinImg from '../../../../images/coin.svg';
import infoImg from '../../../../images/icons/info_chats.svg';

const PlayerInfo = React.memo(({
  name, bal, openPlayerModal, uid, position, myId, fontMode, posClassName,
}) => {
  const { t } = useTranslation('playerInfo');
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const normalBalance = bal > constants.PLAYER_ZOLE_RANGE.min;
  const lowBalance = bal >= constants.PLAYER_ZOLE_RANGE.maxmin && bal <= constants.PLAYER_ZOLE_RANGE.min;
  const negativeBalance = bal < constants.PLAYER_ZOLE_RANGE.maxmin;

  const STYLE_TOP_TABLE = classNames({
    'player-balance-text': normalBalance,
    'player-balance-text-orange': lowBalance,
    'player-balance-text-red': negativeBalance,
  });

  const STYLE_COIN_BLINK = classNames({
    'player-balance-coin': normalBalance,
    'player-balance-coin blink': lowBalance || negativeBalance,
  });

  const onHover = () => {
    if (bal <= constants.PLAYER_ZOLE_RANGE.min) {
      setPopoverOpen(true);
    }
  };

  const onHoverLeave = () => {
    setPopoverOpen(false);
  };

  return (
    <Fragment>
      <div className="player-name-wrapper">
        {/*  <div className="player-level">
          <div className="player-level-text">
            {lvl}
          </div>
        </div> */}
        <div className="player-name">
          {name}
        </div>
        <div className="player-additional-info" onClick={() => openPlayerModal(uid, position, posClassName)}>
          <Media src={infoImg} className="player-additional-info-img" />
        </div>
      </div>
      {
        uid === myId ? (
          <div className="player-balance">
            <div onMouseEnter={() => onHover()} onMouseLeave={() => onHoverLeave()} id="player-balance-popover">
              <Media src={coinImg} className={STYLE_COIN_BLINK} />
            </div>
            <Popover
              container={'div > div'}
              placement="left"
              isOpen={popoverOpen}
              target="player-balance-popover"
              onMouseEnter={() => onHover()}
              onMouseLeave={() => onHoverLeave()}
              className="player-balance-popover"
              popperClassName={`popover popover-font-${fontMode}`}
            >
              <PopoverBody
                onMouseLeave={() => onHoverLeave()}
                className="player-balance-popover-body"
              >
                <div className="player-balance-popover-body-header">
                  {negativeBalance ? t('negativeBalance') : t('lowBalance')}
                </div>
                <div className="player-balance-popover-body-main">
                  <div>
                    {negativeBalance ? t('negativeBalanceContent') : t('lowBalanceContent')}
                  </div>
                </div>
              </PopoverBody>
            </Popover>
            <div className={STYLE_TOP_TABLE} onMouseEnter={() => onHover()} onMouseLeave={() => onHoverLeave()}>
              {bal}
            </div>
          </div>
        ) : (
          <div className="player-balance">
            <Media src={coinImg} className="player-balance-coin" />
            <div className="player-balance-text">
              {bal}
            </div>
          </div>
        )
      }
    </Fragment>
  );
});

PlayerInfo.propTypes = {
//  lvl: PropTypes.number,
  name: PropTypes.string,
  bal: PropTypes.number,
  openPlayerModal: PropTypes.func.isRequired,
  uid: PropTypes.string,
  position: PropTypes.string,
  myId: PropTypes.string,
};

PlayerInfo.defaultProps = {
//  lvl: null,
  name: null,
  bal: null,
  //  openPlayerModal: false,
  uid: null,
  position: null,
  myId: null,
};

export default PlayerInfo;
