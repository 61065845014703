import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Progress from 'reactstrap/lib/Progress';

import { useTranslation } from 'react-i18next';

import RatingsInfo from './RatingsInfo';

// import stepupImg from '../../../../../../../images/stepup.webp';
import progresStepImg from '../../../../../../../images/icons/progress.svg';
import myInfoRatingsImg from '../../../../../../../images/redesign/player/info.svg';
import lightMyInfoRatingsImg from '../../../../../../../images/redesign/light-mode/player/info.svg';
import * as constants  from '../../../../../../../constants/constants';

const renderStep = (name, value) => (
  <Col className="d-flex align-items-center flex-column">
    <div>
      {name}
    </div>
    <div className="my-info-ratings-step-name">
      {value}
    </div>
    <div className="my-info-ratings-step-divider" />
  </Col>
);

const Ratings = React.memo(({screenMode}) => {
  const { t } = useTranslation('common');
  const [toggleRatingsInfo, setToggleRatingsInfo] = useState(false);
  // Tavs Reitings
  const [ratingsOpen, setRatingsOpen] = useState(false);

  const toggleRatingsModal = () => {
    setToggleRatingsInfo(prevToggleRatingsInfo => !prevToggleRatingsInfo);
  };

  return (
    <Row className={`my-info-ratings ${ratingsOpen && 'my-info-ratings-open'}`}>
      <div className="my-info-ratings-title" onClick={() => setRatingsOpen(!ratingsOpen)}>
        <div className="my-info-ratings-title-text">
          {t('myInfo.yourRating')}
          {ratingsOpen ? '▲' : '▼'}
        </div>
      </div>
      {ratingsOpen && (
        <>
          <Col xs="12" className="my-info-ratings-body">
            <div className="levelProgress">
              <Row className="my-info-ratings-steps flex-nowrap">
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-weak d-flex align-items-center flex-column"><div className="d-flex align-items-center">{t('myInfo.weak')}</div></div>, 1100)}
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-beginner">{t('myInfo.beginner')}</div>, 1200)}
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-untrained">{t('myInfo.untrained')}</div>, 1300)}
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-medium">{t('myInfo.medium')}</div>, 1400)}
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-good">{t('myInfo.good')}</div>, 1500)}
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active">{t('myInfo.great')}</div>, 1600)}
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-fan">{t('myInfo.fan')}</div>, 1700)}
                {renderStep(<div className="my-info-ratings-step my-info-ratings-step-pro">{t('myInfo.pro')}</div>, 1800)}
              </Row>
              <Progress
                color="link"
                value={65}
                className="my-info-ratings-progressbar"
              />

              <Media className="my-info-ratings-progressbar-marker stepup-img" src={progresStepImg} style={{ left: `${65}%` }} />
            </div>
          </Col>
          <Media className="my-info-ratings-icon" src={screenMode === constants.SCREEN_MODE.light ? lightMyInfoRatingsImg : myInfoRatingsImg} alt="x" onClick={() => toggleRatingsModal()}/>
          {
            toggleRatingsInfo && (
              <RatingsInfo toggleRatingsInfo={toggleRatingsInfo} toggleRatingsModal={toggleRatingsModal} />
            )
          }
        </>
      )}

    </Row>
  );
});

Ratings.propTypes = {
//  member: PropTypes.shape(),
//  t: PropTypes.func.isRequired,
};

Ratings.defaultProps = {
//  member: {},
};

export default Ratings;

/*
class Ratings extends React.Component {
  static propTypes = {
    giftsHistory: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    gifts: PropTypes.shape(),
    changeTab: PropTypes.func.isRequired,
    removeGift: PropTypes.func.isRequired,
    getGiftsHistory: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    giftsHistory: {},
    notificationSettings: {},
    gifts: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    //  pointsProgressBar: 0,
    };
  }

  componentDidMount() {
  //  const { member } = this.props;
  }

  renderStep = (name, value) => (
    <Col>
      <div>
        {name}
      </div>
      <div className="my-info-ratings-step-name">
        {value}
      </div>
    </Col>
  );

  render() {
    const { t } = this.props;

    return (
      <Row className="my-info-ratings">
        <div className="my-info-ratings-title">
          <div className="my-info-ratings-title-text"> Tavs Reitings </div>
        </div>
        <Col xs="12" className="my-info-ratings-body">
          <div className="levelProgress">
            <Row className="my-info-ratings-steps flex-nowrap">
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-weak">Vājais</div>, 1100)}
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-beginner">Iesācējs</div>, 1200)}
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-untrained">Netrenēts</div>, 1300)}
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-medium">Viduvējs</div>, 1400)}
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-good">Labais</div>, 1500)}
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-great my-info-ratings-step-active">Lieliskais</div>, 1600)}
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-fan">Fans</div>, 1700)}
              {this.renderStep(<div className="my-info-ratings-step my-info-ratings-step-pro">Pro</div>, 1800)}
            </Row>
            <Progress
              color="link"
              value={65}
              className="my-info-ratings-progressbar"
            />

            <Media className="my-info-ratings-progressbar-marker stepup-img" src={stepupImg} style={{ left: `${65}%` }} />
          </div>
        </Col>
      </Row>
    );
  }
}


export default Ratings; */
