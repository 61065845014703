import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import startTournamentSound from '../../../sounds/click_feedback.flac';
import closeImg from '../../../images/icons/close.png';

import {
  returnToTournament,
} from '../../../actions/tournaments';
import {
    leaveRoomMenu,
  } from '../../../actions/room';

import {
  FONT_MODE
} from '../../../constants/constants';

import { size } from 'lodash';

class TournamentNotification extends React.Component {
  static propTypes = {
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    myTournamentsData: PropTypes.shape(),
    uid: PropTypes.string,
    offset: PropTypes.number,
    t: PropTypes.func.isRequired,
    returnToTournamentFunc: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    joinedRooms: PropTypes.shape(),
    leaveRoom: PropTypes.func.isRequired,
  }

  static defaultProps = {
    tournaments: [],
    myTournamentsData: {},
    uid: null,
    offset: 0,
    fontMode: FONT_MODE.normal,
    joinedRooms: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      seconds: null,
      tournament: null,
    };

    this.startTournamentAudio = new Audio(startTournamentSound);
  }

  componentWillMount() {
    this.intervalID = setInterval(() => {
      const { offset, myTournamentsData, tournaments, leaveRoom, joinedRooms, t } = this.props;
      const { tournament } = this.state;

      if (!tournament) {
        if (myTournamentsData) {
          if (tournaments) {
            const { ignoredModal } = this.state;

            if (myTournamentsData.tournamentId && ignoredModal !== myTournamentsData.tournamentId) { //myTournamentsData.paused &&
              const curTournament = tournaments.find(item => item.id === myTournamentsData.tournamentId);

              if (curTournament && curTournament.currentState === 'registration') {
                if (curTournament && curTournament.startDate && curTournament.startDate - (1000 * 30) < (Date.now() + offset)) {
                  this.setState({ openModal: true, ignoredModal: myTournamentsData.tournamentId, tournament: curTournament });

                  const playPromise = this.startTournamentAudio.play();
                  if (playPromise !== undefined) {
                    playPromise
                      .then(_ => {
                        console.log("audio played auto");
                      })
                      .catch(error => {
                        console.log("playback prevented");
                      });
                  }
                }
              } else {
                const { openModal, tournament } = this.state;
                if (openModal || tournament) {
                  this.setState({ openModal: false, ignoredModal: null, tournament: null });
                }
              }
            } else if (!myTournamentsData) {
              const { openModal, tournament } = this.state;
              if (openModal || tournament) {
                this.setState({ openModal: false, ignoredModal: null, tournament: null });
              }
            }

            if (myTournamentsData.tournamentId) {
              const curTournament = tournaments.find(item => item.id === myTournamentsData.tournamentId);
              let joinedRoom;
              if (size(joinedRooms) > 0) {
                Object.keys(joinedRooms).map((joinedRoomKey) => {
                  joinedRoom = { key: joinedRoomKey };
                  return null;
                });

                if(curTournament && curTournament.currentState === 'registration') {
                  if (curTournament && curTournament.startDate && curTournament.startDate - (1000 * 60 * 5) < (Date.now() + offset)) {
                    leaveRoom(joinedRoom.key);
                    this.setState({
                      errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
                    });
                  }
                }
              }
            }
          }
        }
      }

      if (tournament && tournament.startDate) {
        const gameLength = tournament.startDate - (Date.now() + offset);
        const seconds = Math.round((gameLength % (1000 * 60)) / 1000);

        if (seconds >= 0) {
          this.setState({ seconds });
        } else {
          this.setState({ openModal: false, ignoredModal: null, tournament: null, seconds: null });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  returnToTournament = () => {
    const { returnToTournamentFunc, myTournamentsData } = this.props;

    returnToTournamentFunc(myTournamentsData.tournamentId).then(() => {
      this.setState({
        openModal: false,
      });
    });
  }

  close = () => {
    const { myTournamentsData } = this.props;

    if (myTournamentsData && myTournamentsData.tournamentId) {
      this.setState({
        openModal: false,
        ignoredModal: myTournamentsData.tournamentId,
      });
    } else {
      this.setState({
        openModal: false,
        ignoredModal: null,
      });
    }
  }

  closeErrorModal = () => {
    this.setState({
      errorModalOpen: false, errorModalText: null, errorModalHeader: null
    });
  }

  render() {
    const {
      t,
      uid,
      fontMode,
      offset
    } = this.props;

    const {
      openModal,
      tournament,
      seconds,
      errorModalOpen,
      errorModalText,
      errorModalHeader,
    } = this.state;

    if (!(tournament && uid)) {
      return null;
    }

    return (
      <Fragment>
        {
          seconds ? (
            <div className="notification">
              {(tournament && uid) ? (
                <Modal container={'div > div'} returnFocusAfterClose={false} size="lg" isOpen={openModal} toggle={this.close} className={`old-modal root-font-${fontMode}`}>
                  <ModalHeader
                    toggle={this.close}
                    close={
                      <Media src={closeImg} className="notification-header-close" alt="X" onClick={this.close} />
                    }
                  >
                    {t('home.notifications')}
                  </ModalHeader>
                  <ModalBody className="text-center">
                    {t('tournaments.startsSoon', { time: seconds || null })}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="link" className="modal-footer-button" onClick={this.returnToTournament}>{t('tournaments.ready')}</Button>
                  </ModalFooter>
                </Modal>
              ) : (null)}
            </div>
          ) : null
        }
        
        <Modal
            container={'div > div'}
            isOpen={errorModalOpen}
            toggle={this.closeErrorModal}
            className={`notification old-modal root-font-${fontMode}`}
          >
            <ModalHeader
              className="notification-header"
              close={(
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={this.closeErrorModal}
                />
              )}
            >
              {errorModalHeader}
            </ModalHeader>
            <ModalBody className="notification-body">
              {errorModalText}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button
                color="link"
                className="btn notification-footer-button"
                onClick={this.closeErrorModal}
              >
                {t('common.ok')}
              </Button>
            </ModalFooter>
          </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  uid: state.member.uid || '',
  offset: state.member.offset || 0,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
  myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
  joinedRooms: state.member.joinedRooms || null,
});

const mapDispatchToProps = {
  returnToTournamentFunc: returnToTournament,
  leaveRoom: leaveRoomMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'notifications'])(TournamentNotification));
