

import React from 'react';
import Moment from 'react-moment';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const UserTournaments = ({
    userTournamentsModalKeys,
    toggleUserTournamentsModal,
    userTournaments,
    openResultsModalFunc,
    openPlayersModalFunc,
}) => {
    return (
        <Modal container={`div > div`} size="xl" isOpen={!!userTournamentsModalKeys} toggle={() => toggleUserTournamentsModal(null)} style={{ maxWidth: '90vw'}}>
            <ModalHeader toggle={() => toggleUserTournamentsModal(null)}>
                User Tournaments
            </ModalHeader>
            <ModalBody>
                <table className="w-100 text-center">
                    <thead>
                        <tr>
                            <th>
                                Title
                            </th>
                            <th>
                                ID
                            </th>
                            <th>
                                Date
                            </th>
                            <th>
                                Results
                            </th>
                            <th>
                                Players
                            </th>
                            <th>
                                Bet
                            </th>
                            <th>
                                Fee
                            </th>
                            <th>
                                Bonus
                            </th>
                            <th>
                                Rounds
                            </th>
                            <th>
                                Round Length
                            </th>
                            <th>
                                Speed
                            </th>
                            <th>
                                Type
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        {userTournamentsModalKeys && userTournamentsModalKeys.map((key) => (
                            <tr key={key}>
                                <td>
                                    {userTournaments[key].name}
                                </td>
                                <td>
                                    {key}
                                </td>
                                <td>
                                    <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                        {userTournaments[key].startDate}
                                    </Moment>
                                </td>
                                <td>
                                    <Button color="primary" onClick={() => openResultsModalFunc(key)}>
                                        Results
                                    </Button>
                                </td>
                                <td>
                                    <Button color="primary" onClick={() => openPlayersModalFunc(key, userTournaments[key].currentState)}>
                                        Players
                                    </Button>
                                </td>
                                <td>
                                    {userTournaments[key].bet}
                                </td>
                                <td>
                                    {userTournaments[key].entryFee}
                                </td>
                                <td>
                                    {userTournaments[key].bonus}
                                </td>
                                <td>
                                    {userTournaments[key].rounds}
                                </td>
                                <td>
                                    {userTournaments[key].roundLength}
                                </td>
                                <td>
                                    {userTournaments[key].lightningGame && 'lightning'}
                                    {userTournaments[key].fastGame && 'fast'}
                                    {userTournaments[key].unlimitedGame && 'unlimited'}
                                    {!userTournaments[key].unlimitedGame && !userTournaments[key].lightningGame && !userTournaments[key].fastGame && 'slow'}
                                </td>
                                <td>
                                    {userTournaments[key].gameType === 'P' && (userTournaments[key].smallGame ? 'PM' : 'P')}
                                    {userTournaments[key].gameType === 'G' && (userTournaments[key].smallGame ? 'MG' : 'G')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => toggleUserTournamentsModal(null)}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserTournaments;