import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';

import Ratings from './Components/MyInfo/Ratings';
import Statistics from './Components/MyInfo/Statistics';
import GiftsHistory from './Components/MyInfo/GiftsHistory';
// import Friends from './Friends';
import ScrollAreaWrapper from '../../Components/ScrollAreaWrapper';

import * as constants from '../../../../../constants/constants';

import { removeGift, getGiftsHistory, getPercentPosInfo, getPercentGPlayedInfo, getPercentGWonInfo, getPercentTotalPntsInfo, getPercentBalInfo } from '../../../../../actions/member';

class MyInfo extends Component {
  static propTypes = {
    member: PropTypes.shape().isRequired,
    achievementsNotification: PropTypes.bool,
    gifts: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    getGiftsHistoryFunction: PropTypes.func.isRequired,
    removeGiftFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    getPercentPosInfoFunction: PropTypes.func.isRequired,
    getPercentGPlayedInfoFunction: PropTypes.func.isRequired,
    getPercentGWonInfoFunction: PropTypes.func.isRequired,
    getPercentTotalPntsInfoFunction: PropTypes.func.isRequired,
    getPercentBalInfoFunction: PropTypes.func.isRequired,
    betterPos: PropTypes.number,
    betterGPlayed: PropTypes.number,
    betterGWon: PropTypes.number,
    betterTotalPnts: PropTypes.number,
    betterBal: PropTypes.number,
    screenMode: PropTypes.string,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    achievementsNotification: false,
    gifts: {},
    notificationSettings: {},
    betterPos: 0,
    betterGPlayed: 0,
    betterGWon: 0,
    betterTotalPnts: 0,
    betterBal: 0,
    screenMode: constants.SCREEN_MODE.normal,
    fontMode: constants.FONT_MODE.normal
    //  leaderboardData: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      //  leaderboardPosition: '',
    };
    this.getRatingData = this.getRatingData.bind(this);

  }

  getRatingData = () => {
    const { getPercentPosInfoFunction, getPercentGPlayedInfoFunction, getPercentGWonInfoFunction, getPercentTotalPntsInfoFunction, getPercentBalInfoFunction } = this.props;
    getPercentPosInfoFunction();
    getPercentGPlayedInfoFunction();
    getPercentGWonInfoFunction();
    getPercentTotalPntsInfoFunction();
    getPercentBalInfoFunction();
  }

  componentDidMount() {
    this.getRatingData();
  }

  componentDidUpdate(prevProps) {
    const { fontMode, screenMode } = this.props;
    if (prevProps.fontMode !== fontMode || prevProps.screenMode !== screenMode) {
      this.getRatingData();
    }
  }

  render() {
    const {
      screenMode, member, t, changeTab, achievementsNotification, gifts, removeGiftFunction, getGiftsHistoryFunction, notificationSettings, betterPos, betterGPlayed, betterGWon, betterTotalPnts, betterBal,
    } = this.props;

    return (
      <>
        <Helmet>
          <title>
            Zole - {t('route.myInfo')}
          </title>
        </Helmet>
        <div className="layout-body layout-body-background">
          <div className="layout-body-top">
            <Row className="layout-subheader">
              <div className="layout-subheader-link">
                <Button color="link" className="layout-subheader-link-text layout-subheader-link-text-active" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                  {t('common:myInfo.myInfo')}
                </Button>
              </div>
              <div className="layout-subheader-link my-info-friends-button">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('common:myInfo.friends')}
                </Button>
              </div>
              <div className="layout-subheader-link my-info-ignoredPlayers-button-desktop">
                <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('common:myInfo.ignoredPlayers')}
                </Button>
              </div>
              <div className="layout-subheader-link my-info-achievements-button-desktop">
                <Button color="link" className={classNames("layout-subheader-link-text")} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('common:myInfo.achievements')}
                </Button>
              </div>
              <div className="layout-subheader-link my-info-results-button-desktop">
                <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('common:myInfo.results')}
                </Button>
              </div>
              <div className="layout-subheader-link my-info-weeklyStatus-button">
                <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('common:myInfo.weeklyStatus')}
                </Button>
              </div>
              <div className="layout-subheader-link my-info-gameLogs-button">
                <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('common:myInfo.gameLogs')}
                </Button>
              </div>
              <div className="layout-subheader-link my-info-gifts-button">
                <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfoGifts)}>
                  {t('common:myInfo.gifts')}
                </Button>
              </div>
            </Row>
          </div>
          <div className="layout-body-main">
            <div
              className="my-info-scrollarea"
              //  className="ach-table-scrollarea"
              show
              rightOffset={0}
              topOffset={0}
              bottomOffset={0}
            >

              <Ratings
                changeTab={changeTab}
                screenMode={screenMode}
                t={t}
              />
              <Statistics
                changeTab={changeTab}
                t={t}
                member={member}
                betterPos={betterPos}
                betterGPlayed={betterGPlayed}
                betterGWon={betterGWon}
                betterTotalPnts={betterTotalPnts}
                betterBal={betterBal}
                screenMode={screenMode}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  gifts: state.rooms.gifts || {},
  betterPos: state.member.betterPos || 0,
  betterGPlayed: state.member.betterGPlayed || 0,
  betterGWon: state.member.betterGWon || 0,
  betterTotalPnts: state.member.betterTotalPnts || 0,
  betterBal: state.member.betterBal || 0,
});

const mapDispatchToProps = {
  removeGiftFunction: removeGift,
  getGiftsHistoryFunction: getGiftsHistory,
  getPercentPosInfoFunction: getPercentPosInfo,
  getPercentGPlayedInfoFunction: getPercentGPlayedInfo,
  getPercentGWonInfoFunction: getPercentGWonInfo,
  getPercentTotalPntsInfoFunction: getPercentTotalPntsInfo,
  getPercentBalInfoFunction: getPercentBalInfo,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MyInfo));
