import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Media from 'reactstrap/lib/Media';

import {
  getOtherUserInfo,
} from '../../../../../../actions/room';

import infoImg from '../../../../../../images/redesign/player/info.svg';
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';

import buttonClickedSound from '../../../../../../sounds/click_feedback.flac';
import * as constants from '../../../../../../constants/constants';
import IconPopover from '../../../Components/Components/IconPopover';

class PlayerInfoButton extends React.Component {
  static propTypes = {
    fetchUserInfo: PropTypes.func.isRequired,
    uid: PropTypes.string,
    name: PropTypes.string,
    gifts: PropTypes.shape(),
    playerInfo: PropTypes.shape(),
    posClassName: PropTypes.string,
    photo: PropTypes.string,
    t: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    position: PropTypes.string,
    toggleGiftsModal: PropTypes.func,
    tournamentRoom: PropTypes.bool,
  };

  static defaultProps = {
    uid: null,
    name: '',
    gifts: {},
    playerInfo: {},
    posClassName: null,
    photo: null,
    screenMode: constants.SCREEN_MODE.normal,
    position: null,
    tournamentRoom: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      infoPopoverOpen: false,
    };

    this.buttonClickedAudio = new Audio(buttonClickedSound);
  }

  closePlayerModal = () => {
    this.setState({ popoverOpen: false });
  }

  setInfoPopoverOpen = (isOpen) => {
    this.setState({infoPopoverOpen: isOpen});
  }

  render() {
    const {
      uid,
      name,
      gifts,
      playerInfo,
      posClassName,
      photo,
      t,
      screenMode,
      position,
      toggleGiftsModal,
      tournamentRoom,
      openPlayerModal,
    } = this.props;

    const { popoverOpen, infoPopoverOpen, screenWidth, screenHeight } = this.state;

    return (
      <>
        <Media
          className="player-info-icon"
          src={screenMode !== constants.SCREEN_MODE.light ? infoImg : lightInfoImg}
          alt=""
          onClick={() => openPlayerModal(uid, position, posClassName, photo, name)}
          onMouseEnter={() => this.setInfoPopoverOpen(true)}
          onMouseLeave={() => this.setInfoPopoverOpen(false)}
          id={`info-popover-${uid}`}
        />
        <IconPopover text={t('common:popovers.playerInfo')} placement="right" targetId={`info-popover-${uid}`} popoverOpen={infoPopoverOpen} addClassName="player-info-popover" />
      </>
    );
  }
}

const mapStateToProps = state => ({
  playerInfo: state.rooms.playerInfo || null,
  gifts: state.rooms.gifts || null,
});

const mapDispatchToProps = {
  fetchUserInfo: getOtherUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['game', 'common'])(PlayerInfoButton));
