import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Container from 'reactstrap/lib/Container';
import {
  isMobile,
} from 'react-device-detect';

import { Helmet } from 'react-helmet';

import {
  SCREEN_MODE,
  NEW_DESIGN_ROOT_STYLE,
  FONT_MODE,
} from '../../../../../constants/constants';
import { isOnHome } from '../../../../../utils/url';

const Template = ({
  pageTitle, containerClassName, state, children, isFullscreen, screenMode, fontMode, addScreenModeRootClass,
}) => {
  let localNewDesign;

  try {
    localNewDesign = localStorage.getItem('newDesign'); //x
  } catch (err) {
    console.log(err);
  }

  return (
    !isMobile ? (
      <div
        className={classNames(`root-class-design20 root-font-${isOnHome(window.location.href) ? FONT_MODE.large : fontMode}`, {
          'fullscreen-new-design-landing': isFullscreen && containerClassName === NEW_DESIGN_ROOT_STYLE.new_design,
          [`root-class-design20-${screenMode}`]: addScreenModeRootClass,
        })}
        style={isFullscreen ? (containerClassName === NEW_DESIGN_ROOT_STYLE.new_design ? { height: '100%', width: '100%', minWidth: '375px' } : { height: '100vh', width: '100vw' }) : {}}
      >
        <div className={`${isFullscreen ? (containerClassName === NEW_DESIGN_ROOT_STYLE.new_design ? 'landing-overflow-manage-redesign' : 'overflow-manage-redesign') : ''}`} >
          <Container fluid className={`${containerClassName} ${isFullscreen ? 'root-class-design20-fullscreen root-class-design20-fullscreen-menu' : ''}`}>
            <div className={
              classNames(localNewDesign ? 'game-bg' : 'game-bg-old', {
                'loading-bg': state.isLoading,
              })}
            />
            {localNewDesign && containerClassName !== NEW_DESIGN_ROOT_STYLE.new_design && containerClassName !== NEW_DESIGN_ROOT_STYLE.new_login ? (
              <div className="lobby-bg-table" />
            ) : containerClassName === 'new-design-login' ? (
              <div className="lobby-bg-table-chair" />
            ) : (null)}
            {/* <Helmet>
              <title>{pageTitle}</title>
            </Helmet> */}

            <div style={{ height: '100%', zIndex: 2, position: 'relative' }}>
              <div style={{ padding: 0, position: 'relative', height: '100%' }}>{children}</div>
            </div>
          </Container>
        </div>
      </div>
    ) : (
      <div className={`root-class-design20 root-class-design20-${screenMode}`} style={{ height: '100%', width: '375px !important', minWidth: '375px' }}>
        <div className="landing-overflow-manage-redesign" style={{ width: '375px !important' }}>
          <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <div className={
              classNames(localNewDesign ? 'game-bg' : 'game-bg-old', {
                'loading-bg': state.isLoading,
              })}
            />
            {/* <div className={classNames(`root-class-design20 root-font-${fontMode}`, {'fullscreen-new-design-landing': isFullscreen && containerClassName === NEW_DESIGN_ROOT_STYLE.new_design})} style={isFullscreen ? (containerClassName === NEW_DESIGN_ROOT_STYLE.new_design ? { height: '100%', width: '100%', minWidth: '375px' } : { height: '100vh', width: '100vw ' }) : {}}>
      <div className={`${isFullscreen ? (containerClassName === NEW_DESIGN_ROOT_STYLE.new_design ? 'landing-overflow-manage-redesign' : 'overflow-manage-redesign') : ''}`} >
        <Container fluid className={`${containerClassName} ${isFullscreen ? 'root-class-design20-fullscreen root-class-design20-fullscreen-menu' : ''}`}> */}
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>

            <div>{children}</div>
          </Container>
        </div>
      </div>
    )
  );
};

Template.propTypes = {
  pageTitle: PropTypes.string,
  isFullscreen: PropTypes.bool,
  children: PropTypes.element.isRequired,
  containerClassName: PropTypes.string,
  state: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  screenMode: PropTypes.string,
  fontMode: PropTypes.string,
  addScreenModeRootClass: PropTypes.bool,
};

Template.defaultProps = {
  pageTitle: 'Zole',
  isFullscreen: false,
  containerClassName: '',
  state: {},
  screenMode: SCREEN_MODE.dark,
  fontMode: FONT_MODE.normal,
  addScreenModeRootClass: false,
};

const mapStateToProps = state => ({
  state: state.state || {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
