import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import Dropdown from 'reactstrap/lib/Dropdown';
import classNames from 'classnames';

import { withRouter } from 'react-router-dom';

import { size, map, get, sortBy, includes } from 'lodash';

import AllUsers from './AllUsers';
// import AllVipUsers from './AllVipUsers';
import AllBans from './AllBans';
import AllTransactions from './AllTransactions';
import AllTournaments from './AllTournaments';
import AllGiftCodes from './AllGiftCodes';
import ShopDiscounts from './ShopDiscounts';
import UserMessages from './UserMessages';
import AdminsList from './AdminsList';
import EventsBanner from './EventsBanner';
import EmailEditor from './EmailEditor';
import NewVersionEditor from './NewVersionEditor';
import Blogs from './Blogs';
import BotsList from './BotsList';
import Statistics from './Statistics';
import Settings from './Settings';
import SuspiciousPlayers from './SuspiciousPlayers';
import Feedback from './Feedback/Feedback';

import AdminLogsContainer from '../../../containers/Admin/AdminLogs';
import AdminLogsComponent from './AdminLogsRooms';

import MissedTurnsTable from './MissedTurnsTable';
import AdminActionLogs from './AdminActionLogs';

import MissedRoomsStatistics from './MissedRoomsStatistics';
import * as constants from '../../../constants/constants';

// import FirebaseLogs from './FirebaseLogs';

import Notifications from './Notifications';
import MultiAccounts from './MultiAccounts';

import Hints from './Hints';
import ReportedPlayers from './ReportedPlayers';
import ReportedMessages from './ReportedMessages';
import PlayerRoomMessages from './PlayerRoomMessages';
import LogRocket from './LogRocket';
import AdminPlatform from './AdminPlatform';
import TextKeys from './TextKeys';
import CreateSurvey from './CreateSurvey';
import SurveyResults from './SurveyResults';
import Campaigns from './Campaigns';
import PossibleBotPlayers from './PossibleBotPlayers';
import Profanities from './Profanities';
import HelpEditor from './HelpEditor/HelpEditor';
import AutoCompensations from './AutoCompensations';
import TournamentsUsers from './TournamentsUsers';

class AdminPanel extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    t: PropTypes.func.isRequired,
    allUsers: PropTypes.shape({}),
    usersCount: PropTypes.number,
    logsCount: PropTypes.number,
    filteredUsers: PropTypes.shape({}),
    nonVerifiedUsers: PropTypes.arrayOf(PropTypes.shape({})),
    userGamesHistory: PropTypes.arrayOf(PropTypes.shape({})),
    userBalanceHistory: PropTypes.arrayOf(PropTypes.shape({})),
    userPointsHistory: PropTypes.arrayOf(PropTypes.shape({})),
    allPayments: PropTypes.arrayOf(PropTypes.shape({})),
    paymentsCount: PropTypes.number,
    removedPaymentsCount: PropTypes.number,
    completedPaymentsCount: PropTypes.number,
    //  allVipUsers: PropTypes.shape({}),
    allBans: PropTypes.arrayOf(PropTypes.shape({})),
    bansCount: PropTypes.number,
    allTransactions: PropTypes.shape({}),
    allTournaments: PropTypes.shape({}),
    warningPlayers: PropTypes.shape({}),
    allReportedMessages: PropTypes.shape({}),
    allGiftCodes: PropTypes.shape([]),
    claimedPlayers: PropTypes.shape({}),
    tournamentPlayers: PropTypes.arrayOf(PropTypes.shape({})),
    tournamentPlayerHistory: PropTypes.shape({}),
    tournamentResults: PropTypes.shape({}),
    activeMessages: PropTypes.shape({}),
    readMessages: PropTypes.arrayOf(PropTypes.shape({})),
    unreadMessages: PropTypes.shape({}),
    chatMessages: PropTypes.shape({}),
    chatMessagesUserLastLanguage: PropTypes.string,
    admins: PropTypes.shape({}),
    bots: PropTypes.shape({}),
    adminActionData: PropTypes.arrayOf(PropTypes.shape({})),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    loading: PropTypes.bool,
    deleteUser: PropTypes.func.isRequired,
    swapToUser: PropTypes.func.isRequired,
    manualyVerifyUser: PropTypes.func.isRequired,
    toggleLogRocket: PropTypes.func.isRequired,
    toggleSmartlook: PropTypes.func.isRequired,
    resetActiveRoom: PropTypes.func.isRequired,
    blockUser: PropTypes.func.isRequired,
    unblockUser: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    addBalance: PropTypes.func.isRequired,
    addPoints: PropTypes.func.isRequired,
    fetchAllUsers: PropTypes.func.isRequired,
    fetchFilteredUsers: PropTypes.func.isRequired,
    fetchNonVerifiedUsers: PropTypes.func.isRequired,
    fetchUsersRange: PropTypes.func.isRequired,
    //  fetchAllVipUsers: PropTypes.func.isRequired,
    addGiftCode: PropTypes.func.isRequired,
    editGiftCode: PropTypes.func.isRequired,
    deleteGiftCode: PropTypes.func.isRequired,
    fetchClaimedPlayers: PropTypes.func.isRequired,
    editBan: PropTypes.func.isRequired,
    editTournament: PropTypes.func.isRequired,
    addTournament: PropTypes.func.isRequired,
    deleteTournament: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentPlayerHistory: PropTypes.func.isRequired,
    fetchTournamentResults: PropTypes.func.isRequired,
    answerSupportMessage: PropTypes.func.isRequired,
    supportMessageFileUpload: PropTypes.func.isRequired,
    updateAvatarFileUpload: PropTypes.func.isRequired,
    setSupportMessageAsResponded: PropTypes.func.isRequired,
    messageAll: PropTypes.func.isRequired,
    forceCloseTournament: PropTypes.func.isRequired,
    getUserMessages: PropTypes.func.isRequired,
    cancelUserMessages: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    fetchPaymentsRange: PropTypes.func.isRequired,
    fetchCompletedPaymentsRange: PropTypes.func.isRequired,
    fetchInitiatedPaymentsRange: PropTypes.func.isRequired,
    fetchFilteredTransactions: PropTypes.func.isRequired,
    fetchUserGamesHistory: PropTypes.func.isRequired,
    fetchUserBalanceHistory: PropTypes.func.isRequired,
    fetchUserPointsHistory: PropTypes.func.isRequired,
    fetchBansRange: PropTypes.func.isRequired,
    fetchAdminActionLog: PropTypes.func.isRequired,
    //  toggleSmartLook: PropTypes.func.isRequired,
    fetchAdmins: PropTypes.func.isRequired,
    fetchBots: PropTypes.func.isRequired,
    updateRole: PropTypes.func.isRequired,
    fetchStatistic: PropTypes.func.isRequired,
    setMobileVersion: PropTypes.func.isRequired,
    addNewEvent: PropTypes.func.isRequired,
    allEvents: PropTypes.shape({}),
    searchUid: PropTypes.string,
    fontMode: PropTypes.string,
    fetchTournamentUsers: PropTypes.func.isRequired,
    tournamentUsers: PropTypes.shape({}),
    userTournaments: PropTypes.shape({}),
    fetchAutoCompensations: PropTypes.func.isRequired,
    disableAutoCompensationFunc: PropTypes.func.isRequired,
    autoCompensations: PropTypes.shape({}),
    totalTournamentUserCount: PropTypes.number,
    allGifts: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
    allUsers: {},
    usersCount: 0,
    logsCount: 0,
    filteredUsers: {},
    nonVerifiedUsers: [],
    userGamesHistory: [],
    userBalanceHistory: [],
    userPointsHistory: [],
    allPayments: [],
    paymentsCount: 0,
    removedPaymentsCount: 0,
    completedPaymentsCount: 0,
    //  allVipUsers: {},
    allGiftCodes: {},
    claimedPlayers: {},
    allBans: [],
    bansCount: 0,
    allTransactions: {},
    allTournaments: {},
    warningPlayers: {},
    allReportedMessages: {},
    tournamentPlayers: {},
    tournamentPlayerHistory: {},
    tournamentResults: [],
    activeMessages: {},
    readMessages: [],
    unreadMessages: {},
    chatMessages: {},
    admins: {},
    bots: {},
    adminActionData: {},
    loading: false,
    allEvents: {},
    searchUid: null,
    fontMode: constants.FONT_MODE.normal,
    allGifts: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.searchUid ? '1' : '0',
      dropIsOpen: false,
      toggleActiveTab: null
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { searchUid } = this.props;
    if (searchUid) {
      this.setState({
        activeTab: '1',
      });
    }
  }

  toggle(tab) {
    const { history } = this.props
    const { activeTab, toggleActiveTab } = this.state;
    const { pathname } = window.location;
    const regex = /\/admin\/(.+)/;

    if (pathname) {
      const match = pathname.match(regex);
      if (match && match[1]) {
        history.push('/admin');
      }
    }
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        toggleActiveTab: null,
      });
    }
  }

  handleDropdown(toggleTab) {
    const { toggleActiveTab } = this.state;
    if (toggleActiveTab === toggleTab) {
      this.setState({
        toggleActiveTab: null,
      })
    } else {
      this.setState({
        toggleActiveTab: toggleTab,
      })
    }
  }

  //  toggleSmartLook = () => {
  //    const { smartLookStatus, toggleSmartLook } = this.props;

  //    toggleSmartLook(!smartLookStatus);
  //  }

  render() {
    const {
      t,
      allUsers,
      usersCount,
      logsCount,
      filteredUsers,
      nonVerifiedUsers,
      //  allVipUsers,
      allBans,
      //  allTransactions,
      deleteUser,
      swapToUser,
      manualyVerifyUser,
      toggleLogRocket,
      toggleSmartlook,
      resetActiveRoom,
      blockUser,
      unblockUser,
      editUser,
      addBalance,
      addPoints,
      fetchAllUsers,
      fetchUsersRange,
      fetchFilteredUsers,
      fetchNonVerifiedUsers,
      //  fetchAllVipUsers,
      allGiftCodes,
      addGiftCode,
      editGiftCode,
      deleteGiftCode,
      claimedPlayers,
      fetchClaimedPlayers,
      editBan,
      allTournaments,
      warningPlayers,
      allReportedMessages,
      editTournament,
      addTournament,
      deleteTournament,
      tournamentPlayers,
      fetchTournamentPlayers,
      tournamentPlayerHistory,
      fetchTournamentPlayerHistory,
      tournamentResults,
      fetchTournamentResults,
      showNotification,
      answerSupportMessage,
      supportMessageFileUpload,
      updateAvatarFileUpload,
      setSupportMessageAsResponded,
      messageAll,
      forceCloseTournament,
      //  activeMessages,
      readMessages,
      unreadMessages,
      chatMessages,
      chatMessagesUserLastLanguage,
      getUserMessages,
      cancelUserMessages,
      allPayments,
      paymentsCount,
      removedPaymentsCount,
      completedPaymentsCount,
      initiatedPaymentsCount,
      fetchPaymentsRange,
      fetchCompletedPaymentsRange,
      fetchInitiatedPaymentsRange,
      fetchFilteredTransactions,
      fetchBansRange,
      bansCount,
      fetchUserGamesHistory,
      fetchUserBalanceHistory,
      fetchUserPointsHistory,
      userGamesHistory,
      userBalanceHistory,
      userPointsHistory,
      smartLookStatus,
      missedTurnsCount,
      fetchmissedTurnsCount,
      missedTurnsData,
      fetchmissedTurnsData,
      fetchAdmins,
      fetchBots,
      updateRole,
      fetchStatistic,
      admins,
      bots,
      fetchAdminActionLog,
      adminActionData,
      statistics,
      setMobileVersion,
      getMobileVersion,
      settings,
      setTreasureChestEnabled,
      getTreasureChestEnabled,
      loading,
      searchUid,
      fontMode,
      toggleNewDesign,
      newDesign,
      screenMode,
      toggleScreenMode,
      fetchTournamentUsers,
      tournamentUsers,
      userTournaments,
      fetchAutoCompensations,
      autoCompensations,
      disableAutoCompensationFunc,
      totalTournamentUserCount,
      allGifts,
    } = this.props;
    const { activeTab, dropIsOpen, toggleActiveTab } = this.state;

    return (
      <div className="admin-panel">
        <Nav tabs style={{ color: '#fff' }}>
          {constants.ADMIN_PANEL_NAVIGATION && map(constants.ADMIN_PANEL_NAVIGATION, (item, key) => {
            return (
              item.sublinks ? (
                <Dropdown nav inNavbar isOpen={item.label === toggleActiveTab} toggle={() => this.handleDropdown(item.label)} key={key}>
                  <DropdownToggle nav caret className={classNames("nav-link dropdown-toggle", {"admin-panel-nav-link active": includes(constants.ADMIN_MAIN_MENU[item.label], activeTab)})}>{item.title}</DropdownToggle>
                  <DropdownMenu className="dropdown-menu">
                    {item.sublinks.map((submenu, index) => (
                      <DropdownItem key={index}>
                        <NavLink onClick={() => this.toggle(submenu.id)} className={classNames('dropdown-item', { 'admin-panel-nav-link active': (activeTab === item.id), 'admin-panel-nav-link ': activeTab !== item.id })} >{submenu.title}</NavLink>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <NavItem>
                  <NavLink
                    className={classNames({ 'admin-panel-nav-link active': (activeTab === item.id), 'admin-panel-nav-link ': activeTab !== item.id })}
                    onClick={() => { this.toggle(item.id); }}
                  >
                    {
                      item.title
                    }
                  </NavLink>
                </NavItem>
              )
            );
          })}
        </Nav>
        {/*  <Button onClick={this.toggleSmartLook}>
          {`${smartLookStatus ? 'Izslēgt' : 'Ieslēgt'} smartlook`}
        </Button> */}
        <TabContent activeTab={activeTab}>
          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.users}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.users && (
              <Row>
                <Col md="12">
                  <AllUsers
                    allUsers={allUsers}
                    usersCount={usersCount}
                    userGamesHistory={userGamesHistory}
                    userBalanceHistory={userBalanceHistory}
                    userPointsHistory={userPointsHistory}
                    filteredUsers={filteredUsers}
                    nonVerifiedUsers={nonVerifiedUsers}
                    fetchAllUsers={fetchAllUsers}
                    fetchUsersRange={fetchUsersRange}
                    fetchFilteredUsers={fetchFilteredUsers}
                    fetchNonVerifiedUsers={fetchNonVerifiedUsers}
                    deleteUser={deleteUser}
                    swapToUser={swapToUser}
                    manualyVerifyUser={manualyVerifyUser}
                    toggleLogRocket={toggleLogRocket}
                    toggleSmartlook={toggleSmartlook}
                    resetActiveRoom={resetActiveRoom}
                    blockUser={blockUser}
                    unblockUser={unblockUser}
                    editUser={editUser}
                    addBalance={addBalance}
                    addPoints={addPoints}
                    fetchUserGamesHistory={fetchUserGamesHistory}
                    fetchUserBalanceHistory={fetchUserBalanceHistory}
                    fetchUserPointsHistory={fetchUserPointsHistory}
                    showNotification={showNotification}
                    answerSupportMessage={answerSupportMessage}
                    supportMessageFileUpload={supportMessageFileUpload}
                    updateAvatarFileUpload={updateAvatarFileUpload}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                    getUserMessages={getUserMessages}
                    cancelUserMessages={cancelUserMessages}
                    chatMessages={chatMessages}
                    loading={loading}
                    searchUid={searchUid}
                    fontMode={fontMode}
                    toggleNewDesign={toggleNewDesign}
                    newDesign={newDesign}
                    screenMode={screenMode}
                    toggleScreenMode={toggleScreenMode}
                  />
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.nonVerifiedAccounts}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.nonVerifiedAccounts && (
              <Row>
                <Col md="12">
                  <AllUsers
                    allUsers={allUsers}
                    usersCount={usersCount}
                    userGamesHistory={userGamesHistory}
                    userBalanceHistory={userBalanceHistory}
                    userPointsHistory={userPointsHistory}
                    filteredUsers={filteredUsers}
                    nonVerifiedUsers={nonVerifiedUsers}
                    fetchAllUsers={fetchAllUsers}
                    fetchUsersRange={fetchUsersRange}
                    fetchFilteredUsers={fetchFilteredUsers}
                    fetchNonVerifiedUsers={fetchNonVerifiedUsers}
                    deleteUser={deleteUser}
                    swapToUser={swapToUser}
                    manualyVerifyUser={manualyVerifyUser}
                    toggleLogRocket={toggleLogRocket}
                    toggleSmartlook={toggleSmartlook}
                    resetActiveRoom={resetActiveRoom}
                    blockUser={blockUser}
                    unblockUser={unblockUser}
                    editUser={editUser}
                    addBalance={addBalance}
                    addPoints={addPoints}
                    fetchUserGamesHistory={fetchUserGamesHistory}
                    fetchUserBalanceHistory={fetchUserBalanceHistory}
                    fetchUserPointsHistory={fetchUserPointsHistory}
                    showNotification={showNotification}
                    answerSupportMessage={answerSupportMessage}
                    supportMessageFileUpload={supportMessageFileUpload}
                    updateAvatarFileUpload={updateAvatarFileUpload}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                    getUserMessages={getUserMessages}
                    cancelUserMessages={cancelUserMessages}
                    chatMessages={chatMessages}
                    loading={loading}
                    nonVerifiedStart
                    fontMode={fontMode}
                    toggleNewDesign={toggleNewDesign}
                    newDesign={newDesign}
                    screenMode={screenMode}
                    toggleScreenMode={toggleScreenMode}
                  />
                </Col>
              </Row>
            )}
          </TabPane>
          {activeTab === constants.ADMIN_NAVIGATION_KEY.blockedUser && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.blockedUser}>
              <Row>
                <Col md="12">
                  <AllBans
                    allBans={allBans}
                    bansCount={bansCount}
                    editBan={editBan}
                    unblockUser={unblockUser}
                    fetchBansRange={fetchBansRange}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.suspiciousPlayers && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.suspiciousPlayers}>
              <Row>
                <Col sm="12">
                  <SuspiciousPlayers />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.multiAccounts && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.multiAccounts}>
              <Row>
                <Col sm="12">
                  <MultiAccounts showNotification={showNotification} />
                </Col>
              </Row>
            </TabPane>
          )}

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.transactions}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.transactions && (
              <Row>
                <Col md="12">
                  <AllTransactions
                  //  allTransactions={allTransactions}
                    allPayments={allPayments}
                    paymentsCount={paymentsCount}
                    removedPaymentsCount={removedPaymentsCount}
                    fetchPaymentsRange={fetchPaymentsRange}
                    completedPaymentsCount={completedPaymentsCount}
                    fetchCompletedPaymentsRange={fetchCompletedPaymentsRange}
                    initiatedPaymentsCount={initiatedPaymentsCount}
                    fetchInitiatedPaymentsRange={fetchInitiatedPaymentsRange}
                    fetchFilteredTransactions={fetchFilteredTransactions}
                  />
                </Col>
              </Row>
            )}
          </TabPane>
          {activeTab === constants.ADMIN_NAVIGATION_KEY.gitcode && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.gitcode}>
              <Row>
                <Col md="12">
                  <AllGiftCodes
                    allGiftCodes={allGiftCodes}
                    addGiftCode={addGiftCode}
                    editGiftCode={editGiftCode}
                    deleteGiftCode={deleteGiftCode}
                    claimedPlayers={claimedPlayers}
                    allUsers={allUsers}
                    fetchClaimedPlayers={fetchClaimedPlayers}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.shopDiscounts && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.shopDiscounts}>
              <Row>
                <Col sm="12">
                  <ShopDiscounts
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.events && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.events}>
              <Row>
                <Col sm="12">
                  <EventsBanner
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.tournaments && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.tournaments}>
              <Row>
                <Col md="12">
                  <AllTournaments
                    allTournaments={allTournaments}
                    tournamentPlayers={tournamentPlayers}
                    tournamentResults={tournamentResults}
                    tournamentPlayerHistory={tournamentPlayerHistory}
                    allUsers={allUsers}
                    editTournament={editTournament}
                    addTournament={addTournament}
                    deleteTournament={deleteTournament}
                    fetchTournamentPlayers={fetchTournamentPlayers}
                    fetchTournamentResults={fetchTournamentResults}
                    fetchTournamentPlayerHistory={fetchTournamentPlayerHistory}
                    forceCloseTournament={forceCloseTournament}
                    showNotification={showNotification}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                    fetchBots={fetchBots}
                    bots={bots}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.tournamentsUsers && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.tournamentsUsers}>
              <Row>
                <Col md="12">
                  <TournamentsUsers
                    totalTournamentUserCount={totalTournamentUserCount}
                    fetchTournamentUsers={fetchTournamentUsers}
                    tournamentUsers={tournamentUsers}
                    userTournaments={userTournaments}
                    fetchTournamentResults={fetchTournamentResults}
                    fetchBots={fetchBots}
                    tournamentResults={tournamentResults}
                    bots={bots}
                    fetchTournamentPlayers={fetchTournamentPlayers}
                    fetchTournamentPlayerHistory={fetchTournamentPlayerHistory}
                    tournamentPlayers={tournamentPlayers}
                    tournamentPlayerHistory={tournamentPlayerHistory}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.logs}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.logs && (
              <Row>
                <Col sm="12">
                  <AdminLogsContainer
                    Layout={AdminLogsComponent}
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>
          {activeTab === constants.ADMIN_NAVIGATION_KEY.adminAction && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.adminAction}>
              <Row>
                <Col sm="12">
                  <AdminActionLogs
                    adminActionData={adminActionData}
                    fetchAdminActionLog={fetchAdminActionLog}
                    logsCount={logsCount}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.admins && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.admins}>
              <Row>
                <Col sm="12">
                  <AdminsList
                    fetchAdmins={fetchAdmins}
                    updateRole={updateRole}
                    admins={admins}
                    manualyVerifyUser={manualyVerifyUser}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab === constants.ADMIN_NAVIGATION_KEY.missedRooms && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.missedRooms}>
              <Row>
                <Col sm="12">
                  <MissedRoomsStatistics />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.missedTurns && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.missedTurns}>
              <Row>
                <Col sm="12">
                  <MissedTurnsTable
                    missedTurnsCount={missedTurnsCount}
                    fetchmissedTurnsCount={fetchmissedTurnsCount}
                    fetchmissedTurnsData={fetchmissedTurnsData}
                    missedTurnsData={missedTurnsData}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab === constants.ADMIN_NAVIGATION_KEY.supportChats && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.supportChats}>
              <Row>
                <Col sm="12">
                  <UserMessages
                    answerSupportMessage={answerSupportMessage}
                    supportMessageFileUpload={supportMessageFileUpload}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                    messageAll={messageAll}
                    getUserMessages={getUserMessages}
                    cancelUserMessages={cancelUserMessages}
                  //  allChats={activeMessages}
                    readMessages={readMessages}
                    unreadMessages={unreadMessages}
                    chatMessages={chatMessages}
                    chatMessagesUserLastLanguage={chatMessagesUserLastLanguage}
                    showNotification={showNotification}
                    t={t}
                    fontMode={fontMode}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab === constants.ADMIN_NAVIGATION_KEY.statistics && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.statistics}>
              <Row>
                <Col sm="12">
                  <Statistics
                    fetchStatistic={fetchStatistic}
                    statistics={statistics}
                    allGifts={allGifts}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab === constants.ADMIN_NAVIGATION_KEY.bots && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.bots}>
              <Row>
                <Col sm="12">
                  <BotsList
                    fetchBots={fetchBots}
                    bots={bots}
                    editUser={editUser}
                    addBalance={addBalance}
                    addPoints={addPoints}
                    fetchUserGamesHistory={fetchUserGamesHistory}
                    fetchUserBalanceHistory={fetchUserBalanceHistory}
                    fetchUserPointsHistory={fetchUserPointsHistory}
                    userGamesHistory={userGamesHistory}
                    userBalanceHistory={userBalanceHistory}
                    userPointsHistory={userPointsHistory}
                    showNotification={showNotification}
                    updateAvatarFileUpload={updateAvatarFileUpload}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.feedback}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.feedback && (
              <Row>
                <Col md="12">
                  <Feedback
                    showNotification={showNotification}
                    getUserMessages={getUserMessages}
                    chatMessages={chatMessages}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                    supportMessageFileUpload={supportMessageFileUpload}
                    answerSupportMessage={answerSupportMessage}
                    cancelUserMessages={cancelUserMessages}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.createSurvey}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.createSurvey && (
              <Row>
                <Col sm="12">
                  <CreateSurvey
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.surveyResults}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.surveyResults && (
              <Row>
                <Col sm="12">
                  <SurveyResults
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          {activeTab === constants.ADMIN_NAVIGATION_KEY.settings && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.settings}>
              <Row>
                <Col sm="12">
                  <Settings
                    setMobileVersion={setMobileVersion}
                    getMobileVersion={getMobileVersion}
                    setTreasureChestEnabled={setTreasureChestEnabled}
                    getTreasureChestEnabled={getTreasureChestEnabled}
                    settings={settings}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === constants.ADMIN_NAVIGATION_KEY.profanities && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.profanities}>
              <Row>
                <Col sm="12">
                  <Profanities />
                </Col>
              </Row>
            </TabPane>
          )}
          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.emailEditor}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.emailEditor && (
              <Row>
                <Col sm="12">
                  <EmailEditor
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>
          {activeTab === constants.ADMIN_NAVIGATION_KEY.design && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.design}>
              <Row>
                <Col sm="12">
                  <AdminPlatform
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab === constants.ADMIN_NAVIGATION_KEY.textKeys && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.textKeys}>
              <Row>
                <Col sm="12">
                  <TextKeys
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.versionHistory}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.versionHistory && (
              <Row>
                <Col sm="12">
                  <NewVersionEditor
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>
          {activeTab === constants.ADMIN_NAVIGATION_KEY.blogs && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.blogs}>
              <Row>
                <Col sm="12">
                  <Blogs
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.logRocket}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.logRocket && (
              <Row>
                <Col sm="12">
                  <LogRocket
                    showNotification={showNotification}
                    toggleLogRocket={toggleLogRocket}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          {/* <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.firebase}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.firebase && (
              <Row>
                <Col sm="12">
                  <FirebaseLogs
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane> */}

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.notifications}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.notifications && (
              <Row>
                <Col sm="12">
                  <Notifications
                    showAlertNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.hints}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.hints && (
              <Row>
                <Col sm="12">
                  <Hints
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.reportPlayers}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.reportPlayers && (
              <Row>
                <Col sm="12">
                  <ReportedPlayers
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.reportMessages}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.reportMessages && (
              <Row>
                <Col sm="12">
                  <ReportedMessages
                    showNotification={showNotification}
                    warningPlayers={warningPlayers}
                    allReportedMessages={allReportedMessages}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.playerRoomMessages}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.playerRoomMessages && (
              <Row>
                <Col sm="12">
                  <PlayerRoomMessages
                    showNotification={showNotification}
                    blockUser={blockUser}
                    unblockUser={unblockUser}
                    chatMessages={chatMessages}
                    setSupportMessageAsResponded={setSupportMessageAsResponded}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.campaigns}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.campaigns && (
              <Row>
                <Col sm="12">
                  <Campaigns
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.possibleBotPlayers}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.possibleBotPlayers && (
              <Row>
                <Col sm="12">
                  <PossibleBotPlayers
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.helpEditor}>
            {activeTab === constants.ADMIN_NAVIGATION_KEY.helpEditor && (
              <Row>
                <Col sm="12">
                  <HelpEditor
                    showNotification={showNotification}
                  />
                </Col>
              </Row>
            )}
          </TabPane>

          {activeTab === constants.ADMIN_NAVIGATION_KEY.autoCompensation && (
            <TabPane tabId={constants.ADMIN_NAVIGATION_KEY.autoCompensation}>
              <Row>
                <Col sm="12">
                  <AutoCompensations
                    fetchAutoCompensations={fetchAutoCompensations}
                    disableAutoCompensationFunc={disableAutoCompensationFunc}
                    autoCompensations={autoCompensations}
                  />
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
}

export default withTranslation('admin')(withRouter(AdminPanel));
