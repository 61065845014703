import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import ReactTableContainer from 'react-table-container';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import LevelProgress from './LevelProgress';
import GiftsHistory from './GiftsHistory';
// import Friends from './Friends';
import * as constants from '../../../constants/constants';

import myInfoImg from '../../../images/icons/my_profile.png';

import { removeGift, getGiftsHistory } from '../../../actions/member';

class MyInfo extends Component {
  static propTypes = {
    member: PropTypes.shape().isRequired,
    i18n: PropTypes.shape().isRequired,
    myTournamentsData: PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.number,
      points: PropTypes.number,
    }),
    //  leaderboardData: PropTypes.arrayOf(PropTypes.shape()),
    achievementsNotification: PropTypes.bool,
    gifts: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    getGiftsHistoryFunction: PropTypes.func.isRequired,
    removeGiftFunction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    myTournamentsData: {},
    achievementsNotification: false,
    gifts: {},
    notificationSettings: {},
    fontMode: constants.FONT_MODE.normal,
  //  leaderboardData: {},
  }

  constructor(props) {
    super(props);
    this.state = {
    //  leaderboardPosition: '',
    };
  }

  render() {
    const {
      fontMode, member, t, myTournamentsData, changeTab, achievementsNotification, gifts, i18n, removeGiftFunction, getGiftsHistoryFunction, notificationSettings, userTournamentsHistory,
    } = this.props;

    console.log('userTournamentsHistory', { userTournamentsHistory });

    return (
      <div className="my-info d-flex flex-column">
        <Helmet>
          <title>
            Zole - {t('route.myInfo')}
          </title>
        </Helmet>

        <Row className="my-info-header">
          <Col xs="3" className="pr-0">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className="my-info-header-text">
              {t('myInfo.myInfo')}
            </div>
          </Col>
          <Col xs="9" style={{ paddingRight: 30 }}>
            <Row>
              {/* Stop for production mode(change xs=3 to xs2 for first 3) */}
              <Col xs="2" className="menu-topTab menu-myInfoTab my-info-menu">
                <Button color="link" className="my-info-header-button active" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                  {t('myInfo.myInfo')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab friends-menu">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                  {t('myInfo.friends')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab ignored-players-menu">
                <Button color="link" className={`my-info-header-button`} onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('myInfo.ignoredPlayers')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab achievements-menu">
                {/* <Button color="link" className={`my-info-header-button ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}> */}
                <Button color="link" className={`my-info-header-button`} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                  {t('myInfo.achievements')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab results-menu-tab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('myInfo.results')}
                </Button>
              </Col>
              {/* Stop for production mode */}
              <Col xs="2" className="menu-topTab menu-myInfoTab weekly-statistics-menu">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('myInfo.weeklyStatus')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab game-logs-menu">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('myInfo.gameLogs')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{flex: 1, overflowX: 'visible', overflowY: 'auto'}}>
          <Row className="m-0">
            <Col xs="6" sm="6" />
            <Col xs="6" sm="6">
              <Row>
                <Col>
                  {t('myInfo.tournaments')}
                  :
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-info-detail my-info-content-joyride m-0">
            <Col xs="6" sm="6" className="my-info-left">
              <Row style={{ color: '#fff' }}>
                <Col sm="12" className="my-info-scores">
                  <div className="my-info-scores-table">
                    <Row>
                      <Col className="text">
                        {t('common.position')}
                      </Col>
                      <Col className="text">
                        {t('common.parties')}
                      </Col>
                      <Col className="text">
                        {t('common.wins')}
                      </Col>
                      <Col className="text">
                        {t('common.points')}
                      </Col>
                      <Col className="text">
                        {t('common.level')}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="textData">
                        {member.position}
                      </Col>
                      <Col className="textData">
                        {member.gamesPlayed}
                      </Col>
                      <Col className="textData">
                        {member.gamesWon}
                      </Col>
                      <Col className="textData">
                        {member.totalPoints}
                      </Col>
                      <Col className="textData">
                        {member.level}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <LevelProgress member={member} />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <GiftsHistory
                    getGiftsHistory={getGiftsHistoryFunction}
                    removeGift={removeGiftFunction}
                    giftsHistory={member.giftsHistory}
                    gifts={gifts}
                    t={t}
                    notificationSettings={notificationSettings}
                    changeTab={changeTab}
                    fontMode={fontMode}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="5" sm="5" className="my-info-right">
              <Row>
                <Col className="my-info-right-col">
                  <ReactTableContainer
                    width="100%"
                    height="300px"
                    className="my-info-tournaments-table"
                    scrollbarStyle={{
                      background: {
                        background: 'transparent',
                        width: 1,
                        marginRight: 3,
                      },

                      backgroundFocus: {
                        background: 'transparent',
                        width: 1,
                        marginRight: 3,
                      },
                      foreground: {
                        background: '#fff',
                        width: 6,
                        left: -1,
                      },

                      foregroundFocus: {
                        background: '#fff',
                        width: 6,
                        left: -1,
                      },
                    }}
                  >
                    <table className="my-info-tournaments">
                      <thead className="my-info-tournaments-header" style={{ backgroundColor: '#274f4b' }}>
                        <tr>
                          <th className="my-info-tournaments-header-col text-left">
                            {t('tournaments.title')}
                          </th>
                          <th className="my-info-tournaments-header-col">
                            {t('tournaments.position')}
                          </th>
                          <th className="my-info-tournaments-header-col">
                            {t('tournaments.winningPot')}
                          </th>
                          {/* <th className="my-info-tournaments-header-col">
                            {t('tournaments.entryFee')}
                          </th> */}
                        </tr>
                      </thead>
                      <tbody className="my-info-tournaments-body">
                        {userTournamentsHistory && Object.keys(userTournamentsHistory).reverse().map(key => (
                          <tr key={key} className="my-info-tournaments-row">
                            <td className="my-info-tournaments-col text-left">
                              {userTournamentsHistory[key].name}
                            </td>
                            <td className="my-info-tournaments-col">
                              {userTournamentsHistory[key].finalPosition}
                            </td>
                            <td className="my-info-tournaments-col">
                              {userTournamentsHistory[key].winAmount}
                            </td>
                            {/* <td className="my-info-tournaments-col">
                              {userTournamentsHistory[key].entryFee}
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </ReactTableContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  myTournamentsData: state.tournaments ? state.tournaments.myTournamentsData : {},
  //  leaderboardData: (state.leaderboard && state.leaderboard.leaderboard) ? state.leaderboard.leaderboard : {},
  gifts: state.rooms.gifts || {},
  userTournamentsHistory: state.member.userTournamentsHistory || {},
});

const mapDispatchToProps = {
  removeGiftFunction: removeGift,
  getGiftsHistoryFunction: getGiftsHistory,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MyInfo));
