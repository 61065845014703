import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
// import Media from 'reactstrap/lib/Media';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import Moment from 'react-moment';
import Spinner from 'reactstrap/lib/Spinner';

import RoomLogs from './RoomLogs';
import * as constants from '../../../constants/constants';
import DateFormat from '../UI/DateFormat';
import CountDownDate from '../UI/CountDownDate';
import Select from 'react-select';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

class AdminLogsRooms extends React.Component {
  static propTypes = {
    member: PropTypes.shape({
      email: PropTypes.string,
    }),
    //  allRooms: PropTypes.shape({}),
    allRooms: PropTypes.arrayOf(PropTypes.shape({})),
    roomData: PropTypes.shape({}),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    fetchRoomLog: PropTypes.func.isRequired,
    fetchRoomLogsRange: PropTypes.func.isRequired,
    filteredRoomsLogs: PropTypes.shape({}),
    fetchFilteredRoomLogs: PropTypes.func.isRequired,
    roomsPlayedCount: PropTypes.number,
    bots: PropTypes.shape({}),
  }

  static defaultProps = {
    member: {},
    allRooms: [],
    roomData: {},
    filteredRoomsLogs: null,
    roomsPlayedCount: 0,
    bots: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pageSize: 50,
      //  roomFilter: '',
      //  tournamentFilter: '',
      filteredRoomsLogs: null,
      filter: '',
      filterType: 'roomId',
      filterMinValue: [],
      filterSpeed: '',
      filterBet: [],
      filterAmountOfPlayer: '',
      filterByTimeBetween: '',
      filterByTimeBetweenTime: null,
      filterCloseReason: '',
      spinnerLoading: false,
      showBotRoom: false,
      startDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
      endDate: new Date(),
    };

    this.toggle = this.toggle.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps = (nextProps) => {
    const nextFilteredRoomsLogs = nextProps.filteredRoomsLogs;
    const { filteredRoomsLogs } = this.props;

    console.log('componentWillReceiveProps', filteredRoomsLogs);

    if (filteredRoomsLogs && !nextFilteredRoomsLogs) {
      this.setState({ currentPage: 0, filteredRoomsLogs: null });
    } else if (nextFilteredRoomsLogs) {
      this.setState({
        filteredRoomsLogs: nextFilteredRoomsLogs,
        spinnerLoading: false,
      });
    }
  }

  componentWillUnmount = () => {
    const { fetchFilteredRoomLogs } = this.props;

    fetchFilteredRoomLogs('');
  }


  filter = () => {
    const { fetchFilteredRoomLogs } = this.props;
    const { filter, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, showBotRoom, startDate, endDate, filterByTimeBetween, filterByTimeBetweenTime } = this.state;
    
    console.log(filter, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, showBotRoom, "filterCheckRooms");

    console.log('filterAmountOfPlayer', filterAmountOfPlayer);

    if (Date.parse(startDate) > Date.parse(endDate)) return ;

    this.setState({
      spinnerLoading: true,
    });

    this.setState({
      currentPage: 0,
    });

    fetchFilteredRoomLogs(filter, filterType, this.getValuesArray(filterMinValue), filterSpeed, this.getValuesArray(filterBet), filterAmountOfPlayer, filterCloseReason, filterByTimeBetween, filterByTimeBetweenTime, showBotRoom, Date.parse(startDate), Date.parse(endDate));
  }

  resetFilters = () => {
    this.setState({
      filter: '',
      filterType: 'roomId',
      filterMinValue: [],
      filterSpeed: '',
      filterBet: [],
      filterAmountOfPlayer: '',
      filterByTimeBetween: '',
      filterByTimeBetweenTime: null,
      filterCloseReason: '',
      showBotRoom: false,
      startDate: new Date(Date.now() - 24 * 60 * 60 * 1000),
      endDate: new Date(),
    });
  }

  selectType = (e) => {
    this.setState({ filterType: e.target.value });
  }

  selectSpeed = (e) => {
    this.setState({ filterSpeed: e.target.value });
  }

  selectBet = (filterBet) => {
    this.setState({ filterBet });
  }

  selectFilterMinValue = (filterMinValue) => {
    this.setState({ filterMinValue });
  }

  selectAmountOfPlayer = (e) => {
    this.setState({ filterAmountOfPlayer: e.target.value });
  }

  selectCloseReason = (e) => {
    this.setState({ filterCloseReason: e.target.value });
  }

  selectFilterByTimeBetween = (e) => {
    this.setState({ filterByTimeBetween: e.target.value });
  }

  handleKeyPress = (target) => {
    if (target.charCode === constants.ENTERKEY) {
      this.filter();
    }
  }

  handleChange(event) {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [event.target.name]: value,
    });
  }

  toggle() {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }

  toggleBotRoom(showBotRoom) {
    this.setState({
      showBotRoom,
    });

    if (showBotRoom) {
      this.setState({
        filterType: 'hasBots',
      });
    } else {
      this.setState({
        filterType: '',
      });
    }
  }

  openModal(room) {
    const { fetchRoomLog } = this.props;

    console.log('openModal', room);

    fetchRoomLog(room.roomId);
    this.setState({
      roomId: room.roomId,
      room,
      openModal: true,
    });
  }

  handleClick(e, index) {
    e.preventDefault();

    const { fetchRoomLogsRange, roomsPlayedCount, filteredRoomsLogs } = this.props;
    if (filteredRoomsLogs && filteredRoomsLogs.length > 0) {
      console.log('has filtered');
    } else {
      const end = roomsPlayedCount - (50 * index);
      const start = roomsPlayedCount - ((50 * index) + 50);

      fetchRoomLogsRange(start, end);
    }
    this.setState({
      currentPage: index,
    });
  }

  changeStartDate = (date) => {
    this.setState({ startDate: date });
  }

  changeEndDate = (date) => {
    this.setState({ endDate: date });
  }

  getValuesArray = (options) => {
    let values = [];
    for (const option of options) {
      values.push(option.value);
    }
    return values;
  }


  table() {
    const {
      allRooms,
      roomsPlayedCount,
      t,
    } = this.props;
    const {
      currentPage,
      pageSize,
      filteredRoomsLogs,
      spinnerLoading,
      filterCloseReason,
    } = this.state;

    console.log('filteredRoomsLogs', filteredRoomsLogs, this.props.filteredRoomsLogs);

    let rooms = allRooms;
    let pagesCount = Math.ceil(roomsPlayedCount / pageSize);

    if (filteredRoomsLogs && (filteredRoomsLogs.length || filteredRoomsLogs.length === 0)) {
      rooms = filteredRoomsLogs.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize,
      );
      pagesCount = Math.ceil(filteredRoomsLogs.length / pageSize);
    }

    if (!allRooms.length) {
      return null;
    }
    if (spinnerLoading) {
      return (
        <Spinner type="grow" color="warning" style={{ width: '2rem', height: '2rem' }} />
      );
    }
    console.log(rooms, "checkGameErrorRooms")

    return (
      <Fragment>
        {rooms
          .map((room, index) => (
            <Fragment key={room.roomId}>
              <tr key={room.roomId} className={`allUsers-table-row ${index % 2 === 0 ? ('odd') : ('even')}`}>
                <td className="allUsers-table-col">
                  {room.date && (
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecond} date={room.date} />
                  )}
                </td>
                <td className="allUsers-table-col">
                  {room.roomId}
                </td>
                <td className="allUsers-table-col">
                  {room.tournamentId}
                </td>
                <td style={{ color: (room.closeReason && room.closeReason === constants.FILTER_CLOSE_REASON.missedTurn) ? "red" : "#FFF" }} className="allUsers-table-col">
                  {room.closeReason === 'missedTurn' ? (
                    <Fragment>
                      {`${room.closeReason} (${room.isOnline ? 'online' : 'offline'}, ${room.triedToMove ? 'tried to move' : "didn't try to move"})`}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {room.closeReason || ''}
                    </Fragment>
                  )}

                </td>
                <td className="allUsers-table-col">
                  {room?.secondUserJoined ? (
                    <CountDownDate showOption="admin_report" date={room?.secondUserJoined} newDate={room.date} showWithoutText={true} />
                  ) : ('-')}
                </td>
                <td className="allUsers-table-col">
                  {room?.firstRoundStart ? (
                    <CountDownDate showOption="admin_report" date={room?.firstRoundStart} newDate={room.date} showWithoutText={true} />
                  ) : ('-')}
                </td>
                <td className="allUsers-table-col">
                  {room?.closeTime ? (
                    <CountDownDate showOption="admin_report" date={room.closeTime} newDate={room.date} showWithoutText={true} />
                  ) : ('-')}
                </td>
                <td className="allUsers-table-col">
                  {room.closeTime && (
                    <DateFormat formatType={constants.FORMAT_DATE_TYPE.fullDateSecond} date={room.closeTime} />
                  )}
                </td>
                <td className="allUsers-table-col">
                  {
                    filterCloseReason === constants.GAMEERROR ? (
                      room?.partyCount || 0
                    ) : (
                      room?.party || 0
                    )
                  }
                </td>
                <td className="allUsers-table-col">
                  <Button onClick={() => this.openModal(room)}>
                    View
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        {roomsPlayedCount > pageSize && (
          <div className="pagination-wrapper">
            <Pagination aria-label="Page navigation example">
              <PaginationItem disabled={currentPage === 0}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, 0)} href="#">
                  {`‹‹‹`}
                </PaginationLink>
              </PaginationItem>

              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pagesCount)].map((page, i) => {
                if (i > currentPage - 4 && i < currentPage + 4) {
                  return (
                    <PaginationItem active={i === currentPage} key={page}>
                      <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  );
                }

                return null;
              })}

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink
                  style={{ color: '#000' }}
                  onClick={e => this.handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>

              <PaginationItem disabled={currentPage >= pagesCount - 1}>
                <PaginationLink style={{ color: '#000' }} onClick={e => this.handleClick(e, pagesCount - 1)} href="#">
                  {`›››`}
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      openModal, roomId, room, filterType, filter, filterMinValue, showBotRoom, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, startDate, endDate, filterByTimeBetween, filterByTimeBetweenTime,
    } = this.state;

    const { roomData, t, bots } = this.props;

    const multiSelectStyle = {
      menu: (provided) => ({
        ...provided,
        backgroundColor: 'rgb(34, 34, 34)',
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'white' : '#495057',
        padding: '5px 10px',
        height: 'auto',

      }),
    };

    const multiSelectTheme = (theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: 'rgb(25, 103, 210)',
      },
    })
    

    return (
      <Fragment>
        <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-admin-logs-rooms">
          <PopoverBody className="popover-body">
            A list of all finished and ongoing rooms with an option to view details of each of them. Note: Room details are only available for the last 14 days.
          </PopoverBody>
        </UncontrolledPopover>
        <div style={{ marginTop: 100, color: '#fff' }}>
          <h2>
            Rooms
            <Button className="admin-help-button" id="admin-help-button-admin-logs-rooms">
              ?
            </Button>
          </h2>
          <Row>
            <Col sm="2">
              <Label for="filterType">
                Filter by
              </Label>
              <Input type="select" className="game-bet-select" value={filterType} onChange={this.selectType}>
                <option value="roomId" style={{ backgroundColor: '#222' }}>Room Id</option>
                <option value="tournamentId" style={{ backgroundColor: '#222' }}>Tournament Id</option>
                <option value="userId" style={{ backgroundColor: '#222' }}>Player Id</option>
                {/*  <option value="missedTurn" style={{ backgroundColor: '#222' }}>{t('missedTurn')}</option>  */}
              </Input>
            </Col>
            <Col sm="3">
              <Label for="filter">
                Value
              </Label>
              <Input
                type="text"
                name="filter"
                id="filter"
                value={filter}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
              />
            </Col>
            <Col sm="3">
              <Label for="filter">
                Min number of games per room
              </Label>
                <Select
                  isMulti
                  value={filterMinValue}
                  onChange={this.selectFilterMinValue}
                  closeMenuOnSelect={false}
                  theme={multiSelectTheme}
                  styles={multiSelectStyle}
                  options={[
                    { value: '1', label: 1 },
                    { value: '3', label: 3 },
                    { value: '5', label: 5 },
                    { value: '10', label: 10 },
                    { value: '15', label: 15 },
                    { value: '20', label: 20 },
                    { value: '25', label: 25 },
                  ]}
                />
            </Col>
            <Col sm="2">
              <Label for="filterBotRoomCheck" check>
                <Input id="filterBotRoomCheck" type="checkbox" onClick={() => this.toggleBotRoom(!showBotRoom)} checked={showBotRoom} readOnly />
                <span className="checkmark" />
                Show the room with Bot players
              </Label>
            </Col>
            <Col sm="1">
              <Button onClick={this.filter} style={{ marginTop: 20 }}>
                Filter
              </Button>
            </Col>
            <Col sm="1">
              <Button onClick={this.resetFilters} style={{ marginTop: 20 }}>
                Reset
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label for="filterSpeed">
                Filter By Speed
              </Label>
              <Input type="select" className="game-bet-select" value={filterSpeed} onChange={this.selectSpeed}>
                <option value="" style={{ backgroundColor: '#222' }}></option>
                <option value="lightning" style={{ backgroundColor: '#222' }}>Lightning</option>
                <option value="atra" style={{ backgroundColor: '#222' }}>Fast</option>
                <option value="parasta" style={{ backgroundColor: '#222' }}>Slow</option>
                <option value="unlimited" style={{ backgroundColor: '#222' }}>Unlimited</option>

              </Input>
            </Col>
            <Col>
              <Label for="filterBet">
                Filter By Bet
              </Label>
              <Select
                isMulti
                value={filterBet}
                onChange={this.selectBet}
                closeMenuOnSelect={false}
                theme={multiSelectTheme}
                styles={multiSelectStyle}
                options={[
                  { value: '1:10', label: '1:10' },
                  { value: '1:25', label: '1:25' },
                  { value: '1:50', label: '1:50' },
                  { value: '1:100', label: '1:100' },
                  { value: '1:250', label: '1:250' },
                  { value: '1:500', label: '1:500' },
                  { value: '1:1000', label: '1:1000' },
                  { value: '1:5000', label: '1:5000' },
                  { value: '1:10000', label: '1:10000' }
                ]}
              />
            </Col>
            <Col>
              <Label for="filterSpeed">
                {/* {t('amountOfPlayer')} */}
                Amount Of Player
              </Label>
              <Input type="select" className="game-bet-select" value={filterAmountOfPlayer} onChange={this.selectAmountOfPlayer}>
                <option value="" style={{ backgroundColor: '#222' }}></option>
                <option value="3players" style={{ backgroundColor: '#222' }}>3 Players</option>
                <option value="4players" style={{ backgroundColor: '#222' }}>4 Players</option>
              </Input>
            </Col>
            <Col>
              <Label for="filterSpeed">
                {/* {t('closeReason')} */}
                Close Reason
              </Label>
              <Input type="select" className="game-bet-select" value={filterCloseReason} onChange={this.selectCloseReason}>
                <option value="" className="background-222" />
                {
                  constants.closeReason.map((item) => {
                    return (
                      <option className="background-222" value={item.id}>
                        {item.label}
                      </option>
                    );
                  })
                }
              </Input>
            </Col>


            <Col>
              <Label for="filterSpeed">
                Filter by time between
              </Label>
              <Input type="select" className="game-bet-select" value={filterByTimeBetween} onChange={this.selectFilterByTimeBetween}>
                <option value="" style={{ backgroundColor: '#222' }}></option>
                <option value="secondUserJoinedTimeTaken" style={{ backgroundColor: '#222' }}>time between creating a room and joining second player</option>
                <option value="firstRoundStartTimeTaken" style={{ backgroundColor: '#222' }}>time between creating room and starting to play</option>
                <option value="closeTimeTimeTaken" style={{ backgroundColor: '#222' }}>time between create and close</option>
              </Input>
            </Col>

            {filterByTimeBetween ? (
              <Col>
                <Label for="filterSpeed">
                  Time between (minutes)
                </Label>
                <Input
                  type="number"
                  name="filterByTimeBetweenTime"
                  id="filterByTimeBetweenTime"
                  value={filterByTimeBetweenTime}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                  onWheel={(e) => e.target.blur()}
                />
              </Col>
            ): (null) }
            
          </Row>
          <Row>
            <Col sm="6">
              <Label for="startDate">
                {/* {t('startDate')} */}
                From
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={this.changeStartDate}
                    value={startDate}
                    defaultValue={new Date(Date.now() - 24 * 60 * 60 * 1000)}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Label for="endDate">
                {/* {t('endDate')} */}
                To
              </Label>
              <Row>
                <Col md="12">
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    culture="lv"
                    onChange={this.changeEndDate}
                    value={endDate}
                    defaultValue={new Date()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <table style={{ width: '100%', fontSize: 12, color: '#fff' }}>
            <colgroup>
              <col span="1" className="" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>
                  Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Room Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  Tournament Id
                </th>
                <th style={{ textAlign: 'center' }}>
                  Close Reason
                </th>
                <th style={{ textAlign: 'center' }}>
                  Time between create and joining second player
                </th>
                <th style={{ textAlign: 'center' }}>
                  Time between create room and start to play
                </th>
                <th style={{ textAlign: 'center' }}>
                  Time between create and close
                </th>
                <th style={{ textAlign: 'center' }}>
                  Close Date
                </th>
                <th style={{ textAlign: 'center' }}>
                  Parties
                </th>
                <th style={{ textAlign: 'center' }}>
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {this.table()}
            </tbody>
          </table>
        </div>
        <Modal container={`div > div`} isOpen={openModal} toggle={this.toggle} style={{ maxWidth: '65%' }}>
          <ModalHeader toggle={this.toggle}>
            {console.log('roomroomroom', room)}
            {room ? `Room: ${room.roomId}, Game type: ${room.smallGame ? `${room.gameType ? room.gameType : 'P'}M` : `${room.gameType ? room.gameType : 'P'}`}, Bet: ${room.bet}, Speed: ${constants.SPEED_TYPE[room.speed]}, ${room.minGames ? `Min Games: ${room.minGames}` : ''}, ${room.fourPRoom ? '4' : '3'} Players` : ''}
          </ModalHeader>
          <ModalBody>
            <RoomLogs roomData={roomData} roomId={roomId} roomParams={room} bots={bots} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation('admin')(AdminLogsRooms);
