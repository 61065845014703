import React, { Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import { map, get } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import MenuRow from '../../Components/Components/MenuRow';

import ScrollAreaWrapper from '../../../UI/ScrollAreaWrapper';

import * as constants from '../../../../../constants/constants';

import {
  getWeeklyStatistics,
} from '../../../../../actions/member';

const WeeklyStatus = ({ changeTab, achievementsNotification }) => {

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const weeklyStatistics = useSelector(state => state.member.weeklyStatistics);
  const screenMode = useSelector(state => state.member.screenMode);
  const fontMode = useSelector(state => state.member.fontMode);

  useEffect(() => {
    dispatch(getWeeklyStatistics());
  }, [screenMode, fontMode]);

  const getTrend = (week1, week2) => {
    const trend = week1 - week2;
    return trend;
  };

  return (
    <>
      <Helmet>
        <title>
          Zole - {t('common:myInfo.weeklyStatus')}
        </title>
      </Helmet>
      <div className="layout-body">
        <div className="layout-body-top">
          <Row className="layout-subheader">
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                {t('common:myInfo.myInfo')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                {t('common:myInfo.friends')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                {t('common:myInfo.ignoredPlayers')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className={classNames("layout-subheader-link-text")} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                {t('common:myInfo.achievements')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                {t('common:myInfo.results')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text my-info-weeklyStatus-button layout-subheader-link-text-active" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                {t('common:myInfo.weeklyStatus')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                {t('common:myInfo.gameLogs')}
              </Button>
            </div>
            <div className="layout-subheader-link my-info-gifts-button">
              <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfoGifts)}>
                {t('common:myInfo.gifts')}
              </Button>
            </div>
          </Row>
        </div>
        <div className="layout-body-main">

          <Row className="layout-body-main-table-header weekly-status-layout-body-main-table-header">
            <Col sm="4" className='text-center' />
            <Col sm="2" className='text-center'>
              {t('myInfo.lastWeek')}
            </Col>
            <Col sm="2" className='text-center'>
              {t('myInfo.twoWeeksAgo')}
            </Col>
            <Col sm="2" className='text-center pl-0'>
              {t('myInfo.trend')}
            </Col>
            <Col sm="2" className='text-center pl-0'>
              {t('myInfo.average')}
            </Col>
          </Row>

          <div
            className="layout-body-main-scrollarea weekly-status-scrollbar"
            contentClassName="layout-body-main-scrollarea-body"
            show
            rightOffset={0}
            topOffset={30}
            bottomOffset={0}
          >
            {weeklyStatistics && (
              <Fragment>
                {constants.WEEKLY_STATISTICS_DATA && map(constants.WEEKLY_STATISTICS_DATA, (item, key) => (
                  <MenuRow key={key}>
                    <Row key={key}>
                      <Col sm="4">
                        {t(`myInfo.${key}`)}
                      </Col>
                      <Col sm="2" className='text-center'>
                        {get(weeklyStatistics, `oneWeekAgo.${key}`, 0)}
                      </Col>
                      <Col sm="2" className='text-center'>
                        {get(weeklyStatistics, `twoWeeksAgo.${key}`, 0)}
                      </Col>
                      <Col sm="2" className='text-center'>
                        <div className={
                          classNames({
                            'menu-table-row-weekly-large-trend': get(weeklyStatistics, `oneWeekAgo.${key}`, 0) - get(weeklyStatistics, `twoWeeksAgo.${key}`, 0) > 0,
                            'menu-table-row-weekly-low-trend': get(weeklyStatistics, `oneWeekAgo.${key}`, 0) - get(weeklyStatistics, `twoWeeksAgo.${key}`, 0) < 0,
                            'menu-table-row-weekly-neutral-trend': get(weeklyStatistics, `oneWeekAgo.${key}`, 0) - get(weeklyStatistics, `twoWeeksAgo.${key}`, 0) === 0,
                          })
                        }>
                          {getTrend(get(weeklyStatistics, `oneWeekAgo.${key}`, 0), get(weeklyStatistics, `twoWeeksAgo.${key}`, 0))}
                        </div>
                      </Col>
                      <Col sm="2" className='text-center'>
                        {(get(weeklyStatistics, `oneWeekAgo.${key}`, 0) + get(weeklyStatistics, `twoWeeksAgo.${key}`, 0)) / 2}
                      </Col>
                    </Row>
                  </MenuRow>
                ))}
              </Fragment>
            )}
          </div>
          {/* <div className="menu-table-row-filler-out" /> */}

        </div>
      </div>
    </>
  );
}

WeeklyStatus.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
};

WeeklyStatus.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
};

export default WeeklyStatus;
