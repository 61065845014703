import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import GameTimer from './GameTimer';
import GameTimerTournament from './GameTimerTournament';
import { FONT_MODE } from '../../../constants/constants';

class GameStats extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    gameStartTime: PropTypes.number,
    offset: PropTypes.number,
    gameType: PropTypes.string,
    smallGame: PropTypes.bool,
    bet: PropTypes.string,
    party: PropTypes.number,
    scoreTableOpen: PropTypes.bool,
    rPlayed: PropTypes.number,
    maxGames: PropTypes.number,
    fontMode: PropTypes.string,
    tournamentRound: PropTypes.number,
  }

  static defaultProps = {
    gameStartTime: null,
    offset: null,
    gameType: null,
    smallGame: null,
    bet: null,
    party: null,
    scoreTableOpen: true,
    rPlayed: null,
    maxGames: null,
    fontMode: FONT_MODE.normal,
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  shouldComponentUpdate(nextProps) {
    const {
      gameStartTime,
      offset,
      gameType,
      smallGame,
      bet,
      party,
      scoreTableOpen,
      rPlayed,
      maxGames,
      fontMode,
      tournamentRound,
      registeredTournament,
      tournaments,
    } = nextProps;

    const {
      gameStartTime: curGameStartTime,
      offset: curOffset,
      gameType: curGameType,
      smallGame: curSmallGame,
      bet: curBet,
      party: curParty,
      scoreTableOpen: curScoreTableOpen,
      rPlayed: curRPlayed,
      maxGames: curMaxGames,
      fontMode: curFontMode,
      tournamentRound: curTournamentRound,
      registeredTournament: curRegisteredTournament,
      tournaments: curTournaments,
    } = this.props;

    if (fontMode !== curFontMode) {
      return true;
    }

    if (scoreTableOpen !== curScoreTableOpen) {
      return true;
    }

    if (gameStartTime !== curGameStartTime) {
      return true;
    }

    if (offset !== curOffset) {
      return true;
    }

    if (gameType !== curGameType) {
      return true;
    }

    if (smallGame !== curSmallGame) {
      return true;
    }

    if (bet !== curBet) {
      return true;
    }

    if (party !== curParty) {
      return true;
    }

    if (rPlayed !== curRPlayed) {
      return true;
    }

    if (maxGames !== curMaxGames) {
      return true;
    }

    if (tournamentRound !== curTournamentRound) {
      return true;
    }

    if (registeredTournament !== curRegisteredTournament) {
      return true;
    }

    if (JSON.stringify(tournaments) !== JSON.stringify(curTournaments)) {
      return true;
    }

    return false;
  }


  render() {
    const {
      t,
      gameStartTime,
      offset,
      gameType,
      smallGame,
      bet,
      //  party,
      scoreTableOpen,
      rPlayed,
      maxGames,
      fontMode,
      tournamentRound,
      isTournamentLastRound,
      registeredTournament,
      tournaments,
    } = this.props;

    const tournament = (tournaments && tournaments.length) ? tournaments.find(item => item.id === registeredTournament) : null;
    const currentRound = tournament ? tournament.currentRound : '';

    console.log('Gametimer tournamentRound', tournamentRound, isTournamentLastRound, currentRound);

    console.log('rPlayed', rPlayed, gameStartTime, maxGames);
    const showRoundsLeft = (rPlayed || rPlayed === 0) && maxGames;

    return (
      <div className={`game-stats ${maxGames ? `game-stats-max-games-${fontMode}` : ''} ${!scoreTableOpen ? 'display-none' : ''}`}>
        {maxGames ? (
          <div>
            {t('game:timeTournament', { round: isTournamentLastRound ? (currentRound || '') : tournamentRound })}{' '}
            {/*  {`${t('game:timeTournament', { round: tournamentRound })} `}  */}
            {gameStartTime ? (
              <GameTimerTournament
                gameStartTime={gameStartTime}
                offset={offset}
                maxGames={maxGames}
              />
            ) : (null)}
          </div>
        ) : (null)}
        <table className="game-stats-table">
          <tbody>
            <tr>
              <th className="game-stats-table-col">{t('type')}</th>
              <th className="game-stats-table-col">{t('time')}</th>
              <th className="game-stats-table-col">{t('parties')}</th>
              <th className="game-stats-table-col">{t('bet')}</th>
              {showRoundsLeft ? (
                <th className="game-stats-table-col" style={{whiteSpace: 'normal'}}>
                  {t('roundsLeft')}
                </th>
              ) : (null)}
            </tr>
            <tr>
              <th className="game-stats-table-col">
                {gameType === 'P' ? (
                  <Fragment>
                    {smallGame ? (
                      <div>
                        PM
                      </div>
                    ) : (
                      <div>
                        P
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {smallGame ? (
                      <div>
                        MG
                      </div>
                    ) : (
                      <div>
                        G
                      </div>
                    )}
                  </Fragment>
                )}
              </th>
              <th className="game-stats-table-col">
                {gameStartTime ? (
                  <GameTimer
                    gameStartTime={gameStartTime}
                    offset={offset}
                  />
                ) : (null)}
              </th>
              <th className="game-stats-table-col">{rPlayed ? (rPlayed + 1) : 1}</th>
              <th className="game-stats-table-col">{bet}</th>
              {(rPlayed || rPlayed === 0) && maxGames ? (
                <th className="game-stats-table-col">
                  {maxGames - rPlayed}
                </th>
              ) : (null)}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gameStartTime: state.game.globalParams.gameStartTime,
  offset: state.member.offset || 0,
  gameType: state.game.globalParams.gameType,
  smallGame: state.game.globalParams.smallGame,
  bet: state.game.globalParams.bet,
  party: state.game.globalParams.party,
  rPlayed: state.game.globalParams.rPlayed || 0,
  maxGames: state.game.globalParams.tournamentRoom ? state.game.globalParams.maxGames : null,
  tournamentRound: state.game.globalParams.tournamentRound,
  isTournamentLastRound: state.game.globalParams.isTournamentLastRound,
  registeredTournament: state.member.registeredTournament,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
});

export default connect(mapStateToProps, null)(GameStats);
