import React, { Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';

import { useTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch, connect } from 'react-redux';


import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';


import * as constants from '../../../../../constants/constants';
import { getGiftsHistory, removeGift } from '../../../../../actions/member';
import GiftsHistory from './Components/MyInfo/GiftsHistory';

const Gifts = ({ changeTab, achievementsNotification, getGiftsHistoryFunction, removeGiftFunction, member, gifts, notificationSettings }) => {

  const { t } = useTranslation('common');
  // const dispatch = useDispatch();

  // const screenMode = useSelector(state => state.member.screenMode);
  // const fontMode = useSelector(state => state.member.fontMode);

  // useEffect(() => {
  //   dispatch(getWeeklyStatistics());
  // }, [screenMode, fontMode]);

  return (
    <>
      <Helmet>
        <title>
          Zole - {t('common:myInfo.weeklyStatus')}
        </title>
      </Helmet>
      <div className="layout-body">
        <div className="layout-body-top">
          <Row className="layout-subheader">
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                {t('common:myInfo.myInfo')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.friends)}>
                {t('common:myInfo.friends')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.ignoredUsers)}>
                {t('common:myInfo.ignoredPlayers')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className={classNames("layout-subheader-link-text")} onClick={() => changeTab(constants.MENU_NAVIGATION.archievements)}>
                {t('common:myInfo.achievements')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text " onClick={() => changeTab(constants.MENU_NAVIGATION.gameHistory)}>
                {t('common:myInfo.results')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text my-info-weeklyStatus-button" onClick={() => changeTab(constants.MENU_NAVIGATION.weeklyStatus)}>
                {t('common:myInfo.weeklyStatus')}
              </Button>
            </div>
            <div className="layout-subheader-link">
              <Button color="link" className="layout-subheader-link-text layout-subheader-link-text" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                {t('common:myInfo.gameLogs')}
              </Button>
            </div>
            <div className="layout-subheader-link my-info-gifts-button">
              <Button color="link" className="layout-subheader-link-text layout-subheader-link-text layout-subheader-link-text-active" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfoGifts)}>
                {t('common:myInfo.gifts')}
              </Button>
            </div>
          </Row>
        </div>
        <div className="layout-body-main">
          <GiftsHistory
            getGiftsHistory={getGiftsHistoryFunction}
            removeGift={removeGiftFunction}
            giftsHistory={member.giftsHistory}
            gifts={gifts}
            t={t}
            notificationSettings={notificationSettings}
            changeTab={changeTab}
          />
        </div>
      </div>
    </>
  );
}

Gifts.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
};

Gifts.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
};

const mapStateToProps = state => ({
  member: state.member || {},
  gifts: state.rooms.gifts || {},
});

const mapDispatchToProps = {
  removeGiftFunction: removeGift,
  getGiftsHistoryFunction: getGiftsHistory,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Gifts));

