import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";

import { Button, Col, Media, Row, TabContent, TabPane } from "reactstrap";
import DiscountTimer from "../../Components/Components/Timer";
import { map, filter } from "lodash";

import coins1 from "../../../../../images/redesign/buy-page/coins_1.webp";
import coins2 from "../../../../../images/redesign/buy-page/coins_2.webp";
import coins3 from "../../../../../images/redesign/buy-page/coins_3.webp";
import coins4 from "../../../../../images/redesign/buy-page/coins_4.webp";
import coins5 from "../../../../../images/redesign/buy-page/coins_5.webp";

import bonus1 from "../../../../../images/icons/money_bonus_1.svg";
import bonus2 from "../../../../../images/icons/money_bonus_2.svg";
import bonus3 from "../../../../../images/icons/money_bonus_3.svg";
import bonus4 from "../../../../../images/icons/money_bonus_4.svg";

import coinImg from "../../../../../images/redesign/common/coin.svg";

import balanceResetImg from "../../../../../images/redesign/buy-page/balance_reset.svg";
import pointsResetImg from "../../../../../images/redesign/buy-page/points_reset.svg";
import profileResetImg from "../../../../../images/redesign/buy-page/profile_reset.svg";
import infoImg from "../../../../../images/redesign/player/info.svg";
import lightInfoImg from "../../../../../images/redesign/light-mode/player/info.svg";

import { getDiscounts } from "../../../../../actions/shop";
import * as constants from "../../../../../constants/constants";

const RoomEndedModal = ({
  discounts,
  screenMode,
  balance,
  totalPoints,
  buyMoney,
  fetchDiscounts,
  t,
  i18n,
}) => {
  const [activeHover, setActiveHover] = useState(null);
  const [activeTab, setActiveTab] = useState(
    constants.SHOP_NAVIGATION.moneyPacks
  );

  useEffect(() => {
    const { hostname } = window.location;
    let devModeTemp = false;
    if (
      hostname &&
      (hostname.includes("dev") || hostname.includes("localhost"))
    ) {
      devModeTemp = true;
    }
    fetchDiscounts(devModeTemp);
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const mouseEnter = (key) => {
    setActiveHover(key);
  };

  const mouseLeave = () => {
    setActiveHover(null);
  };

  const coinPacks = (
    <div>
      <Row className="d-flex flex-column align-items-center buy-page-end-time-section mt-4 mb-5">
        {discounts.length > 0 &&
          map(discounts, (item) => (
            <Col className="d-flex flex-row align-items-center">
              <div className="mr-1">
                {`${t("home.endEventTime")}(${
                  i18n.language === constants.sendLanguage.en
                    ? item.titleEN
                  : i18n.language ===
                      constants.sendLanguage.lv
                    ? item.titleLV
                    : i18n.language ===
                      constants.sendLanguage.ru
                    ? item.titleRU
                    : item.titleLV
                }): `}
              </div>
              <DiscountTimer item={item} />
            </Col>
          ))}
      </Row>
      <Row className="room-ended-modal-new-money-pack-row">
        <Col className="room-ended-modal-new-money-pack-col">
          <div className="buy-page-coins-imageWrapper" onClick={() => buyMoney(1)}>
            <Media className="buy-page-coins-image" src={coins1} alt="" />
          </div>
          <div className="buy-page-coins-wrapper room-ended-modal-new-reset-top-distance">
            <Media src={coinImg} className="buy-page-coins-coin" />
            <div className="buy-page-coins-amount">250</div>
          </div>
          <div className="buy-page-coins-button-wrapper room-ended-modal-new-reset-top-distance">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(1)}
                  >
                    0.85 EUR
                  </Button>
                  <Button
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(1)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money5"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(1)}
                >
                  0.85 EUR
                </Button>
              )
            ) : (
              <Button
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(1)}
              >
                0.85 EUR
              </Button>
            )}
          </div>
        </Col>
        <Col className="room-ended-modal-new-money-pack-col">
          <div className="buy-page-coins-imageWrapper" onClick={() => buyMoney(2)}>
            <Media className="buy-page-coins-image" src={coins2} alt="" />
            <Media className="buy-page-coins-bonus-image" src={bonus1} alt="" />
          </div>
          <div className="buy-page-coins-wrapper room-ended-modal-new-reset-top-distance">
            <Media src={coinImg} className="buy-page-coins-coin" />
            <div className="buy-page-coins-amount">500</div>
          </div>
          <div className="buy-page-coins-button-wrapper room-ended-modal-new-reset-top-distance">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(2)}
                  >
                    1.41 EUR
                  </Button>
                  <Button
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(2)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money5"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(2)}
                >
                  1.41 EUR
                </Button>
              )
            ) : (
              <Button
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(2)}
              >
                1.41 EUR
              </Button>
            )}
          </div>
        </Col>
        <Col className="room-ended-modal-new-money-pack-col">
          <div className="buy-page-coins-imageWrapper" onClick={() => buyMoney(3)}>
            <Media className="buy-page-coins-image" src={coins3} alt="" />
            <Media className="buy-page-coins-bonus-image" src={bonus2} alt="" />
          </div>
          <div className="buy-page-coins-wrapper room-ended-modal-new-reset-top-distance">
            <Media src={coinImg} className="buy-page-coins-coin" />
            <div className="buy-page-coins-amount">1500</div>
          </div>
          <div className="buy-page-coins-button-wrapper room-ended-modal-new-reset-top-distance">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(3)}
                  >
                    2.85 EUR
                  </Button>
                  <Button
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(3)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money5"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(3)}
                >
                  2.85 EUR
                </Button>
              )
            ) : (
              <Button
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(3)}
              >
                2.85 EUR
              </Button>
            )}
          </div>
        </Col>
        <Col className="room-ended-modal-new-money-pack-col">
          <div className="buy-page-coins-imageWrapper" onClick={() => buyMoney(4)}>
            <Media className="buy-page-coins-image" src={coins4} alt="" />
            <Media className="buy-page-coins-bonus-image" src={bonus3} alt="" />
          </div>
          <div className="buy-page-coins-wrapper room-ended-modal-new-reset-top-distance">
            <Media src={coinImg} className="buy-page-coins-coin" />
            <div className="buy-page-coins-amount">4000</div>
          </div>
          <div className="buy-page-coins-button-wrapper room-ended-modal-new-reset-top-distance">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(4)}
                  >
                    4.27 EUR
                  </Button>
                  <Button
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(4)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money5"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(4)}
                >
                  4.27 EUR
                </Button>
              )
            ) : (
              <Button
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(4)}
              >
                4.27 EUR
              </Button>
            )}
          </div>
        </Col>
        <Col className="room-ended-modal-new-money-pack-col">
          <div className="buy-page-coins-imageWrapper" onClick={() => buyMoney(5)}>
            <Media className="buy-page-coins-image" src={coins5} alt="" />
            <Media className="buy-page-coins-bonus-image" src={bonus4} alt="" />
          </div>
          <div className="buy-page-coins-wrapper room-ended-modal-new-reset-top-distance">
            <Media src={coinImg} className="buy-page-coins-coin" />
            <div className="buy-page-coins-amount">15000</div>
          </div>
          <div className="buy-page-coins-button-wrapper room-ended-modal-new-reset-top-distance">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(5)}
                  >
                    14.23 EUR
                  </Button>
                  <Button
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(5)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money5"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(5)}
                >
                  14.23 EUR
                </Button>
              )
            ) : (
              <Button
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(5)}
              >
                14.23 EUR
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );

  const premiumPacks = (
    <Row mt-4 mb-5 className="room-ended-modal-new-premium-pack-row">
      <Col className="room-ended-modal-new-premium-pack-col">
        <div>
          <div className={classNames('buy-page-premium-imageWrapper', {'buy-page-premium-imageWrapper-disabled': !balance || balance > 0})} onClick={() => buyMoney(6)}>
            <Media
              className="buy-page-premium-image"
              src={balanceResetImg}
              alt=""
            />
          </div>
          <div className="buy-page-premium-wrapper">
            <div className="buy-page-premium-amount room-ended-modal-new-premium-amount">
              {t("buyPage.balanceReset")}
            </div>
            <div className="buy-page-info-imageWrapper room-ended-modal-new-info-wrapper">
              <Media
                onMouseEnter={() => mouseEnter("6")}
                onMouseLeave={() => mouseLeave("6")}
                className="buy-page-info-image room-ended-modal-new-info-image"
                src={
                  screenMode === constants.SCREEN_MODE.light
                    ? lightInfoImg
                    : infoImg
                }
                alt=""
              />
              <div
                className={`buy-page-info-hover room-ended-modal-new-info-hover ${
                  activeHover === "6" ? null : "display-none"
                }`}
              >
                <label className="mr-1">{`${t("buyPage.balanceReset")}:`}</label>
                {t("buyPage.balanceResetDesc")}
              </div>
            </div>
          </div>
          <div className="buy-page-premium-button-wrapper">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(6)}
                  >
                    5.00 EUR
                  </Button>
                  <Button
                    disabled={!balance || balance > 0}
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(6)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money6"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  disabled={!balance || balance > 0}
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(6)}
                >
                  5.00 EUR
                </Button>
              )
            ) : (
              <Button
                disabled={!balance || balance > 0}
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(6)}
              >
                5.00 EUR
              </Button>
            )}
          </div>
        </div>
      </Col>
      <Col className="room-ended-modal-new-premium-pack-col">
        <div>
          <div className={classNames('buy-page-premium-imageWrapper', {'buy-page-premium-imageWrapper-disabled': !totalPoints || totalPoints > 0})} onClick={() => buyMoney(7)}>
            <Media
              className="buy-page-premium-image"
              src={pointsResetImg}
              alt=""
            />
          </div>
          <div className="buy-page-premium-wrapper">
            <div className="buy-page-premium-amount room-ended-modal-new-premium-amount">
              {t("buyPage.pointsReset")}
            </div>
            <div className="buy-page-info-imageWrapper room-ended-modal-new-info-wrapper">
              <Media
                className="buy-page-info-image room-ended-modal-new-info-image"
                onMouseEnter={() => mouseEnter("7")}
                onMouseLeave={() => mouseLeave("7")}
                src={
                  screenMode === constants.SCREEN_MODE.light
                    ? lightInfoImg
                    : infoImg
                }
                alt=""
              />
              <div
                className={`buy-page-info-hover room-ended-modal-new-info-hover ${
                  activeHover === "7" ? null : "display-none"
                }`}
              >
                <label className="mr-1">{`${t("buyPage.pointsReset")}:`}</label>
                {t("buyPage.pointsResetDesc")}
              </div>
            </div>
          </div>
          <div className="buy-page-premium-button-wrapper">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(7)}
                  >
                    5.00 EUR
                  </Button>
                  <Button
                    disabled={!totalPoints || totalPoints > 0}
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(7)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money6"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  disabled={!totalPoints || totalPoints > 0}
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(7)}
                >
                  5.00 EUR
                </Button>
              )
            ) : (
              <Button
                disabled={!totalPoints || totalPoints > 0}
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(7)}
              >
                5.00 EUR
              </Button>
            )}
          </div>
        </div>
      </Col>
      <Col className="room-ended-modal-new-premium-pack-col">
        <div>
          <div className={classNames('buy-page-premium-imageWrapper', {})} onClick={() => buyMoney(8)}>
            <Media
              className="buy-page-premium-image"
              src={profileResetImg}
              alt=""
            />
          </div>
          <div className="buy-page-premium-wrapper">
            <div className="buy-page-premium-amount room-ended-modal-new-premium-amount">
              {t("buyPage.profileRestart")}
            </div>
            <div className="buy-page-info-imageWrapper room-ended-modal-new-info-wrapper">
              <Media
                className="buy-page-info-image room-ended-modal-new-info-image"
                onMouseEnter={() => mouseEnter("8")}
                onMouseLeave={() => mouseLeave("8")}
                src={
                  screenMode === constants.SCREEN_MODE.light
                    ? lightInfoImg
                    : infoImg
                }
                alt=""
              />
              <div
                className={`buy-page-info-hover buy-page-info-hover-profile room-ended-modal-new-info-hover ${
                  activeHover === "8" ? null : "display-none"
                }`}
              >
                <label className="mr-1">{`${t(
                  "buyPage.profileRestart"
                )}:`}</label>
                {t("buyPage.profileRestartDesc")}
              </div>
            </div>
          </div>
          <div className="buy-page-premium-button-wrapper">
            {discounts.length > 0 ? (
              filter(
                discounts,
                (item) => item.type === constants.SPECIALPRICE
              )[0] ? (
                <>
                  <Button
                    disabled
                    style={{ textDecorationLine: "line-through" }}
                    color="link"
                    className={classNames("layout-default-button", {
                      // "layout-darkHighlighted-button":
                      //   screenMode !== constants.SCREEN_MODE.light,
                      // "layout-redHighlighted-button":
                      //   screenMode === constants.SCREEN_MODE.light,
                    })}
                    onClick={() => buyMoney(8)}
                  >
                    5.00 EUR
                  </Button>
                  <Button
                    color="link"
                    className="layout-default-button layout-redHighlighted-button"
                    onClick={() => buyMoney(8)}
                  >
                    {`${
                      filter(
                        discounts,
                        (item) => item.type === constants.SPECIALPRICE
                      )[0].specialPrice["money6"]
                    } EUR`}
                  </Button>
                </>
              ) : (
                <Button
                  color="link"
                  className={classNames("layout-default-button", {
                    // "layout-darkHighlighted-button":
                    //   screenMode !== constants.SCREEN_MODE.light,
                    // "layout-redHighlighted-button":
                    //   screenMode === constants.SCREEN_MODE.light,
                  })}
                  onClick={() => buyMoney(8)}
                >
                  5.00 EUR
                </Button>
              )
            ) : (
              <Button
                color="link"
                className={classNames("layout-default-button", {
                  // "layout-darkHighlighted-button":
                  //   screenMode !== constants.SCREEN_MODE.light,
                  // "layout-redHighlighted-button":
                  //   screenMode === constants.SCREEN_MODE.light,
                })}
                onClick={() => buyMoney(8)}
              >
                5.00 EUR
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );

  const moneyPackTabs = (
    <div>
      <Row className="layout-subheader">
        <Col className="d-flex justify-content-end">
          <Button
            color="link"
            className={`room-ended-modal-new-button room-ended-modal-new-button-${screenMode} ${
              activeTab === constants.SHOP_NAVIGATION.moneyPacks
                ? `room-ended-modal-new-button-active-${screenMode}`
                : null
            }`}
            onClick={() => toggle(constants.SHOP_NAVIGATION.moneyPacks)}
          >
            {t("buyPage.moneyPacks")}
          </Button>
        </Col>
        <Col className="d-flex justify-content-start">
          <Button
            color="link"
            className={`room-ended-modal-new-button  room-ended-modal-new-button-${screenMode} ${
              activeTab === constants.SHOP_NAVIGATION.premiumPacks
                ? `room-ended-modal-new-button-active-${screenMode}`
                : null
            }`}
            onClick={() => toggle(constants.SHOP_NAVIGATION.premiumPacks)}
          >
            {t("buyPage.premiumPacks")}
          </Button>
        </Col>
      </Row>
      <TabContent className="buy-page-tabContent" activeTab={activeTab}>
        <TabPane
          tabId={constants.SHOP_NAVIGATION.moneyPacks}
          className="buy-page-tab"
        >
          {coinPacks}
        </TabPane>
        <TabPane
          tabId={constants.SHOP_NAVIGATION.premiumPacks}
          className="buy-page-tab"
        >
          {premiumPacks}
        </TabPane>
      </TabContent>
    </div>
  );

  const messageTitle = balance < 0 ? t('buyPage.negativeBalanceTitle') : t('buyPage.lowBalanceTitle');
  const messageDescription = balance < 0 ? t('buyPage.negativeBalanceMsg') : t('buyPage.lowBalanceMsg');

  return (
    <div className="room-ended-modal-new-wrapper">
      <div className="room-ended-modal-new-message">
        <div className="room-ended-modal-new-message-title">{messageTitle}</div>
        <div className="room-ended-modal-new-message-coins">
          <Media src={coinImg} className="room-ended-modal-new-message-coins-coin" />
          <div className="room-ended-modal-new-message-coins-label">
            {balance}
          </div>
        </div>
        <p className="room-ended-modal-new-message-description">
          {messageDescription}
        </p>
      </div>
      <div className="room-ended-modal-new-buy-options">
        {balance < 0 ? moneyPackTabs : coinPacks}
      </div>
    </div>
  );
};

RoomEndedModal.propTypes = {
  discounts: PropTypes.arrayOf(PropTypes.shape({})),
  balance: PropTypes.number,
  totalPoints: PropTypes.number,
  buyMoney: PropTypes.func,
  fetchDiscounts: PropTypes.func.isRequired,
  screenMode: PropTypes.string,
  t: PropTypes.func.isRequired,
};

RoomEndedModal.defaultProps = {
  discounts: [],
  balance: 0,
  screenMode: constants.SCREEN_MODE.normal,
};

const mapStateToProps = (state) => ({
  discounts:
    state.shop.discounts,
  totalPoints: state.member.totalPoints,
});

const mapDispatchToProps = {
  fetchDiscounts: getDiscounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("common")(RoomEndedModal));
