import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'reactstrap/lib/Spinner';

import { useTranslation } from 'react-i18next';
// import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';

import { size } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import {
  ModalBody, ModalHeader, Modal, ModalFooter,
} from 'reactstrap';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import PartyLog from './PartyLog';

import myInfoImg from '../../../images/icons/my_profile.png';
import infoImg from '../../../images/icons/info.svg';
import closeImg from '../../../images/icons/close.png';

import {
  getGameLogs,
} from '../../../actions/member';

import * as constants from '../../../constants/constants';
import PaginationOld from './PaginationOld';

const GameLogs = ({
  achievementsNotification, userSettings, uid, changeTab, fontMode,
}) => {
  const [openPartyModal, setOpenPartyModal] = useState(false);
  const [party, setParty] = useState({});
  const [selectRoom, setSelectRoom] = useState(null);
  const [partyRoomData, setPartyRoomData] = useState({});
  const [roomPlayerImgData, setRoomPlayerImgData] = useState({});
  const [roomPlayerData, setRoomPlayerData] = useState({});
  const [gameLogsInfoModalOpen, setGameLogsInfoModalOpen] = useState(false);
  const [countRoom, setCountRoom] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pulesModal, setPulesModal] = useState(false);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const gameLogs = useSelector(state => state.member.gameLogs);
  const isloading = useSelector(state => state.member.isloading);

  useEffect(() => {
    dispatch(getGameLogs());
  }, []);

  const changeTabA = (item) => {
    changeTab(item);
  };

  const togglePartyLog = (roomId, idx, pulesTitle) => {
    setOpenPartyModal(true);
    setParty(gameLogs[roomId].roomData[idx + 1]);
    setSelectRoom(roomId);
    setPartyRoomData(gameLogs[roomId].roomData);
    setRoomPlayerData(gameLogs[roomId].playerData);
    setRoomPlayerImgData(gameLogs[roomId].playerImg);
    setPulesModal(pulesTitle || false);
  };

  const closePartyLog = () => {
    setOpenPartyModal(false);
    setParty({});
    setSelectRoom(null);
    setPartyRoomData({});
    setRoomPlayerData({});
    setRoomPlayerImgData({});
    setPulesModal(false);
  };

  const gameLogsModal = () => {
    setGameLogsInfoModalOpen(prevGameLogsInfoModalOpen => !prevGameLogsInfoModalOpen);
  };

  const totalNum = () => {
    let count = countRoom;
    setCountRoom(count++);
    return (
      <div>
        {count++}
      </div>
    );
  };

  const handlePagination = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const pagesCount = gameLogs ? Math.ceil(Object.keys(gameLogs).length / pageSize) : 1;

  return (
    <>
      <div className="my-info">
        <Helmet>
          <title>
            Zole -
            {' '}
            {t('myInfo.gameLogs')}
          </title>
        </Helmet>
        <Row className="my-info-header mb-4">
          <Col xs="3" className="d-flex pr-0">
            <Media src={myInfoImg} className="my-info-header-image" />
            <div className="my-info-header-text" />
            <Media className="game-logs-info" src={infoImg} onClick={gameLogsModal} alt="x" />
          </Col>
          <Col xs="9" style={{ paddingRight: 30 }}>
            <Row>
              <Col xs="2" className="menu-topTab menu-myInfoTab my-info-menu">
                <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                  {t('myInfo.myInfo')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.friends)}>
                  {t('myInfo.friends')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.ignoredUsers)}>
                  {t('myInfo.ignoredPlayers')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.archievements)}>
                  {t('myInfo.achievements')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.gameHistory)}>
                  {t('myInfo.results')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab">
                <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.weeklyStatus)}>
                  {t('myInfo.weeklyStatus')}
                </Button>
              </Col>
              <Col xs="2" className="menu-topTab menu-myInfoTab game-logs-menu">
                <Button color="link" className="my-info-header-button active" onClick={() => changeTab(constants.MENU_NAVIGATION.gameLogs)}>
                  {t('myInfo.gameLogs')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-info-content-joyride">
          <Col sm="12" className="game-logs mt-4">
            <ScrollAreaWrapper
              className="game-logs-scrollarea mb-2"
              contentClassName="game-logs-scrollarea-body"
              show
              scrollbarId="top-page-scrollbar"
              rightOffset={12}
              topOffset={0}
              bottomOffset={0}
              horizontal
              vertical
            >
              {
                isloading ? (
                  <div className="top-table-spinner-loading-section d-flex align-items-center">
                    <Spinner color="light" style={{ width: '5rem', height: '5rem' }} />
                  </div>
                ) : (
                  gameLogs && size(gameLogs) > 0 ? (
                    Object.keys(gameLogs).sort((a, b) => gameLogs[b].orderDate - gameLogs[a].orderDate).slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((key, index) => (
                      <>
                        {gameLogs[key].roomData[0] ? (
                          <table className="game-logs-table mt-2" key={key}>
                            <thead>
                              <tr className="game-logs-table-header">
                                <th>#</th>
                                <th>{t('common.date')}</th>
                                <th className="text-center">
                                  {gameLogs[key].roomData[0] ? (gameLogs[key].roomData[0].player1 || '') : ''}
                                </th>
                                <th className="text-center">
                                  {gameLogs[key].roomData[0] ? (gameLogs[key].roomData[0].player2 || '') : ''}
                                </th>
                                <th className="text-center">
                                  {gameLogs[key].roomData[0] ? (gameLogs[key].roomData[0].player3 || '') : ''}
                                </th>
                                {(gameLogs[key].roomData[0] && gameLogs[key].roomData[0].player4) ? (
                                  <th className="text-center">
                                    {gameLogs[key].roomData[0].player4 || ''}
                                  </th>
                                ) : (null)}
                                <th className="text-center">
                                  {t('common.pules')}
                                </th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {
                                gameLogs[key].roomData && gameLogs[key].roomsPoints && gameLogs[key].roomsPoints && Object.keys(gameLogs[key].roomsPoints.rounds).map((pointKey, index) => (
                                  <>
                                    {gameLogs[key].roomData[index + 1] ? (
                                      <tr className="game-logs-table-row" key={pointKey}>
                                        <td>
                                          {(index + 1) === size(gameLogs[key].roomsPoints.rounds) && gameLogs[key].roomsPoints.rounds[pointKey].id === '-' ? (
                                            t('common.pules')
                                          ) : (
                                            index + 1
                                          )}
                                        </td>
                                        <td>
                                          {
                                            gameLogs[key].roomData[index + 1] ? (Object.keys(gameLogs[key].roomData[index + 1]).map((roundKey, roundIdx) => (
                                              roundIdx === 0 ? (
                                                <Moment format={constants.FORMAT_DATE_TYPE.fullDateMinute} locale={constants.sendLanguage.lv}>
                                                  {gameLogs[key].roomData[index + 1][roundKey].time}
                                                </Moment>
                                              ) : (
                                                null
                                              )
                                            ))) : (null)
                                          }
                                        </td>
                                        <td>
                                          <div className="text-center">
                                            {gameLogs[key].roomsPoints.rounds[pointKey].player1}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="text-center">
                                            {gameLogs[key].roomsPoints.rounds[pointKey].player2}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="text-center">
                                            {gameLogs[key].roomsPoints.rounds[pointKey].player3}
                                          </div>
                                        </td>
                                        {(gameLogs[key].roomData[0].player4) && (
                                          <td>
                                            <div className="text-center">
                                              {gameLogs[key].roomsPoints.rounds[pointKey].player4}
                                            </div>
                                          </td>
                                        )}
                                        <td className="text-center">
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'P' && (
                                            <div className="text-center"> P </div>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'P-' && (
                                            <span className="strikeout-new-normal text-center"> P </span>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player1' && (
                                            <div className="text-center">
                                              {gameLogs[key].roomData[0].player1}
                                            </div>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player1-' && (
                                            <span className=" strikeout-new-normal text-center">
                                              {gameLogs[key].roomData[0].player1}
                                            </span>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player2' && (
                                            <div className="text-center">
                                              {gameLogs[key].roomData[0].player2}
                                            </div>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player2-' && (
                                            <span className="strikeout-new-normal text-center">
                                              {gameLogs[key].roomData[0].player2}
                                            </span>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player3' && (
                                            <div className="text-center">
                                              {gameLogs[key].roomData[0].player3}
                                            </div>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player3-' && (
                                            <span className="strikeout-new-normal text-center">
                                              {gameLogs[key].roomData[0].player3}
                                            </span>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player4' && (
                                            <div className="text-center">
                                              {gameLogs[key].roomData[0].player4}
                                            </div>
                                          )}
                                          {gameLogs[key].roomsPoints.rounds[pointKey].pule === 'player4-' && (
                                            <span className="strikeout-new-normal text-center">
                                              {gameLogs[key].roomData[0].player4}
                                            </span>
                                          )}
                                        </td>
                                        <td className="game-logs-table-row-info">
                                          <Media src={infoImg} alt="x" onClick={() => togglePartyLog(key, index, (index + 1) === size(gameLogs[key].roomsPoints.rounds) && gameLogs[key].roomsPoints.rounds[pointKey].id === '-' ? 'pulesCalculateModal' : '')} />
                                        </td>
                                      </tr>
                                    ) : (null)}
                                  </>
                                ))}
                              {size(gameLogs[key].roomsPoints.rounds) > 1 && (
                              <tr className="game-logs-table-row" key="total-row">
                                <td />
                                <td>{t('common:results.total')}</td>
                                <td><div className="text-center">{gameLogs[key].roomsPoints.total.player1}</div></td>
                                <td><div className="text-center">{gameLogs[key].roomsPoints.total.player2}</div></td>
                                <td><div className="text-center">{gameLogs[key].roomsPoints.total.player3}</div></td>
                                {(gameLogs[key].roomData[0].player4) && (
                                <td>
                                  <div className="text-center">
                                    {gameLogs[key].roomsPoints.total.player4}
                                  </div>
                                </td>
                                )}
                                <td />
                                <td />
                              </tr>
                              )}
                            </tbody>
                          </table>
                        ) : (null)}
                      </>
                    ))
                  ) : (
                    <div className="top-table-spinner-loading-section d-flex align-items-center">
                      {t('home.noGameLog')}
                    </div>
                  )
                )
              }
            </ScrollAreaWrapper>
            <PaginationOld
              show={(gameLogs ? Object.keys(gameLogs).length > pageSize : false)}
              currentPage={currentPage}
              pagesCount={pagesCount}
              handlePagination={handlePagination}
            />
          </Col>

        </Row>
        <PartyLog party={party} roomPlayerImgData={roomPlayerImgData} partyRoomData={partyRoomData} roomPlayerData={roomPlayerData} closePartyLog={closePartyLog} togglePartyLog={togglePartyLog} openPartyModal={openPartyModal} fontMode={fontMode} pulesModal={pulesModal} />
      </div>
      <Modal container={'div > div'} isOpen={gameLogsInfoModalOpen} toggle={gameLogsModal} size="md" className={`old-modal root-font-${fontMode}`}>
        <ModalHeader
          toggle={gameLogsModal}
          close={
            <Media src={closeImg} className="notification-header-close" alt="X" onClick={gameLogsModal} />
        }
        >
          {t('myInfo.gameLogs')}
        </ModalHeader>
        <ModalBody>
          {t('home.gameLogsInfo')}
        </ModalBody>
        <ModalFooter>
          {/* <Button className="modal-footer-button tutorial-result-button mb-3" color="secondary" onClick={gameLogsModal}> */}
          <Button className="modal-footer-button mb-3" color="secondary" onClick={gameLogsModal}>
            {t('common.close')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

GameLogs.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
  fontMode: PropTypes.string,
};

GameLogs.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
  fontMode: constants.FONT_MODE.normal,
};

export default GameLogs;
