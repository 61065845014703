export const generalMessages = {};

export const ROOMS = 'rooms';
export const MISSEDROOMS = 'missedRooms';
export const ENVPERROOM = 'envPerRoom';
export const GAMETYPE = 'gameType';
export const BETS = 'bets';
export const GAMESPEED = 'gameSpeed';
export const FASTGAME = 'fastGame';
export const LIGHTNINGGAME = 'lightningGame';
export const PRIVATEROOM = 'privateRoom';
export const PROGAME = 'proGame';
export const LOGINS = 'logins';
export const NEWUSERS = 'newUsers';
export const TOTALPLAYERS = 'totalPlayers';
export const ACTIVITY = 'activity';
export const ACTIVEPLAYERDAY = 'activePlayersDay';
export const ACTIVEPLAYERSWEEK = 'activePlayersWeek';
export const ACTIVEPLAYERSMONTH = 'activePlayersMonth';
export const ACTIVEPLAYERSYEAR = 'activePlayersYear';
export const TRANSACTIONS = 'transactions';
export const PURCHASEDMONEY = 'purchasedMoney';
export const GAMESINROOM = 'gamesInRoom';
export const USERSCOINANALYSIS = 'usersCoinAnalysis';
export const LASTONLINEPLAYERS = 'lastOnlinePlayers';
export const ROOMPLAYERCOUNT = 'roomPlayerCount';
export const DELETEDPLAYERS = 'deletedPlayers';
export const RETURNEDPLAYERS = 'returnedPlayers';
export const PLAYERSPERLANGUAGE = 'playersPerLanguage';
export const BALANCEPERPLAYERS = 'balancePerPlayers';
export const COINPERCHANGES = 'coinPerChanges';
export const PLAYERSPERFONTMODE = 'playersPerFontMode';
export const PLAYERSPERDESIGN = 'playersPerDesign';
export const PLAYERACHIEVEMENTS = 'playerAchievements';
export const PLAYERACTIVETHEME = 'playerActiveTheme';
export const PLAYERSPERSOUND = 'playersPerSound';
export const PLAYERSPERAUTOMERGEROOMS = 'playersPerAutoMergeRooms';
export const PLAYERSFULLSCREEN = 'playersFullScreen';
export const PLAYERSVIEWSPERSCREEN = 'playersViewsPerScreen';
export const PLAYERSPERBROWSER = 'playersPerBrowser';
export const PLAYERSPERSCREENSIZE = 'playersPerScreenSize';
export const PLAYERSPERSCREENSIZEFOREACHDESIGN = 'playersPerScreenSizeForEachDesign';
export const PLAYERSPERSCREENSIZEFOREACHSCREENMODE = 'playersPerScreenSizeForEachScreenMode';
export const GIFTSHISTORY = 'giftsHistory';
export const ONELINEPLAYERS = 'onlinePlayers';

export const statisticsTypes = [
  {
    id: 'rooms', label: 'rooms', tLabel: 'Rooms', link: ['rooms/rooms', 'players/totalPlayersHistory'], chartType: 'common-date', description: 'Shows how many rooms have been played.',
  },
  {
    id: 'missedRooms', label: 'missedTurns', tLabel: 'Missed Turns', description: 'Shows how many moves have been missed.', link: ['rooms/missedRooms', 'rooms/missedRoomsAutomated', 'rooms/startedAutomated', 'rooms/missedRoomsont', 'rooms/missedRoomsond', 'rooms/missedRoomsofft', 'rooms/missedRoomsoffd', 'rooms/missedRoomsWeb', 'rooms/missedRoomsApp'], chartType: 'common-date',
  },
  {
    id: 'envPerRoom', label: 'Room players by enviroment', tLabel: 'Env Per Room', description: 'Shows how many players have played each of the game types.', link: ['rooms/webRooms', 'rooms/appRooms'], chartType: 'common-date',
  },
  {
    id: 'gameType', label: 'gameType', tLabel: 'Game Type', description: 'Shows how many players have played each of the game types.', link: ['rooms/G', 'rooms/MG', 'rooms/P', 'rooms/PM'], chartType: 'common-date',
  },
  {
    id: 'bets', label: 'bets', tLabel: 'Bets', description: 'Shows how many players have played each of the bets.', link: ['rooms/bets'], chartType: 'common-date',
  },
  {
    id: 'gameSpeed', label: 'gameSpeed', tLabel: 'Game Speed', description: 'Shows how many players have played each of the game speed options.', link: ['rooms/fastGame', 'rooms/lightningGame', 'rooms/unlimitedGame', 'rooms/slowGame'], chartType: 'common-date',
  },
  // {
  //   id: 'fastGame', label: 'fastGame', tLabel: 'Fast Game', description: 'Shows how many players have played each of the game speed options.', link: ['rooms/fastGame'], chartType: 'common',
  // },
  // {
  //   id: 'lightningGame', label: 'lightningGame', tLabel: 'Lightning Game', description: 'Shows how many players have played each of the game speed options.', link: ['rooms/lightningGame'], chartType: 'common',
  // },
  // {
  //   id: 'unlimitedGame', label: 'unlimitedGame', tLabel: 'Unlimited Game', description: 'Shows how many players have played each of the game speed options.', link: ['rooms/unlimitedgGame'], chartType: 'common',
  // },
  {
    id: 'privateRoom', label: 'privateRoom', tLabel: 'Private Room', description: 'Shows how many private rooms have been played.', link: ['rooms/privateRoom'], chartType: 'common',
  },
  {
    id: 'proGame', label: 'proGame', tLabel: 'Pro Room', description: 'Shows how many Pro rooms have been played.', link: ['rooms/proGame'], chartType: 'common',
  },
  {
    id: 'logins', label: 'logins', tLabel: 'Login Type', description: 'Shows how many players have signed in per each login method.', link: ['players/loginsTotal', 'players/logins'], chartType: 'common-date',
  },
  {
    id: 'newUsers', label: 'newUsers', tLabel: 'New Users', description: 'Shows how many new players have registered per platform.', link: ['players/newUsersTotal', 'players/newUsers'], chartType: 'common-date',
  },
  {
    id: 'totalPlayers', label: 'totalPlayers', tLabel: 'Total Players', description: 'Shows how many players in total are registered per platform.', link: ['players/totalPlayersHistory'], chartType: 'common-date',
  },
  {
    id: 'activity', label: 'activity', tLabel: 'Activity', description: 'Shows how many players have been active per given periods.', link: ['players/activePlayersDay', 'players/activePlayersWeek', 'players/activePlayersMonth', 'players/activePlayersYear', 'players/playersYearOld'], chartType: 'common-date',
  },
  {
    id: 'transactions', label: 'transactionsPerPlatform', tLabel: 'Transactions Platform', description: 'Shows how many purchases have been made per platform.', link: ['transactions/platforms', 'transactions/total'], chartType: 'common-date',
  },
  {
    id: 'purchasedMoney', label: 'purchasedMoney', tLabel: 'Purchases', description: 'Shows how many purchases have been made per package.', link: ['transactions/types'], chartType: 'common-date',
  },
  {
    id: 'gamesInRoom', label: 'gamesInRoom', tLabel: 'Games per Room', description: 'Shows how many games are played in the rooms.', link: ['rooms/roundsPlayed'], chartType: 'common-date',
  },
  {
    id: 'usersCoinAnalysis', label: 'usersCoinAnalysis', tLabel: 'Buying Players', description: 'Shows how many players are making purchases per platform.', link: ['usersLastPurchase/all', 'usersLastPurchase/draugiem', 'usersLastPurchase/facebook', 'usersLastPurchase/mobile', 'usersLastPurchase/everyPay', 'usersLastPurchase/inbox', 'players/activePlayersDay'], chartType: 'common-date',
  },
  // {
  //   id: 'lastOnlinePlayers', label: 'lastOnlinePlayers', tLabel: 'Last Online Players', link: ['activePlayersPerPlatform/draugiem', 'activePlayersPerPlatform/apple', 'activePlayersPerPlatform/email', 'activePlayersPerPlatform/facebook', 'activePlayersPerPlatform/google', 'activePlayersPerPlatform/inbox'], chartType: 'common-date',
  // },
  {
    id: 'roomPlayerCount', label: 'roomPlayerCount', tLabel: '3- and 4-players Room', description: 'Shows how many players have played 3- and 4-players rooms.', link: ['rooms/roomPlayerCount'], chartType: 'common-date',
  },
  {
    id: 'deletedPlayers', label: 'deletedPlayers', tLabel: 'Deleted Players', description: 'Shows how many players have deleted the account.', link: ['players/deletedUsersTotal', 'players/deletedUsers/apple', 'players/deletedUsers/draugiem', 'players/deletedUsers/email', 'players/deletedUsers/facebook', 'players/deletedUsers/google', 'players/deletedUsers/inbox'], chartType: 'common-date',
  },
  {
    id: 'returnedPlayers', label: 'returnedPlayers', tLabel: 'Returned Players', description: 'Shows how many players have restored the account after completely deleting it.', link: ['players/returnedPlayers'], chartType: 'common-date',
  },
  {
    id: 'playersPerLanguage', label: 'playersPerLanguage', tLabel: 'Players Per Language', description: 'Shows how many players are using the interface per each language.', link: ['players/playersPerLanguage'], chartType: 'common-date',
  },
  {
    id: 'balancePerPlayers', label: 'balancePerPlayers', tLabel: 'Balance Per Players', link: ['players/balances'], chartType: 'common-date',
  },
  {
    id: 'coinPerChanges',
    label: 'coinPerChanges',
    tLabel: 'Coin Per Changes',
    description: 'Shows how many coins have changed per action type.',
    link: [
      /* 'money/won',
      'money/lost',
      'money/purchased',
      'money/admin',
      'money/resetBalance',
      'money/missedTurn',
      'money/profanity',
      'money/buyGift',
      'money/removeGift',
      'money/dailyBonus',
      'money/roomFee',
      'money/tournamentEntryFee',
      'money/friendInviteBonus',
      'money/claimedBonus',
      'money/claimedGiftCode',
      'money/claimedAchievement',
      'money/emailBonus',
      'money/claimedOSBonus', */

      'money/roomFee',
      'money/lost',
      'money/won',
      'money/buyGift',
      'money/removeGift',
      'money/purchased',
      'money/resetBalance',
      'money/resetProfile',
      'money/dailyBonus',
      'money/dailyBonusAdded',
      'money/dailyBonusRemoved',
      'money/tournamentEntryFee',
      'money/tournamentWon',
      'money/friendInviteBonus',
      'money/claimedBonus',
      'money/claimedGiftCode',
      'money/claimedAchievement',
      'money/emailBonus',
      'money/claimedTutorialBonus',
      'money/claimedOSBonus',
      'money/claimedAndroidBonus',
      'money/claimediOSBonus',
      'money/autoCompensation',
      'money/minGamesPenalty',
      'money/minGamesReward',
      'money/leftRoomPenalty',
      'money/leftRoomReward',
      'money/admin',
      'money/profanity',
      'money/adminAdded',
      'money/adminRemoved',
      'money/botsTopUp',
      'money/missedTurn',
      'money/missedTurnReward',
      'money/startBonus',
      'money/deletedProfile',
    ],
    chartType: 'common-date',
  },
  {
    id: 'playersPerFontMode', label: 'playersPerFontMode', tLabel: 'Font Size', description: 'Shows how many players have enabled each of the font sizes.', link: ['players/playersPerFontMode'], chartType: 'common-date',
  },
  {
    id: 'playersPerDesign', label: 'playersPerDesign', tLabel: 'Players Per Design', description: 'Shows how many players have switched to another design.', link: ['players/playersPerDesign', 'players/playersPerDesignDev'], chartType: 'common-date',
  },
  {
    id: 'playerAchievements', label: 'playerAchievements', tLabel: 'Achievements', description: 'Shows how many players have gained each achievement per level.', link: ['playerAchievements'], chartType: 'common-date',
  },
  {
    id: 'playerActiveTheme', label: 'playerActiveTheme', tLabel: 'Design Theme', description: 'Shows how many players have used each of the themes.', link: ['players/playersPerScreenMode', 'players/playersPerScreenModeDev'], chartType: 'common-date',
  },
  {
    id: 'playersPerSound', label: 'playersPerSound', tLabel: 'Sound', description: 'Shows how many players have enabled or disabled the sound effects from the game options.', link: ['players/playersPerSound'], chartType: 'common-date',
  },
  {
    id: 'playersPerAutoMergeRooms', label: 'playersPerAutoMergeRooms', tLabel: 'Auto-join Rooms', description: 'Shows how many players have enabled or disabled the option to automatically join the room.', link: ['players/playersPerAutoMergeRooms'], chartType: 'common-date',
  },
  {
    id: 'playersFullScreen', label: 'playersFullScreen', tLabel: 'Full Screen', description: 'Shows how many players have enabled the full-screen mode on dWeb and iFrame layouts.', link: ['players/fullscreen'], chartType: 'common-date',
  },
  {
    id: 'playersViewsPerScreen', label: 'playersViewsPerScreen', tLabel: 'Views Per Screen', description: 'Shows how many times each of the listed screens has been opened (in both designs together).', link: ['players/viewsPerScreen'], chartType: 'common-date',
  },
  {
    id: 'playersPerBrowser', label: 'playersPerBrowser', tLabel: 'Browsers', description: 'Shows how many players have played per browser.', link: ['players/playersPerBrowser'], chartType: 'common-date',
  },
  // {
  //   id: 'playersPerScreenSize', label: 'playersPerScreenSize', tLabel: 'Player Screen Size', link: ['players/playersPerScreenSize'], chartType: 'common-date',
  // },
  {
    id: 'playersPerScreenSizeForEachDesign', label: 'playersPerScreenSizeForEachDesign', tLabel: 'Screen Size', description: 'Shows how many players have played per screen size on each platform and design.', link: ['players/playersPerScreenSizeForEachDesign'], chartType: 'common-date',
  },
  {
    id: 'playersPerScreenSizeForEachScreenMode', label: 'playersPerScreenSizeForEachScreenMode', tLabel: 'Screen Size for New Design', description: 'Shows how many players have played per screen size on the new design.', link: ['players/playersPerScreenSizeForEachScreenMode'], chartType: 'common-date',
  },
  {
    id: 'giftsHistory', label: 'giftsHistory', tLabel: 'Gifts', description: 'Shows how many times the gifts of every type are buying and removing.', link: ['gifts/send', 'gifts/remove'], chartType: 'common-date',
  },
  {
    id: 'onlinePlayers', label: 'onlinePlayers', tLabel: 'Online Players', description: 'Shows how many players have been online and how many active rooms have been at the given time.', link: ['online'], chartType: 'common-date',
  },
];

export const STATISTICS_PERIOD_TIME = {
  day: 1, week: 7, month: 0,
};

export const STATISTICS_ADMIN_TIMEZONE = {
  timezone: 'Europe/Riga', timelocal: 'en-US',
};

export const STATISTICS_FILTER_TYPE = {
  history: 'History',
  roundsPlayed: 'roundsPlayed',
};

export const STATISTICS_CHART_TYPE = {
  common: 'common',
  commonDate: 'common-date',
};

export const STATISTICS_VIEW_SCREEN_OPTIONS = [
  { value: 'dWeb', label: 'dWeb' },
  { value: 'mWeb', label: 'mWeb' },
  { value: 'iFrame', label: 'iFrame' },
  { value: 'app', label: 'app' },
];

export const STATISTICS_VIEW_DEVICE_OPTIONS = [
  { value: 'desktop', label: 'Desktop' },
  { value: 'mobile', label: 'Mobile' },
];

export const STATISTICS_SCREEN_SIZE_PLATFORM = [
  { value: 'desktop', lablel: 'Desktop' },
  { value: 'draugiem', lablel: 'Draugiem' },
  { value: 'facebook', lablel: 'Facebook' },
  { value: 'inbox', lablel: 'Inbox' },
  { value: 'mobile', lablel: 'Mobile' },
];

export const STATISTICS_DESIGN_MODE = [
  { value: 'old', label: 'Old Design' },
  { value: 'new', label: 'New Design' },
];

export const STATISTICS_ENV_MODE = [
  { value: 'dev', label: 'Development' },
  { value: 'prod', label: 'Production' },
];

export const STATISTICS_THEME_MODE = [
  { value: 'normal', label: 'Normal Theme' },
  { value: 'dark', label: 'Dark Theme' },
  { value: 'light', label: 'Light Theme' },
];

export const CAMPAIGN_LOCATIOIN_OPTIONS = [
  { value: 'loading', label: 'Loading' },
  { value: 'lobby', label: 'Lobby' },
  { value: 'openRoom', label: 'Open Room' },
];

export const CAMPAIGN_PLATFORMS = [
  { value: 'web', label: 'Web' },
  { value: 'draugiem', label: 'Draugiem' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'inbox', label: 'Inbox' },
  { value: 'mobile', label: 'Mobile' },
];

export const UN_CORRESPONDING_BROWSERS = ['Baidu', 'Brave'];

export const STATISTICS_ROUND_RANGE = [
  {
    value: {
      roundStart: 1, roundEnd: 20,
    },
    label: '1 - 20',
  },
  {
    value: {
      roundStart: 21, roundEnd: 40,
    },
    label: '21 - 40',
  },
  {
    value: {
      roundStart: 41, roundEnd: 60,
    },
    label: '41 - 60',
  },
  {
    value: {
      roundStart: 61, roundEnd: 80,
    },
    label: '61 - 80',
  },
  {
    value: {
      roundStart: 81, roundEnd: 100,
    },
    label: '81 - 100',
  },
];

export const ACHIEVEMENT_TYPES = {
  gamesPlayed: 'Games played',
  gamesWon: 'Games won ',
  highestEarned: 'Earned in total',
  earnedInADay: 'Earned a day',
  maxSuccessionWins: 'Consecutive wins',
  maxSuccessionLosses: 'Consecutive wins',
  zolePlayed: 'Zole played',
  zoleWon: 'Zole won',
  fastGamesPlayed: 'Fast games played',
  lightningGamesPlayed: 'Lightning games played',
  unlimitedGamesPlayed: 'Unlimited games played',
  mazaZolePlayed: 'Small Zole played',
  mazaZoleWon: 'Small Zole won',
  bonusSpins: 'Times spun wheel of fortune',
  joinedTournaments: 'Tournaments participated',
  reachedTournamentTop10: 'Times in tournament TOP 10',
  reachedTop100: 'Days was in the TOP 100',
  giftsSent: 'Sent gifts',
  giftsReceived: 'Received gifts',
  maxParties: 'Games in one room',
  supportMessagesSent: 'Messages to administration',
  storePurchase: 'Shop purchase',
  galdinsPlayed: 'Played table',
  galdinsWon: 'Won table',
  galdinsLose: 'Lost table',
  maxDailyLogin: 'Days in a row',
};

export const FEEDBACK_PLATFORM = [
  { value: 'dWeb', label: 'Desktop Web' },
  { value: 'mWeb', label: 'Mobile Web' },
  { value: 'draugiem', label: 'Draugiem' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'inbox', label: 'Inbox' },
];

export const GIFTS_TYPE = [
  { value: 'send', label: 'Buy' },
  { value: 'remove', label: 'Remove' },
];

export const hours = [
  '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
];
