import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
// import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import { map, get } from 'lodash';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';

import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import myInfoImg from '../../../images/icons/my_profile.png';

import {
  getWeeklyStatistics,
} from '../../../actions/member';
import * as constants from '../../../constants/constants';

const WeeklyStatus = ({ achievementsNotification, userSettings, uid, changeTab, fontMode }) => {

  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const weeklyStatistics = useSelector(state => state.member.weeklyStatistics);

  useEffect(() => {
    dispatch(getWeeklyStatistics());
  }, [fontMode]);


  const getTrend = (week1, week2) => {
    const trend = week1 - week2;

    return trend;
  };

  const changeTabA = (item) => {
    changeTab(item);
  };

  // console.log("weekly status");

  return (
    <div className="my-info">
      <Helmet>
        <title>
          {`Zole - ${t('myInfo.weeklyStatus')}`}
        </title>
      </Helmet>
      <Row className="my-info-header">
        <Col xs="3" className="pr-0" style={{display: 'flex'}}>
          <Media src={myInfoImg} className="my-info-header-image" />
          <div className="my-info-header-text">
            {t('myInfo.weeklyStatus')}
          </div>
        </Col>
        <Col xs="9" style={{ paddingRight: 30 }}>
          <Row>
            <Col xs="2" className="menu-topTab menu-myInfoTab my-info-menu">
              <Button color="link" className="my-info-header-button" onClick={() => changeTab(constants.MENU_NAVIGATION.myInfo)}>
                {t('myInfo.myInfo')}
              </Button>
            </Col>
            <Col xs="2" className="menu-topTab menu-myInfoTab">
              <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.friends)}>
                {t('myInfo.friends')}
              </Button>
            </Col>
            <Col xs="2" className="menu-topTab menu-myInfoTab">
              <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.ignoredUsers)}>
                {t('myInfo.ignoredPlayers')}
              </Button>
            </Col>
            <Col xs="2" className="menu-topTab menu-myInfoTab">
              {/* <Button color="link" className={`my-info-header-button ${achievementsNotification ? 'my-info-header-button-notification' : ''}`} onClick={() => changeTabA(constants.MENU_NAVIGATION.archievements)}> */}
              <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.archievements)}>
                {t('myInfo.achievements')}
              </Button>
            </Col>
            <Col xs="2" className="menu-topTab menu-myInfoTab">
              <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.gameHistory)}>
                {t('myInfo.results')}
              </Button>
            </Col>
            <Col xs="2" className="menu-topTab menu-myInfoTab weekly-statistics-menu">
              <Button color="link" className="my-info-header-button active" onClick={() => changeTabA(constants.MENU_NAVIGATION.weeklyStatus)}>
                {t('myInfo.weeklyStatus')}
              </Button>
            </Col>
            <Col xs="2" className="menu-topTab menu-myInfoTab">
              <Button color="link" className="my-info-header-button" onClick={() => changeTabA(constants.MENU_NAVIGATION.gameLogs)}>
                {t('myInfo.gameLogs')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-info-content-joyride">
        <Col sm="12">
          <Row className="weekly-statistics">
            <Col sm="12" className="weekly-statistics-table">
              <Row className="weekly-statistics-table-header mt-2">
                <Col sm="4" className="weekly-statistics-table-header-col" />
                <Col sm="2" className="weekly-statistics-table-header-col">
                  {t('myInfo.lastWeek')}
                </Col>
                <Col sm="2" className="weekly-statistics-table-header-col">
                  {t('myInfo.twoWeeksAgo')}
                </Col>
                <Col sm="2" className="weekly-statistics-table-header-col">
                  {t('myInfo.trend')}
                </Col>
                <Col sm="2" className="weekly-statistics-table-header-col">
                  {t('myInfo.average')}
                </Col>
              </Row>
              <ScrollAreaWrapper
                className="chat-body-scroll-area weekly-statistics-scrollarea"
                contentClassName="online-users-ReactTableContainer"
                show={weeklyStatistics && Object.keys(weeklyStatistics).length > 7 ? true : null}
                rightOffset={14}
                topOffset={79}
                bottomOffset={12}
              >
                {weeklyStatistics && (
                  <Fragment>
                    {constants.WEEKLY_STATISTICS_DATA && map(constants.WEEKLY_STATISTICS_DATA, (item, key) => (
                      <Row key={key} className="weekly-statistics-table-row">
                        <Col sm="4" className="weekly-statistics-table-row-col weekly-statistics-table-row-col-text">
                          {t(`myInfo.${key}`)}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col weekly-statistics-table-row-col-text">
                          {get(weeklyStatistics, `oneWeekAgo.${key}`, 0)}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col">
                          {get(weeklyStatistics, `twoWeeksAgo.${key}`, 0)}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col weekly-statistics-table-row-col-text-color">
                          {getTrend(get(weeklyStatistics, `oneWeekAgo.${key}`, 0), get(weeklyStatistics, `twoWeeksAgo.${key}`, 0))}
                        </Col>
                        <Col sm="2" className="weekly-statistics-table-row-col">
                          {(get(weeklyStatistics, `oneWeekAgo.${key}`, 0) + get(weeklyStatistics, `twoWeeksAgo.${key}`, 0)) / 2}
                        </Col>
                      </Row>
                    ))}
                  </Fragment>
                )}
              </ScrollAreaWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

WeeklyStatus.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
};

WeeklyStatus.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
};

export default WeeklyStatus;
