import React, { Component } from 'react';
import PropTypes, { shape } from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Firebase } from '../../lib/firebase';


import { getUserRole } from '../../actions/member';
import * as constants from '../../constants/constants';

import {
  getAllUsers,
  //  getUsersCount,
  getAllVipUsers,
  deleteUser,
  manualyVerifyUser,
  toggleLogRocket,
  toggleSmartlook,
  resetActiveRoom,
  blockUser,
  unblockUser,
  editUser,
  addBalance,
  addPoints,
  getAllBans,
  editBan,
  getAllGiftCodes,
  addGiftCode,
  editGiftCode,
  deleteGiftCode,
  getClaimedPlayers,
  getAllTournaments,
  addTournament,
  editTournament,
  deleteTournament,
  getTournamentPlayers,
  getTournamentPlayerHistory,
  getTournamentResults,
  getUserMessages,
  cancelUserMessages,
  answerSupportMessage,
  supportMessageFileUpload,
  updateAvatarFileUpload,
  //  getActiveMessages,
  setSupportMessageAsResponded,
  messageAll,
  forceCloseTournament,
  getUserCount,
  getUsersRange,
  getFilteredUsers,
  getNonVerifiedUsers,
  getActiveReadMessages,
  getActiveUnreadMessages,
  getPaymentsRange,
  getPaymentsCount,
  getCompletedPaymentsRange,
  getCompletedPaymentsCount,
  getFilteredTransactions,
  getBansRange,
  getBansCount,
  getUserGamesHistory,
  getUserBalanceHistory,
  getUserPointsHistory,
  getSmartLookEnabled,
  changeSmartLook,
  getmissedTurnsCount,
  getmissedTurnsData,
  getAdmins,
  getBotUsers,
  updateRole,
  getStatistic,
  //  addNewAdmin,
  getMobileVersion,
  setMobileVersion,
  getTreasureChestEnabled,
  setTreasureChestEnabled,
  getInitiatedPaymentsRange,
  getInitiatedPaymentsCount,
  getAdminActionLog,
  getAdminActionLogCount,
  editUserDob,
  getLogRocketUsers,
  getWarningPlayers,
  getAllReportedMessages,
  getAutoCompensations,
  disableAutoCompensation,
  getTournamentUsers,
  getAllGifts,
} from '../../actions/admin';

import { swapToUser } from '../../actions/users';

class Admin extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    member: PropTypes.shape({
      role: PropTypes.string,
    }),
    admin: PropTypes.shape({
      allUsers: PropTypes.shape({}),
      //  allUsersLastKey: PropTypes.shape({}),
      vipUsers: PropTypes.shape({}),
      allGiftCodes: PropTypes.shape({}),
      claimedPlayers: PropTypes.shape({}),
      allBans: PropTypes.arrayOf(PropTypes.shape({})),
      allTransactions: PropTypes.shape({}),
      allTournaments: PropTypes.shape({}),
      tournamentPlayers: PropTypes.shape({}),
      tournamentPlayerHistory: PropTypes.shape({}),
      tournamentResults: PropTypes.arrayOf(PropTypes.shape({})),
      chatMessages: PropTypes.shape({}),
      chatMessagesUserLastLanguage: PropTypes.string,
      userCount: PropTypes.number,
      filteredUsers: PropTypes.shape({}),
      nonVerifiedUsers: PropTypes.arrayOf(PropTypes.shape({})),
      allPayments: PropTypes.arrayOf(PropTypes.shape({})),
      paymentsCount: PropTypes.number,
      userGamesHistory: PropTypes.arrayOf(PropTypes.shape({})),
      userBalanceHistory: PropTypes.arrayOf(PropTypes.shape({})),
      userPointsHistory: PropTypes.arrayOf(PropTypes.shape({})),
      bansCount: PropTypes.number,
      readMessages: PropTypes.arrayOf(PropTypes.shape({})),
      unreadMessages: PropTypes.shape({}),
      adminActionData: PropTypes.arrayOf(PropTypes.shape({})),
      warningPlayers: PropTypes.shape({}),
      allReportedMessages: PropTypes.shape({}),
      gifts: PropTypes.shape({}),
      // nonVerifiedUsers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    match: PropTypes.shape({ params: PropTypes.shape({}) }),
    fetchAllUsers: PropTypes.func.isRequired,
    fetchUsersRange: PropTypes.func.isRequired,
    fetchPaymentsRange: PropTypes.func.isRequired,
    fetchPaymentsCount: PropTypes.func.isRequired,
    fetchCompletedPaymentsRange: PropTypes.func.isRequired,
    fetchCompletedPaymentsCount: PropTypes.func.isRequired,
    fetchInitiatedPaymentsRange: PropTypes.func.isRequired,
    fetchInitiatedPaymentsCount: PropTypes.func.isRequired,
    fetchFilteredTransactions: PropTypes.func.isRequired,
    fetchBansRange: PropTypes.func.isRequired,
    fetchBansCount: PropTypes.func.isRequired,
    fetchUserGamesHistory: PropTypes.func.isRequired,
    fetchUserBalanceHistory: PropTypes.func.isRequired,
    fetchUserPointsHistory: PropTypes.func.isRequired,
    fetchFilteredUsers: PropTypes.func.isRequired,
    fetchNonVerifiedUsers: PropTypes.func.isRequired,
    fetchUserCount: PropTypes.func.isRequired,
    fetchAllVipUsers: PropTypes.func.isRequired,
    fetchAllBans: PropTypes.func.isRequired,
    deleteAUser: PropTypes.func.isRequired,
    swapToUserFunc: PropTypes.func.isRequired,
    verifyUser: PropTypes.func.isRequired,
    blockAUser: PropTypes.func.isRequired,
    unblockAUser: PropTypes.func.isRequired,
    editAUser: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    fetchAllGiftCodes: PropTypes.func.isRequired,
    fetchClaimedPlayers: PropTypes.func.isRequired,
    addAGiftCode: PropTypes.func.isRequired,
    editAGiftCode: PropTypes.func.isRequired,
    deleteAGiftCode: PropTypes.func.isRequired,
    editABan: PropTypes.func.isRequired,
    fetchAllTournaments: PropTypes.func.isRequired,
    fetchWarningPlayers: PropTypes.func.isRequired,
    fetchAllReportedMessages: PropTypes.func.isRequired,
    addATournament: PropTypes.func.isRequired,
    editATournament: PropTypes.func.isRequired,
    deleteATournament: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentUsers: PropTypes.func.isRequired,
    fetchTournamentPlayerHistory: PropTypes.func.isRequired,
    fetchTournamentResults: PropTypes.func.isRequired,
    fetchUserMessages: PropTypes.func.isRequired,
    fetchActiveUnreadMessages: PropTypes.func.isRequired,
    fetchActiveReadMessages: PropTypes.func.isRequired,
    answerSupportMsg: PropTypes.func.isRequired,
    setSupportMsgAsResponded: PropTypes.func.isRequired,
    messageAllUsers: PropTypes.func.isRequired,
    forceCloseTournamentFunc: PropTypes.func.isRequired,
    fetchSmartLookEnabled: PropTypes.func.isRequired,
    //  toggleSmartLook: PropTypes.func.isRequired,
    fetchmissedTurnsCount: PropTypes.func.isRequired,
    fetchmissedTurnsData: PropTypes.func.isRequired,
    fetchAdmins: PropTypes.func.isRequired,
    fetchBots: PropTypes.func.isRequired,
    fetchAdminActionLog : PropTypes.func.isRequired,
    changeRole: PropTypes.func.isRequired,
    fetchStatistic: PropTypes.func.isRequired,
    //  addNewAdminUser: PropTypes.func.isRequired,
    getMobileVersionFunc: PropTypes.func.isRequired,
    setMobileVersionFunc: PropTypes.func.isRequired,
    getTreasureChestEnabledFunc: PropTypes.func.isRequired,
    setTreasureChestEnabledFunc: PropTypes.func.isRequired,
    changeSmartlook: PropTypes.func.isRequired,
    fetchAdminActionLogCount: PropTypes.func.isRequired,
    submitUserDobFuc: PropTypes.func.isRequired,
    searchUid: PropTypes.string,
    fontMode: PropTypes.string,
    fetchLogRocketUsers: PropTypes.func.isRequired,
    fetchAutoCompensations: PropTypes.func.isRequired,
    disableAutoCompensationFunc: PropTypes.func.isRequired,
    fetchGifts: PropTypes.func.isRequired,
  }

  static defaultProps = {
    match: null,
    member: {},
    admin: {},
    userGamesHistory: [],
    userBalanceHistory: [],
    userPointsHistory: [],
    searchUid: null,
    fontMode: constants.FONT_MODE.normal
  }

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      roleLoaded: false,
    };
  }

  componentDidMount = () => {
    const { searchUid, fetchMemberRole, fetchCompletedPaymentsCount, fetchPaymentsCount, fetchInitiatedPaymentsCount, fetchTournamentUsers } = this.props;
    Firebase.auth().onAuthStateChanged((user) => {
      const { history, fetchSmartLookEnabled } = this.props;
      if (user) {
        console.log(user);
        fetchMemberRole().then((role) => {
          console.log(role);

          const { member } = this.props;

          console.log(member);

          if (member && (role === 'admin' || role === 'tester')) {
            this.setState({ roleLoaded: true });
            //  if (member && (member.role === 'admin' || member.role === 'tester')) {
            //  this.fetchUsersRange(1, 50);
            this.fetchFilteredUsers('', '');
          //  this.fetchNonVerifiedUsers('', '');

            fetchSmartLookEnabled();

            //  this.fetchPaymentsRange(1, 50);
            fetchPaymentsCount();
            fetchCompletedPaymentsCount();
            console.log("here!!!!!");
            fetchInitiatedPaymentsCount();

            this.fetchBansRange(1, 150);
            this.fetchBansCount();
            this.fetchAdminActionLogCount();
            this.fetchUserCount();
            this.fetchAllVipUsers();
            this.fetchAllBans();
            this.fetchAllTournaments();
            this.fetchGifts();
            this.getActiveMessages();
            this.fetchAutoCompensations();
            this.fetchAllGiftCodes();

            fetchTournamentUsers(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime(), new Date().getTime());
            // this.fetchWarningPlayers();
            // this.fetchAllReportedMessages();

            if (searchUid) {
              this.fetchFilteredUsers(searchUid.toString(), 'uid');
            }
            // this.fetchNonVerifiedUsers();
          } else {
            history.push('/');
          }
        });
      } else {
        history.push('/');
      }
    });
  }


  deleteUser = (uid) => {
    const { deleteAUser, showNotification } = this.props;

    deleteAUser(uid).then((res) => {
      if (res.data && res.data.status === 'success') {
        showNotification('Success', 'User has been deleted successfully!', 'success');
      } else {
        showNotification('Error!', 'User has been not deleted successfully!', 'danger');
      }
    });
  }

  swapToUser = (uid, userNewDesign, userScreenMode, adminNewDesign, adminScreenMode) => {
    const { swapToUserFunc, showNotification, history } = this.props;

    console.log('call swapToUserFunc', { uid, userNewDesign, userScreenMode, adminNewDesign, adminScreenMode });

    swapToUserFunc(uid, userNewDesign, userScreenMode, adminNewDesign, adminScreenMode).then((res) => {
      console.log('swapToUserFunc', res);
      if (res.data && res.data.status === 'success') {
        history.push('/');
        showNotification('Success', 'Swaped to user successfully!', 'success');
      } else {
        showNotification('Error!', 'Failed to swap!', 'danger');
      }
    });
  }

  manualyVerifyUser = (uid) => {
    const { verifyUser, showNotification, t } = this.props;

    console.log('manualyVerifyUser', uid);

    verifyUser(uid).then((res) => {
      console.log(res, res.data, "result check");
      if (res && res.status === 'success') {
        showNotification(t('success'), 'Verification email has been sent successfully', 'success');
        this.fetchNonVerifiedUsers();
      } else {
        showNotification(t('error!'), 'Failed to validate user.', 'danger');
      }
    }).catch(err => showNotification(t('error!'), err.message, 'danger'));
  }

  toggleLogRocket = (uid) => {
    const { t, changeLogRocket, showNotification } = this.props;

    changeLogRocket(uid).then((res) => {
      if (res && res.status === 'success') {
        if (res.enabled) {
          showNotification('Success!', 'Logrocket sucessfully enabled', 'success');
        } else {
          showNotification('Success!', 'Logrocket switch off successfully', 'success');
        }
      } else {
        showNotification('Error!', 'Failed to change logrocket', 'danger');
      }
    });
  }

  toggleSmartlook = (uid) => {
    console.log('toggleSmartlook', { uid });
    const { t, changeSmartlook, showNotification } = this.props;

    changeSmartlook(uid).then((res) => {
      if (res && res.status === 'success') {
        if (res.enabled) {
          showNotification('Success!', t('common.smartlookSucessfullyEnabled'), 'success');
        } else {
          showNotification('Error!', t('common.smartlookSucessfullyDisabled'), 'success');
        }
      } else {
        showNotification('Error!', 'Failed to change Smartlook', 'danger');
      }
    });
  }


  blockUser = (uid, endDate, reason) => {
    const { blockAUser, showNotification } = this.props;

    return blockAUser(uid, endDate, reason).then((res) => {
      if (res.status === 'success') {
        showNotification('Success!', 'User was successfully blocked', 'success');
      } else {
        showNotification('Error!', 'User was not successfully blocked', 'danger');
      }
      return res.status;
    });
  }

  unblockUser = (uid, endDate, reason) => {
    const { unblockAUser, showNotification } = this.props;

    return unblockAUser(uid, endDate, reason).then((res) => {
      if (res.status === 'success') {
        showNotification('Success!', 'User was successfully unblocked', 'success');
      } else {
        showNotification('Error!', 'User was not successfully unblocked', 'danger');
      }
      return res.status;
    });
  }


  editUser = (uid, balance, level, gamesPlayed, totalPnts, firstName, lastName, photo, updateFlag, filter, filterType, name, lowerCaseName, lowerCaseLastName ) => {
    const { editAUser, showNotification } = this.props;

    editAUser(uid, balance, level, gamesPlayed, totalPnts, firstName, lastName, photo, updateFlag, filter, filterType, name, lowerCaseName, lowerCaseLastName).then((res) => {
      if (res.status === 'success') {
        showNotification("Success!", "User's data has been successfully updated", 'success');
        if (updateFlag == "filtered") {
          this.fetchFilteredUsers(filter, filterType);
        } else {
          this.fetchFilteredUsers('', '');
        }
      } else {
        showNotification("Error!", res.message, 'danger');
      }
    });
  }

  addBalance = (uid, balanceChange) => {
    const { addBalanceFunc, showNotification } = this.props;

    addBalanceFunc(uid, balanceChange).then((res) => {
      if (res.status === 'success') {
        showNotification("Success!", "User's balance has been successfully updated", 'success');
      } else {
        showNotification("Error!", res.message, 'danger');
      }
    });
  }

  addPoints = (uid, totalPntsChange) => {
    const { addPointsFunc, showNotification } = this.props;

    addPointsFunc(uid, totalPntsChange).then((res) => {
      if (res.status === 'success') {
        showNotification("Success!", "User's points has been successfully updated", 'success');
      } else {
        showNotification("Error!", res.message, 'danger');
      }
    });
  }

  editBan = (uid, endDate, reason) => {
    const { editABan, showNotification } = this.props;

    editABan(uid, endDate, reason).then((res) => {
      if (res.status === 'success') {
        showNotification('Success!', 'Ban fixed successfully', 'success');
      } else {
        showNotification('Error!', res.message, 'danger');
      }
    });
  }

  addTournament = (data) => {
    const { addATournament, showNotification } = this.props;

    return new Promise((resolve, reject) => addATournament(data).then((res) => {
      console.log('res', res);
      if (res && res.status === 'success') {
        showNotification('Success!', 'Tournament added successfully', 'success');
        return resolve('success');
      }
      if (res.message) {
        showNotification('Error!', res.message, 'danger');
      }
      return reject(res.message);
    //  return null;
    }));
  }

  editTournament = (data) => {
    const { editATournament, showNotification } = this.props;

    return new Promise((resolve, reject) => editATournament(data).then((res) => {
      if (res.status === 'success') {
        showNotification('Success!', 'Tournament has been edited successfully!', 'success');
        return resolve('success');
      }
      if (res.message) {
        showNotification('Error!', res.message, 'danger');
      }
      return reject(res.message);
    }));
  }

  deleteTournament = (tournamentId) => {
    const { deleteATournament, showNotification } = this.props;

    deleteATournament(tournamentId).then((res) => {
      if (res.status === 'success') {
        showNotification('Success!', 'The tournament has been deleted successfully', 'success');
      } else {
        showNotification('Error!', 'The tournament has been not deleted successfully', 'danger');
      }
    });
  }

  addGiftCode = (data) => {
    const { addAGiftCode, showNotification, t } = this.props;
    let text;
    return new Promise((resolve, reject) => addAGiftCode(data).then((res) => {
      if (res && res.status === 'success') {
        showNotification('Success!', 'Gift code successfully added', 'success');
        return resolve('success');
      }

      if (res.status === 'error') {
        if (res.message === 'missingGiftCode') {
          text = 'Missing gift code';
        } else if (res.message === 'giftCodePatternFailed') {
          text = 'Code has to include only a-z A-Z 0-9 letters.';
        } else if (res.message === 'giftCodeLongFailed') {
          text = 'Code length must be less that 11 and greater than 3.';
        } else if (res.message === 'missingPlayerLimit') {
          text = 'Missing player count that can use the code';
        } else if (res.message === 'missingValue') {
          text = 'Missing amount of game coins which will be added upon activating it.';
        } else if (res.message === 'codeExist') {
          text = 'The code already exists';
        } else if (res.message === 'missingExpire') {
          text = 'Missing expiration time';
        } else {
          text = res.message;
        }
        showNotification('Error!', text, 'danger');
      }
      return reject(res.message);
    //  return null;
    }).catch(e => {
      console.log(e.message);
      showNotification('Error!', e.message, 'danger');
    }));
  }

  editGiftCode = (data) => {
    const { editAGiftCode, showNotification, t } = this.props;

    return new Promise((resolve, reject) => editAGiftCode(data).then((res) => {
      if (res.status === 'success') {
        showNotification('Success!', 'Gift code successfully edited', 'success');
        return resolve('success');
      }
      let text;
      if (res.status === 'error') {
        if (res.message === 'missingGiftCode') {
          text = 'Missing gift code';
        } else if (res.message === 'giftCodePatternFailed') {
          text = 'Code has to include only a-z A-Z 0-9 letters.';
        } else if (res.message === 'giftCodeLongFailed') {
          text = 'Code length must be less that 11 and greater than 3.';
        } else if (res.message === 'missingPlayerLimit') {
          text = 'Missing player count that can use the code';
        } else if (res.message === 'missingValue') {
          text = 'Missing amount of game coins which will be added upon activating it.';
        } else if (res.message === 'codeExist') {
          text = 'The code already exists';
        } else if (res.message === 'missingExpire') {
          text = 'Missing expiration time';
        } else {
          text = res.message;
        }
        showNotification('Error!', text, 'danger');
      }
      return reject(res.message);
    }).catch(e => {
      showNotification('Error!', e.message, 'danger');
    }));
  }

  deleteGiftCode = (giftCodeId) => {
    const { deleteAGiftCode, showNotification, t } = this.props;

    deleteAGiftCode(giftCodeId).then((res) => {
      if (res.status === 'success') {
        showNotification('Success!', 'Gift code is deleted', 'success');
      } else {
        showNotification('Error!', res.message, 'danger');
      }
    });
  }

  answerSupportMessage = (data) => {
    const { answerSupportMsg } = this.props;

    return answerSupportMsg(data).catch((err) => {
      console.log(err);
    });
  }

  supportMessageFileUpload = (data) => {
    const { supportMessageFileUpload } = this.props;

    return supportMessageFileUpload(data).catch((err) => {
      console.log(err);
    });
  }

  updateAvatarFileUpload = (data, editUserId) => {
    const { updateAvatarFileUpload } = this.props;

    return updateAvatarFileUpload(data, editUserId).catch((err) => {
      console.log(err);
    });
  }

  setSupportMessageAsResponded = (data) => {
    const { setSupportMsgAsResponded } = this.props;

    return setSupportMsgAsResponded(data).catch((err) => {
      console.log(err);
    });
  }


  messageAll = (message, filteredPlayers, messageLvInput, messageEnInput, messageRuInput, languageType) => {
    const { messageAllUsers, showNotification, t } = this.props;

    messageAllUsers(message, filteredPlayers, messageLvInput, messageEnInput, messageRuInput, languageType).then((res) => {
      if (res && res.status === constants.SERVER_STATUS_CODE.success) {
        showNotification(t(constants.SERVER_STATUS_CODE.success), t(constants.SERVER_STATUS_CODE.success), constants.FROUNT_SIDE_STATUS.success);
      } else {
        showNotification(t(constants.SERVER_STATUS_CODE.error), t(constants.SERVER_STATUS_CODE.error), constants.FROUNT_SIDE_STATUS.danger);
      }
    }).catch((e) => {
      showNotification(t(constants.SERVER_STATUS_CODE.error), e.message, 'danger');
    });
  }


  getActiveMessages = () => {
    const { fetchActiveReadMessages, fetchActiveUnreadMessages } = this.props;

    console.log('getActiveMessages');

    fetchActiveReadMessages().then(() => {
    //  console.log(res);
    });

    fetchActiveUnreadMessages().then(() => {
    //  console.log(res);
    });
  }

  getUserMessages = (uid) => {
    const { fetchUserMessages } = this.props;

    return fetchUserMessages(uid).then(() => 'success');
  }

  cancelUserMessages = (uid) => {
    const { cancelActiveUserMessages } = this.props;

    return cancelActiveUserMessages(uid).then(() => 'success');
  }

  /*  fetchTournamentPlayers = (tournamentId) => {
    const { fetchTournamentPlayers } = this.props;

    fetchTournamentPlayers(tournamentId);
  } */

  fetchUserCount = () => {
    const { fetchUserCount } = this.props;

    fetchUserCount();
  }

  fetchUsersRange = (start, end) => {
    const { fetchUsersRange } = this.props;

    fetchUsersRange(start, end);
  }

  fetchFilteredUsers = (filter, filterType) => {
    const { fetchFilteredUsers } = this.props;

    fetchFilteredUsers(filter, filterType);
  }

  fetchNonVerifiedUsers = (filter, filterType) => {
    const { fetchNonVerifiedUsers } = this.props;

    fetchNonVerifiedUsers(filter, filterType);
  }
  /*  fetchPaymentsRange = (start, end) => {
      const { fetchPaymentsRange } = this.props;

      fetchPaymentsRange(start, end);
    }  */

  /*  fetchPaymentsCount = () => {
      const { fetchPaymentsCount } = this.props;

      fetchPaymentsCount();
    } */


    fetchBansRange = (start, end) => {
      const { fetchBansRange } = this.props;

      fetchBansRange(start, end);
    }

    fetchBansCount = () => {
      const { fetchBansCount } = this.props;

      fetchBansCount();
    }

    fetchAdminActionLogCount = () => {
      const { fetchAdminActionLogCount } = this.props;
      fetchAdminActionLogCount();
    }

    fetchAdminActionLog = (start, end) => {
      const { fetchAdminActionLog } = this.props;

      fetchAdminActionLog(start, end);
    }
    /*  fetchUserBalanceHistory = (userId) => {
      const { fetchUserBalanceHistory } = this.props;

      fetchUserBalanceHistory(userId);
    } */

    fetchAllUsers() {
      const { fetchAllUsers } = this.props;

      fetchAllUsers();
      // .then(() => {
      //   //  console.log(res);
      // });
    }


    fetchAllVipUsers() {
      const { fetchAllVipUsers } = this.props;

      fetchAllVipUsers().then(() => {
        //  console.log(res);
      });
    }

    fetchAllBans() {
      const { fetchAllBans } = this.props;

      fetchAllBans().then(() => {
        //  console.log(res);
      });
    }

    fetchAllTournaments() {
      const { fetchAllTournaments } = this.props;

      fetchAllTournaments().then(() => {
        //  console.log(res);
      });
    }

    fetchWarningPlayers() {
      const { fetchWarningPlayers } = this.props;

      fetchWarningPlayers().then(() => {
        //  console.log(res);
      });
    }

    fetchAllReportedMessages() {
      const { fetchAllReportedMessages } = this.props;

      fetchAllReportedMessages().then(() => {
        //  console.log(res);
      });
    }

    fetchAllGiftCodes = () => {
      const { fetchAllGiftCodes } = this.props;
      fetchAllGiftCodes().then(() => {
        //  console.log(res);
      });
    }

    fetchGifts = () => {
      const { fetchGifts } = this.props;

      fetchGifts();
    }

    fetchClaimedPlayers(code) {
      const { fetchClaimedPlayers } = this.props;

      fetchClaimedPlayers(code).then(() => {
        //  console.log(res);
      });
    }

    fetchNonVerifiedUsers() {
      const { fetchNonVerifiedUsers } = this.props;

      fetchNonVerifiedUsers().then(() => {

      });
    }

    fetchAutoCompensations = () => {
      const { fetchAutoCompensations } = this.props;
      fetchAutoCompensations().then(() => {

      });
    }

    disableAutoCompensationFunc = (uid, value, name) => {
      const { disableAutoCompensationFunc } = this.props;
      disableAutoCompensationFunc(uid, value, name).then((res) => {
        this.fetchAutoCompensations();
      });
    }

    submitUserDobFuc(editUserId, dob) {
      const { submitUserDobFuc, showNotification } = this.props;
      submitUserDobFuc(editUserId, dob).then((res) => {
        if (res.status === 'success') {
          showNotification("Success!", "User's birthday has been successfully updated", 'success');
        } else {
          showNotification("Error!", res.message, 'danger');
        }
      });
    }

  render = () => {
    const {
      Layout,
      member,
      admin,
      toggleSmartLook,
      resetUsersActiveRoom,
      fetchUserGamesHistory,
      fetchUserBalanceHistory,
      fetchUserPointsHistory,
      fetchmissedTurnsCount,
      fetchmissedTurnsData,
      fetchClaimedPlayers,
      fetchTournamentPlayers,
      fetchTournamentUsers,
      fetchTournamentPlayerHistory,
      fetchTournamentResults,
      forceCloseTournamentFunc,
      fetchAdmins,
      fetchBots,
      changeRole,
      fetchStatistic,
      //  addNewAdminUser,
      getMobileVersionFunc,
      setMobileVersionFunc,
      getTreasureChestEnabledFunc,
      setTreasureChestEnabledFunc,
      fetchPaymentsRange,
      fetchCompletedPaymentsRange,
      fetchInitiatedPaymentsRange,
      fetchFilteredTransactions,
      fetchAdminActionLog,
      fetchAdminActionLogCount,
      showNotification,
      searchUid,
      fontMode,
      toggleNewDesign,
      newDesign,
      screenMode,
      toggleScreenMode,

    } = this.props;

    const {
      error,
      roleLoaded,
    } = this.state;

    if (!roleLoaded) {
      return null;
    }

    return (
      <Layout
        error={error}
        member={member}
        allUsers={admin.allUsers}
        usersCount={admin.userCount}
        logsCount={admin.logsCount}
        filteredUsers={admin.filteredUsers}
        nonVerifiedUsers={admin.nonVerifiedUsers}
        allPayments={admin.allPayments}
        paymentsCount={admin.paymentsCount}
        removedPaymentsCount={admin.removedPaymentsCount}
        completedPaymentsCount={admin.completedPaymentsCount}
        userGamesHistory={admin.userGamesHistory}
        userBalanceHistory={admin.userBalanceHistory}
        userPointsHistory={admin.userPointsHistory}
        allBans={admin.allBans}
        bansCount={admin.bansCount}
        allVipUsers={admin.vipUsers}
      //  allBans={admin.allBans}
        allTransactions={admin.allTransactions}
        missedTurnsCount={admin.missedTurnsCount}
        fetchmissedTurnsCount={fetchmissedTurnsCount}
        missedTurnsData={admin.missedTurnsData}
        fetchmissedTurnsData={fetchmissedTurnsData}
        allTournaments={admin.allTournaments}
        allReportedMessages={admin.allReportedMessages}
        warningPlayers={admin.warningPlayers}
        tournamentPlayers={admin.tournamentPlayers}
        tournamentUsers={admin.tournamentUsers}
        userTournaments={admin.userTournaments}
        totalTournamentUserCount={admin.totalTournamentUserCount}
        tournamentPlayerHistory={admin.tournamentPlayerHistory}
        tournamentResults={admin.tournamentResults}
        readMessages={admin.readMessages}
        unreadMessages={admin.unreadMessages}
        chatMessages={admin.chatMessages}
        chatMessagesUserLastLanguage={admin.chatMessagesUserLastLanguage}
        deleteUser={this.deleteUser}
        swapToUser={this.swapToUser}
        manualyVerifyUser={this.manualyVerifyUser}
        toggleLogRocket={this.toggleLogRocket}
        toggleSmartlook={this.toggleSmartlook}
        resetActiveRoom={resetUsersActiveRoom}
        blockUser={this.blockUser}
        unblockUser={this.unblockUser}
        editUser={this.editUser}
        addBalance={this.addBalance}
        addPoints={this.addPoints}
        fetchAllUsers={this.fetchAllUsers}
        fetchUsersRange={this.fetchUsersRange}
        fetchPaymentsRange={fetchPaymentsRange}
        fetchCompletedPaymentsRange={fetchCompletedPaymentsRange}
        fetchInitiatedPaymentsRange={fetchInitiatedPaymentsRange}
        initiatedPaymentsCount={admin.initiatedPaymentsCount}
        fetchFilteredTransactions={fetchFilteredTransactions}
        fetchBansRange={this.fetchBansRange}
        fetchFilteredUsers={this.fetchFilteredUsers}
        fetchNonVerifiedUsers={this.fetchNonVerifiedUsers}
        fetchRoomLogCount={this.fetchRoomLogCount}
        fetchAllVipUsers={this.fetchAllVipUsers}
        addGiftCode={this.addGiftCode}
        editGiftCode={this.editGiftCode}
        deleteGiftCode={this.deleteGiftCode}
        allGiftCodes={admin.allGiftCodes}
        claimedPlayers={admin.claimedPlayers}
        fetchClaimedPlayers={fetchClaimedPlayers}
        editBan={this.editBan}
        addTournament={this.addTournament}
        editTournament={this.editTournament}
        deleteTournament={this.deleteTournament}
        fetchTournamentPlayers={fetchTournamentPlayers}
        fetchTournamentUsers={fetchTournamentUsers}
        fetchTournamentPlayerHistory={fetchTournamentPlayerHistory}
        fetchTournamentResults={fetchTournamentResults}
        forceCloseTournament={forceCloseTournamentFunc}
        getUserMessages={this.getUserMessages}
        cancelUserMessages={this.cancelUserMessages}
        answerSupportMessage={this.answerSupportMessage}
        supportMessageFileUpload={this.supportMessageFileUpload}
        updateAvatarFileUpload={this.updateAvatarFileUpload}
        setSupportMessageAsResponded={this.setSupportMessageAsResponded}
        messageAll={this.messageAll}
        fetchUserGamesHistory={fetchUserGamesHistory}
        fetchUserBalanceHistory={fetchUserBalanceHistory}
        fetchUserPointsHistory={fetchUserPointsHistory}
      //  toggleSmartLook={toggleSmartLook}
        smartLookStatus={admin.smartLookStatus}
        fetchAdmins={fetchAdmins}
        fetchBots={fetchBots}
        updateRole={changeRole}
      //  addNewAdmin={addNewAdminUser}
        admins={admin.admins}
        bots={admin.bots}
        fetchStatistic={fetchStatistic}
        fetchAdminActionLog={this.fetchAdminActionLog}
        adminActionData={admin.adminActionData}
        statistics={admin.statistics}
        getMobileVersion={getMobileVersionFunc}
        setMobileVersion={setMobileVersionFunc}
        settings={admin.settings}
        getTreasureChestEnabled={getTreasureChestEnabledFunc}
        setTreasureChestEnabled={setTreasureChestEnabledFunc}
        showNotification={showNotification}
        searchUid={searchUid}
        fontMode={fontMode}
        toggleNewDesign={toggleNewDesign}
        newDesign={newDesign}
        screenMode={screenMode}
        toggleScreenMode={toggleScreenMode}
        fetchAutoCompensations={this.fetchAutoCompensations}
        disableAutoCompensationFunc={this.disableAutoCompensationFunc}

        autoCompensations={admin.autoCompensations}
        allGifts = {admin.gifts}
      />
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  admin: state.admin || {},
});

const mapDispatchToProps = {
  fetchAllUsers: getAllUsers,
  fetchUsersRange: getUsersRange,
  fetchFilteredUsers: getFilteredUsers,
  fetchNonVerifiedUsers: getNonVerifiedUsers,
  fetchUserCount: getUserCount,
  fetchAllVipUsers: getAllVipUsers,
  deleteAUser: deleteUser,
  swapToUserFunc: swapToUser,
  verifyUser: manualyVerifyUser,
  changeLogRocket: toggleLogRocket,
  changeSmartlook: toggleSmartlook,
  resetUsersActiveRoom: resetActiveRoom,
  blockAUser: blockUser,
  unblockAUser: unblockUser,
  editAUser: editUser,
  addBalanceFunc: addBalance,
  addPointsFunc: addPoints,
  fetchAllGiftCodes: getAllGiftCodes,
  fetchClaimedPlayers: getClaimedPlayers,
  addAGiftCode: addGiftCode,
  editAGiftCode: editGiftCode,
  deleteAGiftCode: deleteGiftCode,
  fetchPaymentsRange: getPaymentsRange,
  fetchPaymentsCount: getPaymentsCount,
  fetchCompletedPaymentsRange: getCompletedPaymentsRange,
  fetchCompletedPaymentsCount: getCompletedPaymentsCount,
  fetchInitiatedPaymentsRange: getInitiatedPaymentsRange,
  fetchInitiatedPaymentsCount: getInitiatedPaymentsCount,
  fetchFilteredTransactions: getFilteredTransactions,
  fetchBansRange: getBansRange,
  fetchBansCount: getBansCount,
  fetchAllBans: getAllBans,
  editABan: editBan,
  fetchAllTournaments: getAllTournaments,
  fetchWarningPlayers: getWarningPlayers,
  fetchAllReportedMessages: getAllReportedMessages,
  addATournament: addTournament,
  editATournament: editTournament,
  deleteATournament: deleteTournament,
  fetchTournamentPlayers: getTournamentPlayers,
  fetchTournamentUsers: getTournamentUsers,
  fetchTournamentPlayerHistory: getTournamentPlayerHistory,
  fetchTournamentResults: getTournamentResults,
  forceCloseTournamentFunc: forceCloseTournament,
  fetchUserMessages: getUserMessages,
  cancelActiveUserMessages: cancelUserMessages,
  answerSupportMsg: answerSupportMessage,
  supportMessageFileUpload: supportMessageFileUpload,
  updateAvatarFileUpload: updateAvatarFileUpload,
  // fetchActiveMessages: getActiveMessages,
  fetchActiveUnreadMessages: getActiveUnreadMessages,
  fetchActiveReadMessages: getActiveReadMessages,
  setSupportMsgAsResponded: setSupportMessageAsResponded,
  messageAllUsers: messageAll,
  fetchUserGamesHistory: getUserGamesHistory,
  fetchUserBalanceHistory: getUserBalanceHistory,
  fetchUserPointsHistory: getUserPointsHistory,
  fetchSmartLookEnabled: getSmartLookEnabled,
  //  toggleSmartLook: changeSmartLook,
  fetchmissedTurnsCount: getmissedTurnsCount,
  fetchmissedTurnsData: getmissedTurnsData,
  fetchMemberRole: getUserRole,
  fetchAdmins: getAdmins,
  fetchBots: getBotUsers,
  changeRole: updateRole,
  fetchStatistic: getStatistic,
  fetchAdminActionLog: getAdminActionLog,
  fetchAdminActionLogCount: getAdminActionLogCount,
  //  addNewAdminUser: addNewAdmin,
  getMobileVersionFunc: getMobileVersion,
  setMobileVersionFunc: setMobileVersion,
  getTreasureChestEnabledFunc: getTreasureChestEnabled,
  setTreasureChestEnabledFunc: setTreasureChestEnabled,
  submitUserDobFuc: editUserDob,
  fetchLogRocketUsers: getLogRocketUsers,

  fetchAutoCompensations: getAutoCompensations,
  disableAutoCompensationFunc: disableAutoCompensation,
  fetchGifts: getAllGifts,

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));
