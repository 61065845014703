import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ScrollArea from 'react-scrollbar';
import Message from '../AdminSupportMessage';
import { answerSupportMessage, cancelUserMessages, getUserMessages, setSupportMessageAsResponded, supportMessageFileUpload } from '../../../../actions/admin';
import { connect } from 'react-redux';

const Chat = ({
    cancelActiveUserMessages,
    answerSupportMsg,
    fetchUserMessages,
    supportAMessageFileUpload,
    setSupportMsgAsResponded,

    chatMessages,

    openChatModal,
    setOpenChatModal,
    openChatModalName,
    setOpenChatModalName,
    openChatModalUid,
    setOpenChatModalUid,
    showNotification,

}) => {
    const hiddenFileInput = useRef(null);

    const [inputMessage, setInputMessage] = useState('');
    const [fileUploadUrl, setFileUploadUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [ableToSend, setAbleToSend] = useState(true);


    useEffect(() => {
        if (openChatModal) {
            fetchUserMessages(openChatModalUid)
            setFileName('');
            setFileUploadUrl('');
        }
    }, [openChatModal])


    const toggleChat = () => {    
        if (openChatModal) {    
          cancelActiveUserMessages(openChatModalUid);
        }
    
        setOpenChatModal(!openChatModal);
        setOpenChatModalUid('');
        setOpenChatModalName('');
    }

    const handleChangeChat = (event) => {
        if (event.key !== 'Enter' || (event.key === 'Enter' && event.shiftKey)) {
            setInputMessage(event.target.value);
        }
    }
    
    const handleEnter = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            submitMessage();
        }
    }

    const submitMessage = () => {
        if (inputMessage !== '' || fileUploadUrl !== '') {
            answerSupportMsg({
                uid: openChatModalUid, message: inputMessage, fileUploadUrl, fileName,
            }).then(() => {
    
                fetchUserMessages(openChatModalUid).then((res) => {
                });
        
                //  this.scrollToBottom();
                setInputMessage('');
                setFileUploadUrl('');
                setFileName('');
          });
        }
    }

    const chooseFile = (e) => {
        e.preventDefault();
    
        const target = e.target.files[0];
        const typeList = '.jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain';
        if (!target || (target && target.length === 0)) return;
        if (!typeList.includes((target.type).substring((target.type).length - 3)) || target.type == '') { return showNotification('Error!', "Unsupported file!", 'danger'); }
        if (target.size / 1024 > 1536) {
          showNotification('Warning', "Can't upload more than 1.5 MB", 'warning');
          setFileUploadUrl('');
          setFileName('');
        } else {
          setAbleToSend(false);
          supportAMessageFileUpload(target).then((data) => {
            setFileUploadUrl(data.url);
            setFileName(data.url);
            setAbleToSend(true);
            showNotification('Success', "File successfully attached!", 'success');
          });
        }
    }

    const setSupportMessageAsRespondedFunc = () => {
        setSupportMsgAsResponded({ uid: openChatModalUid }).then(() => {
            setOpenChatModal(false);
        });
    }

    return (
        <Modal container={'div > div'} isOpen={openChatModal} toggle={toggleChat}>
            <ModalHeader toggle={toggleChat}>
                {`Chat - ${openChatModalName} - ${openChatModalUid}`}
            </ModalHeader>
            <ModalBody>
                <Row className="chat-body">
                    <Col className="chat-body-wrapper" md="12">
                        <ScrollArea
                            speed={0.65}
                            className="admin-chat-scroll-area"
                            contentClassName="admin-chat-scroll-content"
                            //  smoothScrolling
                            verticalContainerStyle={{
                                background: 'transparent',
                                opacity: 1,
                                width: 10,
                            }}
                            verticalScrollbarStyle={{
                                background: '#fff',
                                borderRadius: 2,
                                width: 6,
                                minHeight: 30,
                                minScrollSize: 35,
                            }}
                            horizontal={false}
                            // ref={(el) => { this.messagesScrollbar = el; }}
                        >
                        {chatMessages && Object.keys(chatMessages).map(key => (
                            <Row key={key}>
                            <Col md="12">
                                <Message message={chatMessages[key]} />
                            </Col>
                            </Row>
                        ))}
                        </ScrollArea>
                    </Col>
                </Row>
                <Row className="chat-footer" style={{ height: 60 }}>
                    <Form style={{ width: '100%', height: '100%' }}>
                        <Col md="12" style={{ height: '100%' }}>
                        <Input
                            className="h-100 chat-footer-input chat-footer-input-admin"
                            type="textarea"
                            name="inputMessage"
                            id="inputMessage"
                            autoComplete="off"
                            placeholder='Write Message...'
                            value={inputMessage}
                            onChange={handleChangeChat}
                            onKeyPress={handleEnter}
                        />

                        </Col>
                    </Form>
                </Row>
                <Row>
                    <Button
                        className="btn-warning admin-file-upload-button"
                        onClick={() => hiddenFileInput.current.click()}
                    >
                        {fileName === '' ? '+ Choose File' : fileName}
                    </Button>
                    <input
                        id="files"
                        hidden
                        ref={hiddenFileInput}
                        className="chat-footer-input chat-footer-input-admin admin-file-upload-input"
                        accept=".jpg,.jpeg,.png,.pdf,.msword,.doc,.rtf,.txt,ain"
                        type="file"
                        onChange={chooseFile}
                    />
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" onClick={setSupportMessageAsRespondedFunc}>Mark As Read</Button>
                <Button className="contact-support-footer-button" disabled={!ableToSend} color="primary" onClick={submitMessage}>
                    Send
                </Button>
                <Button type="button" color="secondary" onClick={toggleChat}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    chatMessages: state.admin.chatMessages || {},
});

const mapDispatchToProps = {
    supportAMessageFileUpload: supportMessageFileUpload,
    cancelActiveUserMessages: cancelUserMessages,
    fetchUserMessages: getUserMessages,
    answerSupportMsg: answerSupportMessage,
    setSupportMsgAsResponded: setSupportMessageAsResponded,
};
  
  
export default connect(mapStateToProps, mapDispatchToProps)(Chat);