import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, PopoverBody } from "reactstrap";
import { UncontrolledPopover } from "reactstrap/lib";
import PaginationOld from "../Menu/PaginationOld";
import Moment from "react-moment";

const AutoCompensations = ({ fetchAutoCompensations, autoCompensations, disableAutoCompensationFunc }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(100);

    useEffect(() => {
        fetchAutoCompensations();
    }, []);

    const handlePagination = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
    };

    const sortedAutoCompensationKeys = useMemo(() => {
        if (!autoCompensations) return [];
        
        return Object.keys(autoCompensations).sort((a, b) => {
            const dateA = autoCompensations[a].lastDate ? autoCompensations[a].lastDate : 0;
            const dateB = autoCompensations[b].lastDate ? autoCompensations[b].lastDate : 0;
            return dateB - dateA;
        });
    }, [autoCompensations]);

    const pagesCount = sortedAutoCompensationKeys.length > 0 ? Math.ceil(sortedAutoCompensationKeys.length / pageSize) : 1;

    return (
        <Fragment>

            <UncontrolledPopover popperClassName={`popover`} placement="right" trigger="focus" target="admin-help-button-auto-compensation">
                <PopoverBody className="popover-body">
                    Users using the auto-compensation mechanism
                </PopoverBody>
            </UncontrolledPopover>

            <div className="header-title">
                <h2>
                    Auto Compensations
                    <Button className="admin-help-button" id="admin-help-button-auto-compensation">
                        ?
                    </Button>
                </h2>
            </div>

            <table className="w-100 text-center">
                <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Player ID
                        </th>
                        <th>
                            Number of compensations
                        </th>
                        <th>
                            Total amount of coins
                        </th>
                        <th>
                            Last compensation
                        </th>
                        <th>
                            Auto-compensation
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedAutoCompensationKeys.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((key) => (
                        <tr key={key}>
                            <td>
                                {autoCompensations[key].name}
                            </td>
                            <td>
                                {autoCompensations[key].uid}
                            </td>
                            <td>
                                {autoCompensations[key].count || '-'}
                            </td>
                            <td>
                                {autoCompensations[key].amount || '-'}
                            </td>
                            <td>
                                {autoCompensations[key].lastDate ? (
                                    <Moment format="DD-MM-YYYY HH:mm" locale="lv">
                                        {autoCompensations[key].lastDate}
                                    </Moment>
                                ) : ('-')}
                            </td>
                            <td>
                                <Button type="button" color="primary" style={{ fontSize: 12, padding: 3 }} onClick={() => disableAutoCompensationFunc(autoCompensations[key].uid, !autoCompensations[key].disabled, autoCompensations[key].name)}>
                                    {autoCompensations[key].disabled ? 'Enable' : 'Disable'}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <PaginationOld
                show={true}
                currentPage={currentPage}
                pagesCount={pagesCount}
                handlePagination={handlePagination}
            />
        </Fragment>
    );
};

export default AutoCompensations;