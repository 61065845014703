import React, { Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';

import { useTranslation, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import * as constants from '../../../../../../constants/constants';
import GiftsHistory from '../Components/MyInfo/GiftsHistory';
import { getGiftsHistory, removeGift } from '../../../../../../actions/member';
import { connect } from 'react-redux';

const Gifts = ({ changeTab, getGiftsHistoryFunction, removeGiftFunction, member, gifts, notificationSettings }) => {

  const { t } = useTranslation('common');
  // const dispatch = useDispatch();

  // const weeklyStatistics = useSelector(state => state.member.weeklyStatistics);
  // const screenMode = useSelector(state => state.member.screenMode);
  // const fontMode = useSelector(state => state.member.fontMode);

  // useEffect(() => {
  //   dispatch(getWeeklyStatistics());
  // }, [screenMode, fontMode]);

  return (
    <div className="layout-mobile-body layout-mobile-body-myinfo-page">
      <Helmet>
        <title>
          Zole - {t('common:myInfo.weeklyStatus')}
        </title>
      </Helmet>

      <div className="layout-mobile-body-top">
        {/* <Row className="layout-mobile-subheader d-none">
          <MyInfoSubHeader changeTab={changeTab} activeTab={activeTab} />
        </Row> */}
      </div>
      <div className="layout-mobile-body-main">
          <GiftsHistory
            getGiftsHistory={getGiftsHistoryFunction}
            removeGift={removeGiftFunction}
            giftsHistory={member.giftsHistory}
            gifts={gifts}
            t={t}
            notificationSettings={notificationSettings}
            changeTab={changeTab}
          />
      </div>
    </div>
  );
}

Gifts.propTypes = {
  achievementsNotification: PropTypes.bool,
  userSettings: PropTypes.shape({
    soundOn: PropTypes.bool,
  }),
  uid: PropTypes.string,
  changeTab: PropTypes.func.isRequired,
  showUserSettingsModal: PropTypes.func.isRequired,
  handleClickStart: PropTypes.func.isRequired,
  OpenMobileDropdownModal: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
};

Gifts.defaultProps = {
  userSettings: {},
  achievementsNotification: false,
  uid: null,
  activeTab: constants.MENU_NAVIGATION.weeklyStatus,
};

const mapStateToProps = state => ({
  member: state.member || {},
  gifts: state.rooms.gifts || {},
});

const mapDispatchToProps = {
  removeGiftFunction: removeGift,
  getGiftsHistoryFunction: getGiftsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Gifts));
